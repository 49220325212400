import React from "react";
import "../../../styles/scss/components/Utilities/Error/ErrorMessage.scss";
const ErrorMessage = () => {
  return (
    <div className="error-message">
      <img
        src="https://cdn.discordapp.com/attachments/1021476676024156204/1150569646852948068/Zero_Socio_Logo_Png_Angry.png?ex=65515bfc&is=653ee6fc&hm=3adb2a166cecbbbb5c2773e6024c30f3e34061181d511c047048fed2ea4f6e28&"
        alt="error"
        className="error-image"
      />
      <p className="error-message-text">Something went wrong, Contact Support</p>
    </div>
  );
};

export default ErrorMessage;
