import { MoonLoader } from "react-spinners";
import "../../styles/scss/components/loaders/MoonLoaders.scss";
export const MoonLoaders = ({loading, size}) => {
  if (!loading) {
    return null;
  } else {
    return (
      <div className="componentloader">
        <MoonLoader
          color={"red"}
          loading={loading}
          size={size}
          speedMultiplier={0.2}
        />
      </div>
    );
  }
};
