export const menuData = [
    {
      path: "/support",
      name: "Support",
    },
    {
        path: "/commands",
        name: "Commands"
    },
    // {
    //     path: "/service",
    //     name: "Service"
    // },
  ];