module.exports.Paths = {
  Dashboard: [
    "/",
    "/konva",
    "/contact",
    "/commands",
    "/premium",
    "/servers",
    "/tos",
    "/bugs",
    "/privacy-policy",
    "/support",
    "/other-services",
    "/community",
    "/careers",
    "/chat",
    "/service",
  ],
  AuthRoute: ["/servers", "/dashboard/", "/users/"],
  Api: [
    "/v1/track",
    "/v1/track/messages",
    "/v1/track/messagesDeleted",
    "/v1/track/messagesSent",
    "/v1/track/roles",
    "/v1/track/channels",
    "/v1/track/client",
    "/v1/track/commands",
    "/v1/track/commandsDeleted",
    "/v1/track/commandsSent",
  ],
};
