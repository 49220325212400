import "../../../../styles/scss/pages/Dashboard/Servers/Utilities/AutoResponder.scss";

import {
  faArrowDown,
  faExclamationTriangle,
  faTimes,
  faTrashAlt,
} from "@fortawesome/fontawesome-free-solid";
import {
  faAdd,
  faClose,
  faHashtag,
  faPen,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SwitchButtons } from "../../../../components/Buttons/SwitchButton";
import { Loading } from "../../../../components/Loaders/Loading";
import { BotMessage } from "../../../../components/Utilities/BotMessage";
import { Checkbox } from "../../../../components/Utilities/Checkbox";
import { EditsConfig } from "../../../../components/Utilities/EditsConfig";
import { Notify } from "../../../../components/Utilities/Notify";
import { Tooltip } from "../../../../components/Utilities/ToolTip";
export const AutoResponders = ({ StatOfData, loading, socket }) => {
  const [newButton, setNewButton] = useState(true);
  const [trigger, setTrigger] = useState("");
  const [message, setMessage] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [respondersLoading, setRespondersLoading] = useState(loading);
  const location = useLocation();
  const path = location.pathname;
  const guildId = path.split("/")[2];
  const guildChannels = StatOfData && StatOfData.current.channels;
  const guildRoles = StatOfData && StatOfData.current.roles;
  const [roles, setRoles] = useState([]);
  const [channels, setChannels] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newResponse, setNewResponse] = useState({
    trigger: "",
    response: "",
    reply: false,
    ping: false,
    target: false,
    enabled: true,
    enabledRoles: [],
    disabledRoles: [],
    enabledChannels: [],
    disabledChannels: [],
  });
  const AutoResponses = StatOfData && StatOfData.current.AutoResponses;

  const [responses, setResponses] = useState([]);
  const [editingResponses, setEditingResponses] = useState([]);
  // const [AutoResponses, setAutoResponses] = useState([]);

  useEffect(() => {
    console.log(AutoResponses);
    if (AutoResponses) {
      setRespondersLoading(false);
    } else {
      const initialData = {
        guildId: guildId,
        client: socket.id,
        from: "AutoResponders",
      };
      if (!StatOfData.current.AutoResponses) {
        socket.emit("getAutoResponses", initialData);
      } else if (!roles) {
        socket.emit("roles", initialData);
      } else if (channels) {
        socket.emit("channels", initialData);
      } else {
        socket.emit("getAutoResponses", initialData);
        socket.emit("channels", initialData);
        socket.emit("roles", initialData);
      }
      setRespondersLoading(true);
    }
  }, [StatOfData]);

  useEffect(() => {
    socket.on("getAutoResponses", (data) => {
      if (data.success) {
        Notify({
          type: "success",
          message: "New responder added",
        });
      } else if (data.updating) {
        Notify({
          type: "success",
          message: "Responder updated",
        });
      } else if (data.deleted) {
        Notify({
          type: "success",
          message: "Responder deleted",
        });
      } else return Notify({ type: "error", message: data.message });
    });

    return () => {
      socket.off("getAutoResponses");
    };
  }, []);

  useEffect(() => {
    let roless = [];
    let channelss = [];
    if (guildRoles && guildRoles.roles && !roles.length) {
      roless = guildRoles.roles.map((role) => {
        return {
          ...role,
        };
      });

      setRoles(roless);
    }

    if (guildChannels && guildChannels.ChData && !channels.length) {
      channelss = guildChannels.ChData.map((channel) => {
        return {
          id: channel.id,
          name: channel.name,
        };
      });
      setChannels(channelss);
    }
    if (StatOfData.current && StatOfData.current.AutoResponses) {
      setResponses(StatOfData.current.AutoResponses.responses);
      setEditingResponses(StatOfData.current.AutoResponses.responses);
    }
  }, [guildChannels, guildRoles, StatOfData]);

  const createNewForm = () => {
    setIsSaveEnabled(true);
    const form = document.querySelector(
      ".auto_responders__body__add__new__form"
    );
    form.classList.toggle("active");
    //disable click outside
    /* document.addEventListener("click", (e) => {
      if (!e.target.closest(".auto_responders__body__add__new")) {
        form.classList.remove("active");
        setNewButton(true);
        setIsSaveEnabled(false);
      }
    });*/
    setNewButton(false);
  };

  const closeNewForm = () => {
    const form = document.querySelector(
      ".auto_responders__body__add__new__form"
    );
    form.classList.remove("active");
    setNewButton(true);
  };

  const cleanInputs = () => {
    const triggerInput = document.querySelector(
      ".auto_responders__body__add__new__form__input input[placeholder='Trigger']"
    );
    const responseInput = document.querySelector(
      ".auto_responders__body__add__new__form__input input[placeholder='Response']"
    );
    if (triggerInput) {
      triggerInput.value = "";
    }
    if (responseInput) {
      responseInput.value = "";
    }
  };

  const checkboxes = [
    { id: "reply", label: "Replay", checked: false },
    { id: "ping", label: "Ping", checked: false },
    { id: "target", label: "Target", checked: false },
  ];

  const [checkedBoxes, setCheckedBoxes] = useState(
    checkboxes.reduce((map, { id, checked }) => ({ ...map, [id]: checked }), {})
  );
  const handleCheckboxChange = (id, isChecked) => {
    setCheckedBoxes({
      ...checkedBoxes,
      [id]: isChecked,
    });
    setNewResponse({
      ...newResponse,
      [id]: isChecked,
    });
  };

  const showRolesenable = (e) => {
    if (newResponse.disabledRoles.length > 0) return;
    const options = document.querySelector(
      ".response_options_enabled_roles_form_options"
    );
    options.classList.toggle("active");

    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_enabled_roles")) {
        options.classList.remove("active");
      }
    });
  };
  const showEditRolesenable = (e) => {
    if (editingResponses[editIndex].disabledRoles.length > 0) return;
    const options = document.querySelector(
      ".response_options_enabled_roles_form_options"
    );
    options.classList.toggle("active");

    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_enabled_roles")) {
        options.classList.remove("active");
      }
    });
  };

  const showRolesdisable = (e) => {
    console.log("newResponse", editingResponses);
    if (newResponse.enabledRoles.length > 0) return;

    const options = document.querySelector(
      ".response_options_disable_roles_form_options"
    );
    options.classList.toggle("active");
    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_disable_roles")) {
        options.classList.remove("active");
      }
    });
  };
  const showEditRolesdisable = (e) => {
    if (editingResponses[editIndex].enabledRoles.length > 0) return;
    const options = document.querySelector(
      ".response_options_disable_roles_form_options"
    );
    options.classList.toggle("active");
    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_disable_roles")) {
        options.classList.remove("active");
      }
    });
  };
  const showChannelsenable = (e) => {
    if (newResponse.disabledChannels.length > 0) return;
    const options = document.querySelector(
      ".response_options_enabled_channels_form_options"
    );
    options.classList.toggle("active");
    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_enabled_channels")) {
        options.classList.remove("active");
      }
    });
  };
  const showEditChannelsenable = (e) => {
    if (editingResponses[editIndex].disabledChannels.length > 0) return;
    const options = document.querySelector(
      ".response_options_enabled_channels_form_options"
    );
    options.classList.toggle("active");
    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_enabled_channels")) {
        options.classList.remove("active");
      }
    });
  };
  const showChannelsdisable = (e) => {
    if (newResponse.enabledChannels.length > 0) return;
    const options = document.querySelector(
      ".response_options_disable_channels_form_options"
    );
    options.classList.toggle("active");
    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_disable_channels")) {
        options.classList.remove("active");
      }
    });
  };
  const showEditChannelsdisable = (e) => {
    if (editingResponses[editIndex].enabledChannels.length > 0) return;
    const options = document.querySelector(
      ".response_options_disable_channels_form_options"
    );
    options.classList.toggle("active");
    //disable click outside
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".response_options_disable_channels")) {
        options.classList.remove("active");
      }
    });
  };
  const handleTriggerChange = (e) => {
    setNewResponse({
      ...newResponse,
      trigger: e.target.value,
    });
    setTrigger(e.target.value);
  };

  const handleEditEnabledRoleChange = (role, index) => {
    const editingCopy = [...editingResponses];
    const roleObj = roles.find((r) => r.id === role);
    const roleIndex = editingCopy[index].enabledRoles.findIndex(
      (r) => r.id === roleObj.id
    );
    if (roleIndex === -1) {
      editingCopy[index] = {
        ...editingCopy[index],
        enabledRoles: [...editingCopy[index].enabledRoles, roleObj],
      };
    } else {
      editingCopy[index] = {
        ...editingCopy[index],
        enabledRoles: editingCopy[index].enabledRoles.filter(
          (r) => r.id !== roleObj.id
        ),
      };
    }
    setEditingResponses(editingCopy);
  };

  const handleEditDisabledRoleChange = (role, index) => {
    const editingCopy = [...editingResponses];
    const roleObj = roles.find((r) => r.id === role);
    const roleIndex = editingCopy[index].disabledRoles.findIndex(
      (r) => r.id === roleObj.id
    );
    if (roleIndex === -1) {
      editingCopy[index] = {
        ...editingCopy[index],
        disabledRoles: [...editingCopy[index].disabledRoles, roleObj],
      };
    } else {
      editingCopy[index] = {
        ...editingCopy[index],
        disabledRoles: editingCopy[index].disabledRoles.filter(
          (r) => r.id !== roleObj.id
        ),
      };
    }
    setEditingResponses(editingCopy);
  };

  const handleEditEnabledChannelChange = (channel, index) => {
    const editingCopy = [...editingResponses];
    const channelObj = channels.find((c) => c.id === channel);
    const channelIndex = editingCopy[index].enabledChannels.findIndex(
      (c) => c.id === channelObj.id
    );
    console.log(channelIndex);
    if (channelIndex === -1) {
      editingCopy[index] = {
        ...editingCopy[index],
        enabledChannels: [...editingCopy[index].enabledChannels, channelObj],
      };
    } else {
      editingCopy[index] = {
        ...editingCopy[index],
        enabledChannels: editingCopy[index].enabledChannels.filter(
          (c) => c.id !== channelObj.id
        ),
      };
    }
    setEditingResponses(editingCopy);
  };

  const handleEditDisabledChannelChange = (channel, index) => {
    const editingCopy = [...editingResponses];
    const channelObj = channels.find((c) => c.id === channel);
    const channelIndex = editingCopy[index].disabledChannels.findIndex(
      (c) => c.id === channelObj.id
    );
    if (channelIndex === -1) {
      editingCopy[index] = {
        ...editingCopy[index],
        disabledChannels: [...editingCopy[index].disabledChannels, channelObj],
      };
    } else {
      editingCopy[index] = {
        ...editingCopy[index],
        disabledChannels: editingCopy[index].disabledChannels.filter(
          (c) => c.id !== channelObj.id
        ),
      };
    }
    setEditingResponses(editingCopy);
  };

  const handleEnabledRoleChange = (role) => {
    console.log(role);
    const roleObj = roles.find((r) => r.id === role);
    console.log(roleObj);
    console.log(newResponse);
    const roleIndex = newResponse.enabledRoles.findIndex(
      (r) => r.id === roleObj.id
    );
    if (roleIndex === -1) {
      setNewResponse({
        ...newResponse,
        enabledRoles: [...newResponse.enabledRoles, roleObj],
      });
    } else {
      const newRoles = newResponse.enabledRoles.filter(
        (r) => r.id !== roleObj.id
      );
      setNewResponse({
        ...newResponse,
        enabledRoles: newRoles,
      });
    }
  };

  const handleDisabledRoleChange = (role) => {
    const roleObj = roles.find((r) => r.id === role);
    const roleIndex = newResponse.disabledRoles.findIndex(
      (r) => r.id === roleObj.id
    );
    if (roleIndex === -1) {
      setNewResponse({
        ...newResponse,
        disabledRoles: [...newResponse.disabledRoles, roleObj],
      });
    } else {
      const newRoles = newResponse.disabledRoles.filter(
        (r) => r.id !== roleObj.id
      );
      setNewResponse({
        ...newResponse,
        disabledRoles: newRoles,
      });
    }
  };

  const handleEnabledChannelChange = (channel) => {
    const channelObj = channels.find((c) => c.id === channel);
    const channelIndex = newResponse.enabledChannels.findIndex(
      (c) => c.id === channelObj.id
    );
    if (channelIndex === -1) {
      setNewResponse({
        ...newResponse,
        enabledChannels: [...newResponse.enabledChannels, channelObj],
      });
    } else {
      const newChannels = newResponse.enabledChannels.filter(
        (c) => c.id !== channelObj.id
      );
      setNewResponse({
        ...newResponse,
        enabledChannels: newChannels,
      });
    }
  };

  const handleDisabledChannelChange = (channel) => {
    const channelObj = channels.find((c) => c.id === channel);
    const channelIndex = newResponse.disabledChannels.findIndex(
      (c) => c.id === channelObj.id
    );
    if (channelIndex === -1) {
      setNewResponse({
        ...newResponse,
        disabledChannels: [...newResponse.disabledChannels, channelObj],
      });
    } else {
      const newChannels = newResponse.disabledChannels.filter(
        (c) => c.id !== channelObj.id
      );
      setNewResponse({
        ...newResponse,
        disabledChannels: newChannels,
      });
    }
  };

  const handleResponseChange = (e) => {
    setNewResponse({
      ...newResponse,
      response: e.target.value,
    });
    setMessage(e.target.value);
  };

  const handleCancel = () => {
    setIsSaveEnabled(false);
    setNewResponse({
      trigger: "",
      response: "",
      reply: false,
      ping: false,
      target: false,
      enabledRoles: [],
      disabledRoles: [],
      enabledChannels: [],
      disabledChannels: [],
    });
    setTrigger("");
    setMessage("");
    setCheckedBoxes(
      checkboxes.reduce(
        (map, { id, checked }) => ({ ...map, [id]: checked }),
        {}
      )
    );

    // Clear input fields explicitly

    cleanInputs();
    closeNewForm();
    setEditIndex(null);
  };

  const handleSave = () => {
    if (!newResponse.trigger || !newResponse.response)
      return Notify({
        message: "Trigger and Response are required",
        type: "info",
      });
    socket.emit("addNewResponder", {
      client: socket.id,
      guildId,
      responder: newResponse,
      isEditing: false,
    });
    setNewResponse({
      trigger: "",
      response: "",
      reply: false,
      ping: false,
      target: false,
      enabledRoles: [],
      disabledRoles: [],
      enabledChannels: [],
      disabledChannels: [],
    });
    setTrigger("");
    setMessage("");
    setCheckedBoxes(
      checkboxes.reduce(
        (map, { id, checked }) => ({ ...map, [id]: checked }),
        {}
      )
    );
    setIsSaveEnabled(false);
    cleanInputs();
    closeNewForm();
    setEditIndex(null);
  };

  const messages = {
    text: newResponse.response,
    reply: true,
    ping: true,
    target: true,
    timestamp: new Date(),
    user: StatOfData.user,
  };
  function moduleEnable(value) {
    setIsEnabled(value);
  }

  const handleEdit = (index) => {
    //setIsSaveEnabled(true);
    const editingCopy = [...editingResponses];
    editingCopy[index] = {
      id: responses[index].id,
      trigger: responses[index].trigger,
      response: responses[index].response,
      reply: responses[index].reply,
      ping: responses[index].ping,
      target: responses[index].target,
      enabledRoles: responses[index].enabledRoles,
      disabledRoles: responses[index].disabledRoles,
      enabledChannels: responses[index].enabledChannels,
      disabledChannels: responses[index].disabledChannels,
    };
    setEditingResponses(editingCopy);
    setEditIndex(index);
  };
  useEffect(() => {
    console.log(newResponse);
  }, [newResponse]);

  const handleEditTriggerChange = (e, index) => {
    const editingCopy = [...editingResponses];
    editingCopy[index] = {
      ...editingCopy[index],
      trigger: e.target.value,
    };
    setEditingResponses(editingCopy);
  };

  const handleEditResponseChange = (e, index) => {
    const editingCopy = [...editingResponses];
    editingCopy[index] = {
      ...editingCopy[index],
      response: e.target.value,
    };
    setEditingResponses(editingCopy);
  };

  const handleEditCheckboxChange = (id, isChecked, index) => {
    const editingCopy = [...editingResponses];
    console.log(id, isChecked, index);
    editingCopy[index] = {
      ...editingCopy[index],
      [id]: isChecked,
    };
    setEditingResponses(editingCopy);
  };

  const handleSaveEdit = (index) => {
    //setIsSaveEnabled(false);
    if (
      editingResponses[index].trigger === responses[index].trigger &&
      editingResponses[index].response === responses[index].response &&
      editingResponses[index].reply === responses[index].reply &&
      editingResponses[index].ping === responses[index].ping &&
      editingResponses[index].target === responses[index].target &&
      editingResponses[index].enabledRoles === responses[index].enabledRoles &&
      editingResponses[index].disabledRoles ===
        responses[index].disabledRoles &&
      editingResponses[index].enabledChannels ===
        responses[index].enabledChannels &&
      editingResponses[index].disabledChannels ===
        responses[index].disabledChannels
    )
      return Notify({
        message: "Please change something before saving!",
        type: "info",
      });
    const finalCopy = [...editingResponses];
    socket.emit("updateResponder", {
      client: socket.id,
      guildId,
      responder: finalCopy[index],
      isEditing: true,
      index,
    });
    console.log(editIndex);
    setEditIndex(null);
  };
  const handleDelete = (index) => {
    socket.emit("deleteResponder", {
      client: socket.id,
      guildId,
      responder: responses[index],
      index,
    });
  };
  const handleCancelEdit = () => {
    setIsSaveEnabled(false);
    setEditingResponses([]);
    setEditIndex(null);
    cleanInputs();
  };
  const handleModuleSwitch = (callback) => {
    let initialData = {
      client: socket.id,
      guildId: guildId,
      module: callback.module,
      value: callback.value,
    };

    socket.emit("moduleSwitch", initialData);
  };
  if (respondersLoading || !AutoResponses ||!guildRoles || !guildChannels) {
    <Loading loading={respondersLoading} />;
  } 
  return (
    <div className="dashcon auto_responders">
      <div className="container">
        <div className="auto_responders__header">
          <h2>
            Auto Responders
            <span>
              <Tooltip text="Auto Responders are a way to automatically respond to messages that contain certain words or phrases." />
            </span>
          </h2>
          <SwitchButtons
            module="AutoResponses"
            isDisabled={false}
            onToggle={handleModuleSwitch}
            defaultChecked={AutoResponses?.enabled}
          />
        </div>
        <div className="auto_responders__body">
          <div className="auto_responders__body__add__new">
            {newButton ? (
              <div className="new-btn" onClick={createNewForm}>
                <FontAwesomeIcon icon={faAdd} className="new-response-icon" />
                New
              </div>
            ) : (
              <div className="new-btn" onClick={handleCancel}>
                <FontAwesomeIcon icon={faClose} className="new-response-icon" />
                Cancel
              </div>
            )}

            <div className="auto_responders__body__add__new__form">
              <h3>New Response</h3>

              {newButton ? null : (
                <>
                  <div className="auto_responders__body__add__new__form__input">
                    <label>Trigger</label>
                    <input
                      type="text"
                      placeholder="Trigger"
                      onChange={handleTriggerChange}
                    />
                  </div>
                  <div className="options">
                    {checkboxes.map(({ id, label }) => (
                      <Checkbox
                        key={id}
                        id={id}
                        label={label}
                        checked={checkedBoxes[id]}
                        onToggle={(isChecked) =>
                          handleCheckboxChange(id, isChecked)
                        }
                      />
                    ))}
                  </div>
                  <div className="auto_responders__body__add__new__form__input">
                    <label>Response</label>
                    <input
                      type="text"
                      placeholder="Response"
                      onChange={handleResponseChange}
                    />
                  </div>

                  <div className="response_options">
                    <div className="response_options_group">
                      <label>Enabled Roles</label>
                      <div
                        className={`response_options_enabled_roles ${
                          newResponse.disabledRoles.length !== 0
                            ? "opacity_5"
                            : ""
                        }`}
                        onClick={showRolesenable}
                      >
                        <div className="response_options_enabled_role_current">
                          {newResponse.enabledRoles.length !== 0 ? (
                            newResponse.enabledRoles.map((role) => {
                              return (
                                <span key={role.id} className="role_picked">
                                  <span
                                    style={{
                                      backgroundColor: `#${role.color
                                        .toString(16)
                                        .padStart(6, "0")
                                        .toUpperCase()}`,
                                    }}
                                    className="roleCircle"
                                  ></span>
                                  <span> {role.name}</span>
                                </span>
                              );
                            })
                          ) : (
                            <span>select role...</span>
                          )}
                        </div>
                        <div className="response_options_enabled_roles_form_options">
                          {guildRoles
                            ? roles.map((role) => {
                                if (
                                  !role.name.includes("everyone") &&
                                  role.tags === false
                                ) {
                                  return (
                                    <div
                                      className="response_options_enabled_roles__role__option"
                                      key={role.id}
                                      onClick={() => {
                                        handleEnabledRoleChange(role.id);
                                      }}
                                    >
                                      {role.name}
                                    </div>
                                  );
                                }
                                return null;
                              })
                            : null}
                        </div>
                        <FontAwesomeIcon icon={faArrowDown} className="arrow" />
                      </div>
                    </div>

                    <div className="response_options_group">
                      <label>Disabled Roles</label>
                      <div
                        className={`response_options_disable_roles ${
                          newResponse.enabledRoles.length !== 0
                            ? "opacity_5"
                            : ""
                        }`}
                        onClick={showRolesdisable}
                      >
                        <div className="response_options_disable_role_current">
                          {newResponse.disabledRoles.length !== 0 ? (
                            newResponse.disabledRoles.map((role) => {
                              return (
                                <span key={role.id} className="role_picked">
                                  <span
                                    style={{
                                      backgroundColor: `#${role.color
                                        .toString(16)
                                        .padStart(6, "0")
                                        .toUpperCase()}`,
                                    }}
                                    className="roleCircle"
                                  ></span>
                                  <span> {role.name}</span>
                                </span>
                              );
                            })
                          ) : (
                            <span>select role...</span>
                          )}
                        </div>
                        <div className="response_options_disable_roles_form_options">
                          {guildRoles
                            ? roles.map((role) => {
                                if (
                                  !role.name.includes("everyone") &&
                                  role.tags === false
                                ) {
                                  return (
                                    <div
                                      className="response_options_disable_roles__role__option"
                                      key={role.id}
                                      onClick={() => {
                                        handleDisabledRoleChange(role.id);
                                      }}
                                    >
                                      {role.name}
                                    </div>
                                  );
                                }
                                return null;
                              })
                            : null}
                        </div>
                        <FontAwesomeIcon icon={faArrowDown} className="arrow" />
                      </div>
                    </div>
                  </div>

                  <div className="response_options">
                    <div className="response_options_group">
                      <label>Enabled Channels</label>
                      <div
                        className={`response_options_enabled_channels ${
                          newResponse.disabledChannels.length !== 0
                            ? "opacity_5"
                            : ""
                        }`}
                        onClick={showChannelsenable}
                      >
                        <div className="response_options_enabled_channel_current">
                          {newResponse.enabledChannels.length !== 0 ? (
                            newResponse.enabledChannels.map((channel) => {
                              return (
                                <span
                                  key={channel.id}
                                  className="channel_picked"
                                >
                                  <FontAwesomeIcon
                                    className="channelSign"
                                    icon={faHashtag}
                                  />
                                  <span> {channel.name}</span>
                                </span>
                              );
                            })
                          ) : (
                            <span>select channel...</span>
                          )}
                        </div>
                        <div className="response_options_enabled_channels_form_options">
                          {guildChannels
                            ? channels.map((channel) => (
                                <div
                                  className="response_options_enabled_channels__channel__option"
                                  key={channel.id}
                                  onClick={() => {
                                    handleEnabledChannelChange(channel.id);
                                  }}
                                >
                                  {channel.name}
                                </div>
                              ))
                            : null}
                        </div>
                        <FontAwesomeIcon icon={faArrowDown} className="arrow" />
                      </div>
                    </div>

                    <div className="response_options_group">
                      <label>Disabled Channels</label>
                      <div
                        className={`response_options_disable_channels ${
                          newResponse.enabledChannels.length !== 0
                            ? "opacity_5"
                            : ""
                        }`}
                        onClick={showChannelsdisable}
                      >
                        <div className="response_options_disable_channel_current">
                          {newResponse.disabledChannels.length !== 0 ? (
                            newResponse.disabledChannels.map((channel) => {
                              return (
                                <span
                                  key={channel.id}
                                  className="channel_picked"
                                >
                                  <FontAwesomeIcon
                                    className="channelSign"
                                    icon={faHashtag}
                                  />
                                  <span> {channel.name}</span>
                                </span>
                              );
                            })
                          ) : (
                            <span className="response_options_disable_channel_current">
                              select channel...
                            </span>
                          )}
                        </div>
                        <div className="response_options_disable_channels_form_options">
                          {guildChannels
                            ? channels.map((channel) => (
                                <div
                                  className="response_options_disable_channels__channel__option"
                                  key={channel.id}
                                  onClick={() => {
                                    handleDisabledChannelChange(channel.id);
                                  }}
                                >
                                  {channel.name}
                                </div>
                              ))
                            : null}
                        </div>
                        <FontAwesomeIcon icon={faArrowDown} className="arrow" />
                      </div>
                    </div>
                  </div>
                  <BotMessage
                    message={newResponse.response}
                    user={messages.user}
                    timestamp={messages.timestamp}
                    trigger={newResponse.trigger}
                    options={checkedBoxes}
                  />
                </>
              )}
            </div>
          </div>
          <div className="auto_responders__body__responses">
            {responses.length !== 0 ? (
              responses.map((responder, index) => (
                <div
                  className="auto_responders__body__responses_response"
                  key={index}
                  tabIndex={index}
                >
                  {editIndex === index ? (
                    <div className="auto_responders__body__responses_response__body">
                      <div className="auto_responders__body__responses_response__body__editing">
                        <div className="response__body__editing__inputs">
                          <input
                            type="text"
                            placeholder={editingResponses[index].trigger}
                            value={editingResponses[index].trigger}
                            onChange={(e) => handleEditTriggerChange(e, index)}
                          />
                          <input
                            type="text"
                            placeholder={editingResponses[index].response}
                            value={editingResponses[index].response}
                            onChange={(e) => handleEditResponseChange(e, index)}
                          />
                        </div>

                        <div className="response__body__editing__options">
                          {checkboxes.map(({ id, label }) => (
                            <Checkbox
                              key={id}
                              id={id}
                              label={label}
                              checked={editingResponses[index][id]}
                              onToggle={(isChecked) =>
                                handleEditCheckboxChange(id, isChecked, index)
                              }
                            />
                          ))}
                        </div>

                        <div className="response_options">
                          <div className="response_options_group">
                            <label>Enabled Roles</label>
                            <div
                              className={`response_options_enabled_roles ${
                                editingResponses[index].disabledRoles.length !==
                                0
                                  ? "opacity_5"
                                  : ""
                              }`}
                              onClick={showEditRolesenable}
                            >
                              <div className="response_options_enabled_role_current">
                                {editingResponses &&
                                editingResponses[index].enabledRoles.length !==
                                  0 ? (
                                  editingResponses &&
                                  editingResponses[index].enabledRoles.map(
                                    (role) => {
                                      return (
                                        <span
                                          key={role.id}
                                          className="role_picked"
                                        >
                                          <span
                                            style={{
                                              backgroundColor: `#${role.color
                                                .toString(16)
                                                .padStart(6, "0")
                                                .toUpperCase()}`,
                                            }}
                                            className="roleCircle"
                                          ></span>
                                          <span> {role.name}</span>
                                        </span>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>select role...</span>
                                )}
                              </div>
                              <div className="response_options_enabled_roles_form_options">
                                {guildRoles
                                  ? roles.map((role) => {
                                      if (
                                        !role.name.includes("everyone") &&
                                        role.tags === false
                                      ) {
                                        return (
                                          <div
                                            className="response_options_enabled_roles__role__option"
                                            key={role.id}
                                            onClick={() => {
                                              handleEditEnabledRoleChange(
                                                role.id,
                                                index
                                              );
                                            }}
                                          >
                                            {role.name}
                                          </div>
                                        );
                                      }
                                      return null;
                                    })
                                  : null}
                              </div>
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="arrow"
                              />
                            </div>
                          </div>

                          <div className="response_options_group">
                            <label>Disabled Roles</label>
                            <div
                              className={`response_options_disable_roles ${
                                editingResponses[index].enabledRoles.length !==
                                0
                                  ? "opacity_5"
                                  : ""
                              }`}
                              onClick={showEditRolesdisable}
                            >
                              <div className="response_options_disable_role_current">
                                {editingResponses &&
                                editingResponses[index].disabledRoles.length !==
                                  0 ? (
                                  editingResponses &&
                                  editingResponses[index].disabledRoles.map(
                                    (role) => {
                                      return (
                                        <span
                                          key={role.id}
                                          className="role_picked"
                                        >
                                          <span
                                            style={{
                                              backgroundColor: `#${role.color
                                                .toString(16)
                                                .padStart(6, "0")
                                                .toUpperCase()}`,
                                            }}
                                            className="roleCircle"
                                          ></span>
                                          <span> {role.name}</span>
                                        </span>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>select role...</span>
                                )}
                              </div>
                              <div className="response_options_disable_roles_form_options">
                                {guildRoles
                                  ? roles.map((role) => {
                                      if (
                                        !role.name.includes("everyone") &&
                                        role.tags === false
                                      ) {
                                        return (
                                          <div
                                            className="response_options_disable_roles__role__option"
                                            key={role.id}
                                            onClick={() => {
                                              handleEditDisabledRoleChange(
                                                role.id,
                                                index
                                              );
                                            }}
                                          >
                                            {role.name}
                                          </div>
                                        );
                                      }
                                      return null;
                                    })
                                  : null}
                              </div>
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="arrow"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="response_options">
                          <div className="response_options_group">
                            <label>Enabled Channels</label>
                            <div
                              className={`response_options_enabled_channels ${
                                editingResponses[index].disabledChannels
                                  .length !== 0
                                  ? "opacity_5"
                                  : ""
                              }`}
                              onClick={showEditChannelsenable}
                            >
                              <div className="response_options_enabled_channel_current">
                                {editingResponses &&
                                editingResponses[index].enabledChannels
                                  .length !== 0 ? (
                                  editingResponses &&
                                  editingResponses[index].enabledChannels.map(
                                    (channel) => {
                                      return (
                                        <span
                                          key={channel.id}
                                          className="channel_picked"
                                        >
                                          <FontAwesomeIcon
                                            className="channelSign"
                                            icon={faHashtag}
                                          />
                                          <span> {channel.name}</span>
                                        </span>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>select channel...</span>
                                )}
                              </div>
                              <div className="response_options_enabled_channels_form_options">
                                {guildChannels
                                  ? channels.map((channel) => (
                                      <div
                                        className="response_options_enabled_channels__channel__option"
                                        key={channel.id}
                                        onClick={() => {
                                          handleEditEnabledChannelChange(
                                            channel.id,
                                            index
                                          );
                                        }}
                                      >
                                        {channel.name}
                                      </div>
                                    ))
                                  : null}
                              </div>
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="arrow"
                              />
                            </div>
                          </div>

                          <div className="response_options_group">
                            <label>Disabled Channels</label>
                            <div
                              className={`response_options_disable_channels ${
                                editingResponses[index].enabledChannels
                                  .length !== 0
                                  ? "opacity_5"
                                  : ""
                              }`}
                              onClick={showEditChannelsdisable}
                            >
                              <div className="response_options_disable_channel_current">
                                {editingResponses &&
                                editingResponses[index].disabledChannels
                                  .length !== 0 ? (
                                  editingResponses &&
                                  editingResponses[index].disabledChannels.map(
                                    (channel) => {
                                      return (
                                        <span
                                          key={channel.id}
                                          className="channel_picked"
                                        >
                                          <FontAwesomeIcon
                                            className="channelSign"
                                            icon={faHashtag}
                                          />
                                          <span> {channel.name}</span>
                                        </span>
                                      );
                                    }
                                  )
                                ) : (
                                  <span className="response_options_disable_channel_current">
                                    select channel...
                                  </span>
                                )}
                              </div>
                              <div className="response_options_disable_channels_form_options">
                                {guildChannels
                                  ? channels.map((channel) => (
                                      <div
                                        className="response_options_disable_channels__channel__option"
                                        key={channel.id}
                                        onClick={() => {
                                          handleEditDisabledChannelChange(
                                            channel.id,
                                            index
                                          );
                                        }}
                                      >
                                        {channel.name}
                                      </div>
                                    ))
                                  : null}
                              </div>
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="arrow"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="auto_responders__body__responses_response__body__buttons">
                        <button
                          tabIndex={index}
                          className="response_delete_button"
                          onClick={() => handleDelete(index)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                        <button
                          className="response_save_button"
                          onClick={() => handleSaveEdit(index)}
                        >
                          <FontAwesomeIcon icon={faSave} />
                        </button>

                        <button
                          className="response_cancel_button"
                          onClick={handleCancelEdit}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="auto_responders__body__responses_response__header">
                      <div className="auto_responders__body__responses_response__header__body__respones_data">
                        <p className="text-break">{responder.trigger}</p>
                      </div>
                      <div>
                        <button
                          tabIndex={index}
                          onClick={() => handleEdit(index)}
                          className="response_edit_button"
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="auto_responders__body__responses_response__header">
                <div className="auto_responders__body__responses_response">
                  <p className="text-break">
                    <span
                      style={{
                        color: "#f44336",
                        margin: "0 5px",
                      }}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                    No responses yet
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <EditsConfig
        show={isSaveEnabled}
        onChange={(value) => {
          if (value) {
            handleSave();
          } else {
            handleCancel();
          }
        }}
      />
    </div>
  );
};
