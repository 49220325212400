import { useState } from "react";
import { Loading } from "../../components/Loaders/Loading";
import "../../styles/scss/pages/Dashboard/OverView.scss";

export const Welcome = (props) => {
  const loading = props.loading;
  const user = props.StatOfData.user || [];
  const guilds = props.StatOfData.guilds || [];
  const [error, setError] = useState(null);
  const avatar = `https://cdn.discordapp.com/avatars/${user.discordID}/${user.avatar}?size=256`;
  

  return (
    <>
      {/* <Servers guilds={guilds} loading={loading} /> */}
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div className="welcome">
          <div className="welcome__avatar">
            <img src={avatar} alt={user.username} />
          </div>
          <div className="welcome__text">
            <h1>
              Welcome back, <span>{user.username}</span> ;)
            </h1>
            <p>
              You have access to {guilds.length} servers. Select one to get
              started.
            </p>
          </div>
       
        </div>
      )}
    </>
  );
};
