import { isAuth } from "../utils";
export const checkAuth = async () => {
  try {
    const res = await isAuth();
    const data = res.data;

    if (data.isAuth === true) {
      return data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
