export const languagesJson = [
    {
    name: "English",
    code: "en",
    flag: "en",
    dir: "ltr",
    path: process.env.PUBLIC_URL + "/assets/flags/en.png",
    },
    {
    name: "Arabic",
    code: "ar",
    flag: "ar",
    dir: "rtl",
    path: process.env.PUBLIC_URL + '/assets/flags/ar.png'
    },
    {
    name: "French",
    code: "fr",
    flag: "fr",
    dir: "ltr",
    path: process.env.PUBLIC_URL + '/assets/flags/fr.png'
    },
    {
    name: "Spanish",
    code: "es",
    flag: "es",
    dir: "ltr",
    path: process.env.PUBLIC_URL + '/assets/flags/es.png'
    },
]