import React, { useEffect, useRef, useState } from "react";
import { Group, Image, Transformer } from "react-konva";

export const Avatar = ({
  avatarLeft,
  avatarTop,
  avatarRotation,
  img,
  height,
  width,
  onAvatarDragEnd,
  onAvatarTransformEnd,
  avatarShape
}) => {
  const avatarRef = useRef();
  const trRef = useRef();
  const [isAvatarSelected, setIsAvatarSelected] = useState(false);

  useEffect(() => {
    if (isAvatarSelected) {
      trRef.current.nodes([avatarRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isAvatarSelected]);
  const handleTransformEnd = (e) => {
    console.log(e.target);
    const node = avatarRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    const newWidth = Math.max(node.width() * scaleX);
    const newHeight = Math.max(node.height() * scaleY);
    const newData = {
      width: newWidth,
      height: newHeight,
      x: e.target.x(),
      y: e.target.y(),
    };
    onAvatarTransformEnd(newData);
  };

  const handleDragEnd = (e) => {
    const newData = {
      x: e.target.x(),
      y: e.target.y(),
    };
    onAvatarDragEnd(newData);
  };


 if (avatarShape === "circle") {
  return (
    <Group>
      <Image
        ref={avatarRef}
        onClick={() => setIsAvatarSelected(!isAvatarSelected)}
        draggable={true}
        onTap={() => setIsAvatarSelected(!isAvatarSelected)}
        x={avatarLeft}
        y={avatarTop}
        width={width}
        height={height}
        image={img}
        onTransformEnd={handleTransformEnd}
        onDragEnd={handleDragEnd}
        stroke="black"
        cornerRadius={width / 2}
      />

      {isAvatarSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 10 || newBox.height < 10) {
              return oldBox;
            }
            return newBox;
          }}
          rotateEnabled={false}
          keepRatio={false}
          enabledAnchors={[
            // "top-center",
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
            // "bottom-center",
            // "middle-left",
            // "middle-right",
          ]}
        />
      )}
    </Group>
  );
} else if (avatarShape === "square") {
  return (
    <Group>
      <Image
        ref={avatarRef}
        onClick={() => setIsAvatarSelected(!isAvatarSelected)}
        draggable={true}
        onTap={() => setIsAvatarSelected(!isAvatarSelected)}
        x={avatarLeft}
        y={avatarTop}
        width={width}
        height={height}
        image={img}
        onTransformEnd={handleTransformEnd}
        onDragEnd={handleDragEnd}
        stroke="black"
      />

      {isAvatarSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 10 || newBox.height < 10) {
              return oldBox;
            }
            return newBox;
          }}
          rotateEnabled={false}
          keepRatio={false}
          enabledAnchors={[
            // "top-center",
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
            // "bottom-center",
            // "middle-left",
            // "middle-right",
          ]}
        />
      )}
    </Group>
  );
}
}