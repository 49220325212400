import "../../styles/scss/components/Utilities/BotMessage.scss";

import { faCheck } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export const BotMessage = ({ user, message, trigger, timestamp, options }) => {
  const isReply = options && options.reply;
  const isPing = options && options.ping;

  const [avatar, setAvatar] = useState("");
  const [username, setUsername] = useState("");
  const [mentions, setMentions] = useState([]);

  useEffect(() => {
    if (user) {
      setAvatar(
        `https://cdn.discordapp.com/avatars/${user.discordID}/${user.avatar}.png?size=256`
      );
      setUsername(user.username);
    }

  }, [user]);
  useEffect(() => {
    if (message.length > 0) {
      if (!isPing) {
        setMentions(false);
      } else {
        setMentions(true);
      }
    } else return;
  }, [message, isReply, isPing]);

  return message ? (
    isReply ? (
      <li className="bot-message">
        <div className={`${mentions ? "message" : "message-mentions"}`}>
          <div className="message_reply_context">
            <img
              className="avatar"
              src={avatar}
              alt=""
            />
            <span className="username">@ZerwOne</span>
            <div className="repliedTextPreview">
              <div className="repliedTextContent_user">{trigger}</div>
            </div>
          </div>
          <div className="contents">
            <img
              className="bot_avatar"
              src="https://cdn.discordapp.com/avatars/819979690738974731/7927f6326413e6d80bc0eeb3dda86900.webp?size=100"
              aria-hidden="true"
              alt=""
            />
            <h3 className="header">
              <span className="headerText">
                <span
                  className="username_h"
                  aria-expanded="false"
                  role="button"
                >
                  ZerwBot
                </span>
                <span className="botTagCozy">
                  <FontAwesomeIcon icon={faCheck} className="botTagVerified" />
                  <span className="botText">BOT</span>
                </span>
              </span>
              <span className="timestamp">
                <time className="timestampCozy">
                  <i className="separator" aria-hidden="true">
                    —
                  </i>
                  {new Date(timestamp).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",

                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </time>
              </span>
            </h3>
            <div className="messageContent">{message}</div>
          </div>
        </div>
      </li>
    ) : (
      <li className="bot-message">
        <div
          className={`${mentions ? "message-user" : "message-user-mentions"}`}
        >
          <div className="contents">
            <img
              className="bot_avatar"
              src={avatar}
              aria-hidden="true"
              alt=""
            />
            <h3 className="header">
              <span className="headerText">
                <span
                  className="username_h"
                  aria-expanded="false"
                  role="button"
                  tabindex="0"
                >
                  ZerwOne
                </span>
              </span>
              <span className="timestamp">
                <time className="timestampCozy">
                  <i className="separator" aria-hidden="true">
                    —
                  </i>
                  {new Date(timestamp).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",

                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </time>
              </span>
            </h3>
            <div className="messageContent">{trigger}</div>
          </div>
        </div>
        <div className={`${mentions ? "message" : "message-mentions"}`}>
          <div className="contents">
            <img
              className="bot_avatar"
              src="https://cdn.discordapp.com/avatars/819979690738974731/7927f6326413e6d80bc0eeb3dda86900.webp?size=100"
              aria-hidden="true"
              alt=""
            />
            <h3 className="header">
              <span className="headerText">
                <span
                  className="username_h"
                  aria-expanded="false"
                  role="button"
                  tabindex="0"
                >
                  ZerwBot
                </span>
                <span className="botTagCozy">
                  <FontAwesomeIcon icon={faCheck} className="botTagVerified" />
                  <span className="botText">BOT</span>
                </span>
              </span>
              <span className="timestamp">
                <time className="timestampCozy">
                  <i className="separator" aria-hidden="true">
                    —
                  </i>
                  {new Date(timestamp).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",

                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </time>
              </span>
            </h3>
            {isPing ? (
              <div className="messageContent">
                <span className="discord-mention">@ZerwOne </span>, {message}
              </div>
            ) : (
              <div className="messageContent">{message}</div>
            )}
          </div>
        </div>
      </li>
    )
  ) : (
    <div></div>
  );
};
