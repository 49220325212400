import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  isLogged: false,
  user: null,
  guilds: null,
  current: {
    guildID: null,
    guild: null,
    AutoMod: null,
    AutoResponses: null,
    Logs: null,
    Welcome: null,
    channels: null,
    members: null,
    roles: null,
    stickers: null,
    emojis: null,
    messageSent: null,
    messageDeleted: null,
  },
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setGuilds: (state, action) => {
      state.guilds = action.payload;
    },
    setCurrent: (state, action) => {
      switch (action.payload.type) {
        case "currentguild":
          state.current.guild = action.payload.data;
          state.current.guildID = action.payload.data.id;
          break;
        case "channels":
          state.current.channels = action.payload.data;
          break;
        case "members":
          state.current.members = action.payload.data;
          break;
        case "roles":
          state.current.roles = action.payload.data;
          break;
        case "stickers":
          state.current.stickers = action.payload.data;
          break;
        case "emojis":
          state.current.emojis = action.payload.data;
          break;
        case "messageSent":
          state.current.messageSent = action.payload.data;
          break;
        case "messageDeleted":
          state.current.messageDeleted = action.payload.data;
          break;
        case "autoResponses":
          state.current.AutoResponses = action.payload.data.AutoResponse;
          break;
        case "getAutoMod":
          state.current.AutoMod = action.payload.data.AutoMod;
          break;
        case "getLogs":
          state.current.Logs = action.payload.data.Logs;
          break;
        case "getWelcomes":
          state.current.Welcome = action.payload.data.Welcome;
          break;
        default:
          break;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLogged, setUser, setGuilds, setCurrent, setLoading } =
  dataSlice.actions;

export default dataSlice.reducer;
