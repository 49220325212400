import "../../styles/scss/components/loaders/Loading.scss";
import { PropagateLoader } from "react-spinners";
export const ComLoad = (props) => {
  const loading = props.loading;
	
	if (!loading) {
		return null;
	} else {
		return (
    <div className="loading">
      <PropagateLoader color={"red"}
       loading={loading}
        size={15}
        speedMultiplier={.5}

        />
    </div>
  );
  }
};
