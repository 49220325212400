import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage, Text, Transformer } from "react-konva";
import { Loading } from "../Loaders/Loading";
import { Avatar } from "./Konva/Avatar";

export const WelcomeImage = React.memo(
  ({
    avatarUrl,
    username,
    text,
    backgroundUrl,
    avatarSize,
    usernameSize,
    textSize,
    usernameLeft,
    usernameTop,
    textLeft,
    textTop,
    avatarLeft,
    avatarTop,
    avatarHeight,
    avatarWidth,
    onUsernameChange,
    backgroundSize,
    onUsernameDragEnd,
    onUsernameTransform,
    onTextTransform,
    onAvatarDragEnd,
    onTextDragEnd,
    onAvatarTransform,
    onAvatarSizeChange,
    avatarShape,
    avatarRotation,
    backgroundType,
    usernameColor,
    textColor,
    textRotation,
    textHeight,
    textWidth,
    usernameRotation,
    usernameHeight,
    usernameWidth,
    usernameScaleX,
    usernameScaleY,
    textScaleX,
    textScaleY,
  }) => {
    const [welcomeImageLoading, setWelcomeImageLoading] = useState(true);
    const [avatarImage, setAvatarImage] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [isUsernameSelected, setIsUsernameSelected] = useState(false);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const transformerRef = useRef();
    const textTrRef = useRef();
    const usernameRef = useRef();
    const textRef = useRef();
   // console.log(textScaleX, textScaleY);
    useEffect(() => {
      if (
        avatarImage === "" ||
        avatarImage === null ||
        avatarImage === undefined
      ) {
        setWelcomeImageLoading(true);
      } else {
        setWelcomeImageLoading(false);
      }
    }, [avatarUrl, avatarImage, backgroundUrl]);
    useEffect(() => {
      if (!avatarImage) {
        const avatarImg = new window.Image();
        avatarImg.src = avatarUrl;
        avatarImg.onload = () => {
          //get avatar img data (width, height, etc)
         // console.log(avatarImg.width, avatarImg.height);
          const data = {
            src: avatarImg,
            width: avatarImg.width,
            height: avatarImg.height,
          };

          setAvatarImage(data);
        };
      }

      const backgroundImg = new window.Image();
      backgroundImg.src = backgroundUrl;
      backgroundImg.onload = () => {
        setBackgroundImage(backgroundImg);
      };
    }, [avatarImage, backgroundUrl]);

    useEffect(() => {
      if (isUsernameSelected) {
      //  console.log(transformerRef);
        // we need to attach transformer manually
        transformerRef.current?.nodes([usernameRef.current]);
        transformerRef.current?.getLayer().batchDraw();
      }
    }, [isUsernameSelected]);

    useEffect(() => {
      if (isTextSelected) {
        //console.log(textTrRef);
        // we need to attach transformer manually
        textTrRef.current?.nodes([textRef.current]);
        textTrRef.current?.getLayer().batchDraw();
      }
    }, [isTextSelected]);

    const handleAvatarDragEnd = useCallback((event) => {
     // console.log(event);
      onAvatarDragEnd(event);
    }, []);

    const handleAvatarTransformEnd = (e) => {
      // Get the current node and its transformation properties

     // console.log(e);
      onAvatarTransform(e);
    };

    const handleUsernameTransformEnd = (e) => {
      // Get the current node and its transformation properties

      const node = usernameRef.current;
      // we will reset it back

      const newWidth = node.width();

      const newChange = {
        top: e.target.attrs.y,
        left: e.target.attrs.x,
        size: e.target.attrs.fontSize,
        rotation: e.target.attrs.rotation,
        color: e.target.attrs.fill,
        width: newWidth,
        height: node.height(),
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
      };

    //  console.log(e.target);
      onUsernameTransform(newChange);
    };
    const handleTextTransformEnd = (e) => {
      // Get the current node and its transformation properties

      const node = textRef.current;
      // we will reset it back

      const newWidth = node.width();

      const newChange = {
        top: e.target.attrs.y,
        left: e.target.attrs.x,
        size: e.target.attrs.fontSize,
        rotation: e.target.attrs.rotation,
        color: e.target.attrs.fill,
        width: newWidth,
        height: node.height(),
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
      };

      //console.log(newChange);
      onTextTransform(newChange);
    };
    const truncateText = (text, maxLength, textSize, scale, maxWidth) => {
      // Calculate the effective width after applying scale
      const effectiveWidth = maxWidth * scale;
      // Calculate the maximum number of characters that can fit
      const maxChars = Math.floor(effectiveWidth / textSize);
      return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
    };
    const truncatedUsername = truncateText(
      username,
      usernameSize,
      usernameSize,
      usernameScaleX,
      usernameWidth
    );
    const handleContextMenu = (e) => {
      e.evt.preventDefault(); // Prevent the default right-click menu from appearing
    };
    if (welcomeImageLoading) return <Loading loading={welcomeImageLoading} />;
    return (
      <Stage
        width={backgroundType === "image" ? backgroundSize.width : 400}
        height={backgroundType === "image" ? backgroundSize.height : 200}
        onContextMenu={handleContextMenu}

        //  onMouseDown={checkDeselect}
        //  onTouchStart={checkDeselect}
      >
        <Layer>
          {backgroundType === "image" && backgroundImage && (
            <Image
              image={backgroundImage}
              width={backgroundSize.width}
              height={backgroundSize.height}
            />
          )}
        </Layer>
        <Layer>
          <Text
            ref={usernameRef}
            text={truncatedUsername}
            draggable={true}
            x={usernameLeft}
            y={usernameTop}
            fontSize={usernameSize}
            scaleX={usernameScaleX}
            scaleY={usernameScaleY}
            fill={usernameColor}
            rotation={usernameRotation}
            width={usernameWidth}
            height={usernameHeight}
            onDragEnd={onUsernameDragEnd}
            onTransformEnd={handleUsernameTransformEnd}
            onClick={() => setIsUsernameSelected(!isUsernameSelected)}
            align="center"
          />

          {isUsernameSelected && (
            <Transformer
              padding={10}
              ref={transformerRef}
              boundBoxFunc={(oldBox, newBox) => {
                return newBox;
              }}
              rotateEnabled={true}
              keepRatio={true}
              enabledAnchors={[
                "top-center",
                "top-left",
                "top-right",
                "bottom-left",
                "bottom-right",
                "bottom-center",
                "middle-left",
                "middle-right",
              ]}
            />
          )}
          <Text
            ref={textRef}
            text={text}
            draggable={true}
            x={textLeft}
            y={textTop}
            fontSize={textSize}
            fill={textColor}
            scaleX={textScaleX}
            scaleY={textScaleY}
            width={textWidth}
            height={textHeight}
            rotation={textRotation}
            onDragEnd={onTextDragEnd}
            onTransformEnd={handleTextTransformEnd}
            onClick={() => setIsTextSelected(!isTextSelected)}
          />
          {isTextSelected && (
            <Transformer
              padding={10}
              ref={textTrRef}
              boundBoxFunc={(oldBox, newBox) => {
                return newBox;
              }}
              rotateEnabled={true}
              keepRatio={true}
              enabledAnchors={[
                "top-center",
                "top-left",
                "top-right",
                "bottom-left",
                "bottom-right",
                "bottom-center",
                "middle-left",
                "middle-right",
              ]}
            />
          )}
        </Layer>
        <Layer>
          <Avatar
            img={avatarImage.src}
            height={avatarHeight}
            width={avatarWidth}
            onAvatarDragEnd={handleAvatarDragEnd}
            onAvatarTransformEnd={handleAvatarTransformEnd}
            avatarShape={avatarShape}
            avatarRotation={avatarRotation}
            avatarLeft={avatarLeft}
            avatarTop={avatarTop}
          />
        </Layer>
      </Stage>
    );
  }
);
