import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { MoonLoaders } from "../Loaders/MoonLoader";

export const SwitchButtons = ({
  onToggle,
  isDisabled,
  module,
  defaultChecked,
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    console.log(checked, defaultChecked);
    setChecked(defaultChecked);
  }, [defaultChecked]);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    onToggle({
      module: module,
      value: nextChecked,
    });
  };

  if (checked === undefined || checked === "undefined" || checked === null) {
    return <MoonLoaders loading={true} size={30} />;
  }

  return (
    <Switch
      onColor="#f44336"
      uncheckedIcon={false}
      checkedIcon={false}
      disabled={isDisabled ? true : false}
      handleDiameter={20}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.3)"
      onChange={handleChange}
      checked={checked}
    />
  );
};
