import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Menu, MenuItem } from "react-pro-sidebar";
import { Typography } from "./Typography";

export const SideBarLoading = ({
  StatOfData,
  loadingStatus,
  style,
  menuItemStyles,
  collapsed,
}) => {
  return (
    <div style={style}>
      <SkeletonTheme
        className="colorchange"
        baseColor="#202020"
        color="#202225"
        highlightColor="#2f3136"
      >
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem>
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__name"
                height={20}
                width={150}
                count={1}
              />
            </SkeletonTheme>
          </MenuItem>
        </Menu>

        <div
          style={{
            padding: "0 24px",
            marginBottom: "8px",
            marginTop: "32px",
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            style={{
              opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
            }}
          >
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__name"
                height={20}
                width={150}
                count={1}
              />
            </SkeletonTheme>
          </Typography>
        </div>
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem>
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__name"
                height={20}
                width={150}
                count={1}
              />
            </SkeletonTheme>
          </MenuItem>
        </Menu>

        <div
          style={{
            padding: "0 24px",
            marginBottom: "8px",
            marginTop: "32px",
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            style={{
              opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
            }}
          >
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__name"
                height={20}
                width={150}
                count={1}
              />
            </SkeletonTheme>
          </Typography>
        </div>
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem>
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__name"
                height={20}
                width={150}
                count={1}
              />
            </SkeletonTheme>
          </MenuItem>
        </Menu>

        <div
          style={{
            padding: "0 24px",
            marginBottom: "8px",
            marginTop: "32px",
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            style={{
              opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
            }}
          >
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__name"
                height={20}
                width={150}
                count={1}
              />
            </SkeletonTheme>
          </Typography>
        </div>
      </SkeletonTheme>
    </div>
  );
};
