import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink /*useNavigte*/ } from "react-router-dom";
import "../styles/scss/components/NavBar.scss";
// import fontawesome from "@fortawesome/fontawesome";
import {
  faCog,
  faCrown,
  faServer,
  faUser,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Avatar from "@radix-ui/react-avatar";
import * as Menubar from "@radix-ui/react-menubar";
import * as Popover from "@radix-ui/react-popover";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { languagesJson } from "../utils/langs";
import { menuData } from "../utils/menu";
import { MoonLoaders } from "./Loaders/MoonLoader";
export const NavBar = ({ StatOfData, isAuth, isConnected, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  const [isSettingsActive, setSettingsActive] = useState(false);
  const [isUserActive, setUserActive] = useState(false);
  const [isMobileMenu, setMobileMenu] = useState("false");
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languagesJson.find(
    (l) => l.code === currentLanguageCode
  );

  const menu = () => {
    if (isSettingsActive) {
      setSettingsActive(false);
    }
    setMobileMenu(!isMobileMenu);
  };

  const showSettings = () => {
    if (isMobileMenu) {
      setMobileMenu(true);
    }
    if (isActive) {
      setActive(false);
    }
    if (isUserActive) {
      setUserActive(false);
    }
    setSettingsActive(!isSettingsActive);
  };

  const showUser = () => {
    if (isMobileMenu) {
      setMobileMenu(true);
    }
    if (isSettingsActive) {
      setSettingsActive(false);
    }

    setActive(!isActive);
    setUserActive(!isUserActive);
  };

  const currentTheme = () => {
    if (cookies.get("theme") === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }
  };

  useEffect(() => {
    currentTheme();
  }, []);

  const switchTheme = () => {
    if (cookies.get("theme") === "dark") {
      cookies.set("theme", "light");
      document.documentElement.setAttribute("data-theme", "light");
    } else {
      cookies.set("theme", "dark");
      document.documentElement.setAttribute("data-theme", "dark");
    }
  };

  const login = function () {
    window.open(`http://localhost:3000/auth/login`, "_self");
  };

  const logout = function () {
    window.open(`http://localhost:3000/logout`, "_self");
  };

  const user = StatOfData && StatOfData.user;

  const handleDocumentClick = (event) => {
    const isMenuOpen = isActive || isSettingsActive || isUserActive;

    const isClickedInsideMenu = event.target.closest(".navbar") !== null;
    if (isMenuOpen && !isClickedInsideMenu) {
      setActive(false);
      setSettingsActive(false);
      setUserActive(false);
    }
  };
  document.addEventListener("click", handleDocumentClick);

  function isUserLoggedIn() {
    return user != null && user !== undefined;
  }
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.documentElement.lang = currentLanguage.code;
  }, [currentLanguage, t]);

  const switchLanguage = () => {
    setActive(!isActive);
  };
  const handleNavigate = (to) => {
    navigate(to);
  };
  return (
    <div className="navbar">
      <div className={`${isMobileMenu ? "disabled" : "help-menu-mobile"}`}>
        {menuData.map((item) => (
          <NavLink to={item.path} key={item.name}>
            <div className="list-item">{item.name}</div>
          </NavLink>
        ))}
      </div>
      <div className="menu display">
        <button className="menu-btn" onClick={menu}>
          Menu
        </button>
      </div>
      <div className="login">
        <Link className="logo" to={"/"}>
          <img
            className="logo"
            src={`${window.location.origin}/assets/logo.png`}
            alt=""
          />
        </Link>
        <h4 className="bot">Zero Socio</h4>
      </div>
      <div className="help-menu">
        {menuData.map((item) => (
          <NavLink to={item.path} key={item.name}>
            <div className="list-item">{item.name}</div>
          </NavLink>
        ))}
      </div>
      {isAuth === null ? (
        <MoonLoaders size={25} loading={true} />
      ) : (
        isUserLoggedIn() && (
          <Popover.Root>
            <Popover.Trigger asChild>
              <Avatar.Root className="user">
                <Avatar.Image
                  // onClick={showUser}
                  className="AvatarImage IconButton"
                  aria-label="Update dimensions"
                  src={
                    user.avatar
                      ? `https://cdn.discordapp.com/avatars/${user.discordID}/${user.avatar}?size=256`
                      : "https://cdn.discordapp.com/embed/avatars/0.png"
                  }
                  alt={user.username + "'s avatar"}
                />
                <Avatar.Fallback className="AvatarFallback" delayMs={600}>
                  CT
                </Avatar.Fallback>
              </Avatar.Root>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className="PopoverContent profile"
                sideOffset={5}
              >
                {user.banner ? (
                  <img
                    className="banner"
                    src={`https://cdn.discordapp.com/banners/${user.discordID}/${user.banner}.png?size=512`}
                    alt=""
                  />
                ) : (
                  <div
                    className="no-banner"
                    style={{
                      backgroundColor: `${
                        user.banner_color ? user.banner_color : user.accent
                      }`,
                    }}
                  ></div>
                )}
                <img
                  className="avatar"
                  src={
                    user.avatar
                      ? `https://cdn.discordapp.com/avatars/${user.discordID}/${user.avatar}?size=256`
                      : "https://cdn.discordapp.com/embed/avatars/0.png"
                  }
                  alt=""
                />
                <h1>
                  {user.username} <span>{"#" + user.discordTag}</span>
                </h1>
                <div className="break"></div>
                <h2 className="bot">Zero Socio</h2>
                <div className="zeroone">
                  <div className="general">
                    <Link to={"/users/@me"} className="item">
                      <FontAwesomeIcon className="icon" icon={faUser} />
                      profile
                    </Link>
                    <Link to={"/settings"} className="item">
                      <FontAwesomeIcon className="icon" icon={faCog} />
                      Settings
                    </Link>
                    <Link to={"/premium"}  className="item premium">
                      <FontAwesomeIcon className="icon" icon={faCrown} />
                      Premium
                    </Link>
                  </div>
                  {/* <div className="break"></div> */}
                  <h2 className="bot">Manage</h2>
                  <div className="general">
                    <Link to="/servers" className="item">
                      <FontAwesomeIcon className="icon" icon={faServer} />
                      Servers
                    </Link>
                  </div>
                </div>

                <div className="buttons">
                  <button className="logout" onClick={logout}>
                    Logout
                  </button>
                  <Menubar.Root className="MenubarRoot">
                    <Menubar.Menu asChild>
                      <Menubar.Trigger className="MenubarTrigger">
                        <img
                          className="MenubarItem"
                          src={window.location.origin + currentLanguage.path}
                          alt={currentLanguage.code + "flag"}
                        />
                      </Menubar.Trigger>
                      <Menubar.Portal>
                        <Menubar.Content
                          className="MenubarContent"
                          align="start"
                        >
                          {languagesJson.map((lang) => (
                            <Menubar.Item
                              className="MenubarItem"
                              key={lang.code}
                              onClick={() => {
                                if (isMobileMenu) {
                                  setMobileMenu(true);
                                }
                                if (isSettingsActive) {
                                  setSettingsActive(false);
                                }
                                if (isActive) {
                                  setActive(false);
                                }
                                if (isUserActive) {
                                  setUserActive(false);
                                }
                                i18next.changeLanguage(lang.code);
                                cookies.set("i18next", lang.code);
                                setActive(!isActive);
                              }}
                            >
                              {/* <img
                                    className="MenubarItem"
                                      src={window.location.origin + lang.path}
                                      alt={lang.code + "flag"}
                                    /> */}
                              {lang.name}
                            </Menubar.Item>
                          ))}
                        </Menubar.Content>
                      </Menubar.Portal>
                    </Menubar.Menu>
                  </Menubar.Root>
                </div>

                {/* <Popover.Arrow className="PopoverArrow" /> */}
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )
      )}
    </div>
  );
};
