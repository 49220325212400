import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { ServersLoders } from "../components/Loaders/Servers";
import "../styles/scss/pages/Servers.scss";
// import LazyLoad from "react-lazy-load";
// import { Lazy } from "../components/Loaders/Lazy";
// import { useEffect } from "react";

export const Servers = ({ loading, StatOfData }) => {
  const [loading1, setLoading1] = useState(loading);
  const user = StatOfData.user || [];
  const guilds = StatOfData.guilds || [];
  const navigate = useNavigate();
  function isUserLoggedIn() {
    return user != null && user !== undefined;
  }
  const random = Math.floor(Math.random() * 6);
  // guilds counts
  const GuildCount = guilds.length;
  //array of guilds that isBotInServer is true
  let allowedmanage = ["0", "1", "2"];
  //filter out the guilds that isBotInServer is true
  const ManageGuilds = guilds.filter(
    (guild) => guild.isBotInServer && guild.dashboard_permissions != "3"
  );

  const InviteGuilds = guilds.filter((guild) => guild.isBotInServer === false);

  const manageButton = (guild) => {
    var _id = guild.id;

    navigate(`/dashboard/${_id}`);
  };

  const inviteButton = (guild) => {
    var _id = guild.id;

    navigate(`/invite/${_id}`);
  };

  useEffect(() => {
    if(guilds.length > 0){
      setLoading1(false)
    } else {
      setLoading1(true)
    }
  }, [guilds]);
  return isUserLoggedIn() ? (
    <div className="gservers">
      {loading1 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1, marginLeft: "5px", marginRight: "5px" }}>
            <p>
              {" "}
              <Skeleton
                width={360}
                height={30}
                baseColor="#1f1c1c"
                highlightColor="black"
              />
            </p>
          </div>
          <div style={{ flex: 1, marginLeft: "5px", marginRight: "5px" }}>
            <p>
              {" "}
              <Skeleton
                width={40}
                height={30}
                baseColor="#1f1c1c"
                highlightColor="red"
              />
            </p>
          </div>
          <div style={{ flex: 1, marginLeft: "5px", marginRight: "5px" }}>
            <p>
              {" "}
              <Skeleton
                width={100}
                height={30}
                baseColor="#1f1c1c"
                highlightColor="black"
              />
            </p>
          </div>
        </div>
      ) : (
        <h1>
          <span
            style={{
              color: `red`,
            }}
          >
            {user.username}
          </span>
          <span> You </span> are<span> currently</span> in
          <span className="colorchange"> {guilds.length}</span>
          <span> server</span>.
        </h1>
      )}

      {loading1 ? (
        <ServersLoders count={4} manage={true} />
      ) : (
        <div className="guilds">
          {ManageGuilds.length > 0 &&
            ManageGuilds.map((guild) => (
              <div key={guild.id}>
                {/* <Lazy skeleton={skeleton} count={1} /> */}
                <div className="guild" key={guild.id}>
                  {loading ? (
                    <SkeletonTheme
                      className="guild colorchange"
                      height="10rem"
                      width="16rem"
                      style={{
                        position: "absolute",
                        inset: "0px",
                        zIndex: "1",
                        transform: "scale(1.4)",
                        filter: "blur(10px)",
                        opacity: "0.3",
                      }}
                      baseColor="#202020"
                      highlightColor="#444"
                    >
                      <Skeleton count={1} />
                    </SkeletonTheme>
                  ) : (
                    <div
                      style={{
                        background: `${
                          guild.icon
                            ? `url(https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png)
                        center center / cover no-repeat
                        `
                            : `url(https://cdn.discordapp.com/embed/avatars/${random}.png) center center / cover no-repeat`
                        }`,
                        position: "absolute",
                        inset: "0px",
                        zIndex: "1",
                        transform: "scale(1.4)",
                        filter: "blur(10px)",
                        opacity: "0.3",
                      }}
                    ></div>
                  )}
                  {loading1 ? (
                    <SkeletonTheme baseColor="#202020" highlightColor="#444" />
                  ) : (
                    <img
                      style={{
                        border: `2px solid hsl(21deg 100% 43%)`,
                      }}
                      src={
                        guild.icon
                          ? `https://cdn.discordapp.com/icons/${guild.id}/${
                              guild.icon
                            }.${guild.icon.startsWith("a_") ? "gif" : "png"}`
                          : `https://cdn.discordapp.com/embed/avatars/${random}.png`
                      }
                      alt={guild.name}
                    />
                  )}
                  {loading1 ? (
                    <Skeleton
                      className="sk"
                      baseColor="#1f1c1c"
                      highlightColor="black"
                      height={30}
                      count={1}
                    />
                  ) : (
                    <small>
                      {guild.dashboard_permissions
                        ? guild.dashboard_permissions
                            .replace("1", "Admin")
                            .replace("2", "Moderator")
                            .replace("3", "Member")
                            .replace("0", "Owner")
                        : "Member"}
                    </small>
                  )}
                </div>
                <div className="manage">
                  {loading1 ? (
                    <Skeleton
                      baseColor="#1f1c1c"
                      highlightColor="black"
                      height={30}
                      width={100}
                      count={1}
                    />
                  ) : (
                    <h2>{guild.name}</h2>
                  )}
                  {loading1 ? (
                    <Skeleton
                      baseColor="#1f1c1c"
                      highlightColor="black"
                      height={45}
                      width={100}
                      count={1}
                    /> //
                  ) : (
                    <button
                      className="manage-btn"
                      onClick={() => manageButton(guild)}
                    >
                      Manage
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {loading1 ? (
        <ServersLoders count={4} manage={false} />
      ) : (
        <div className="guilds">
          {InviteGuilds.map((guild) => (
            <div className="Bord" key={guild.id}>
              <div className="guild" key={guild.id}>
                {loading ? (
                  <SkeletonTheme
                    className="guild"
                    height="10rem"
                    width="16rem"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      zIndex: "1",
                      transform: "scale(1.4)",
                      filter: "blur(10px)",
                      opacity: "0.3",
                    }}
                    baseColor="#202020"
                    highlightColor="#444"
                  >
                    <Skeleton count={1} />
                  </SkeletonTheme>
                ) : (
                  <div
                    style={{
                      background: `${
                        guild.icon
                          ? `url(https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png)
                        center center / cover no-repeat
                        `
                          : `url(https://cdn.discordapp.com/embed/avatars/${random}.png) center center / cover no-repeat`
                      }`,
                      position: "absolute",
                      inset: "0px",
                      zIndex: "1",
                      transform: "scale(1.4)",
                      filter: "blur(10px)",
                      opacity: "0.3",
                    }}
                  ></div>
                )}
                {loading ? (
                  <SkeletonTheme baseColor="#202020" highlightColor="#444" />
                ) : (
                  <img
                    style={{
                      border: `2px solid black`,
                    }}
                    src={
                      guild.icon
                        ? `https://cdn.discordapp.com/icons/${guild.id}/${
                            guild.icon
                          }.${guild.icon.startsWith("a_") ? "gif" : "png"}`
                        : `https://cdn.discordapp.com/embed/avatars/${random}.png`
                    }
                    alt={guild.name}
                  />
                )}
                {loading1 ? (
                  <Skeleton
                    className="sk"
                    baseColor="#1f1c1c"
                    highlightColor="black"
                    height={30}
                    count={1}
                  />
                ) : (
                  <small>
                    {guild.dashboard_permissions
                      ? guild.dashboard_permissions
                          .replace("1", "Admin")
                          .replace("2", "Moderator")
                          .replace("3", "Member")
                          .replace("0", "Owner")
                      : "Member"}
                  </small>
                )}
              </div>
              <div className="manage">
                {loading1 ? (
                  <Skeleton
                    baseColor="#1f1c1c"
                    highlightColor="black"
                    height={30}
                    width={100}
                    count={1}
                  />
                ) : (
                  <h2>{guild.name}</h2>
                )}
                {loading1 ? (
                  <Skeleton
                    baseColor="#1f1c1c"
                    highlightColor="black"
                    height={45}
                    width={100}
                    count={1}
                  />
                ) : (
                  <button
                    className="manage-btn"
                    onClick={() => inviteButton(guild)}
                  >
                    Invite
                  </button>
                )}{" "}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div className="Servers">Please Login to see your servers</div>
  );
};
