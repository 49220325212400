import English from "../locale/en.json";
import Arabic from "../locale/ar.json";

export const resources = {
    en: {
        translation: English
    },
    ar: {
        translation: Arabic
    }
};