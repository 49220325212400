import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ServersLoders = (props) => {
  const count = props.count || 1;
  const manage = props.manage || false;
  const mBody = [];
  const iBody = [];

  for (let i = 0; i < count; i++) {
    iBody.push(
      <div className="Bord" key={i}>
        <div className="guild" key={i}>
          <SkeletonTheme
            className="guild"
            height="10rem"
            width="16rem"
            style={{
              position: "absolute",
              inset: "0px",
              zIndex: "1",
              transform: "scale(1.4)",
              filter: "blur(10px)",
              opacity: "0.3",
            }}
            baseColor="#202020"
            highlightColor="#444"
          >
            {/* Skeleton elements */}
          </SkeletonTheme>
          <SkeletonTheme baseColor="#202020" highlightColor="#444" />
          <Skeleton
            className="sk"
            baseColor="#1f1c1c"
            highlightColor="black"
            height={30}
            count={1}
          />
        </div>
        <div className="manage">
          <Skeleton
            baseColor="#1f1c1c"
            highlightColor="black"
            height={30}
            width={100}
            count={1}
          />

          <Skeleton
            baseColor="#1f1c1c"
            highlightColor="black"
            height={45}
            width={100}
            count={1}
          />
        </div>
      </div>
    );
  }

  for (let i = 0; i < count; i++) {
    mBody.push(
      <div key={i}>
        <div className="guild">
          <SkeletonTheme
            className="guild colorchange"
            height="10rem"
            width="16rem"
            style={{
              position: "absolute",
              inset: "0px",
              zIndex: "1",
              transform: "scale(1.4)",
              filter: "blur(10px)",
              opacity: "0.3",
            }}
            baseColor="#202020"
            highlightColor="#444"
          >
            <Skeleton count={1} />
          </SkeletonTheme>
          <SkeletonTheme baseColor="#202020" highlightColor="#444" />
          <Skeleton
            className="sk"
            baseColor="#1f1c1c"
            highlightColor="black"
            height={30}
            count={1}
          />
        </div>
        <div className="manage">
          <Skeleton
            baseColor="#1f1c1c"
            highlightColor="black"
            height={30}
            width={100}
            count={1}
          />
          <Skeleton
            baseColor="#1f1c1c"
            highlightColor="black"
            height={45}
            width={100}
            count={1}
          />
        </div>
      </div>
    );
  }

  if (manage) {
    return <div className="guilds">{mBody}</div>;
  } else {
    return <div className="guilds">{iBody}</div>;
  }
};
