import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components/Loaders/Loading";
import "../../styles/scss/pages/InviteBot.scss";
export const BotInvite = (props) => {
  const guilds = props.StatOfData.guilds || [];
  const user = props.StatOfData.user || [];
  const loading = props.loading || false;
const navigate = useNavigate();
  const { id } = useParams();

  const guild = guilds.find((guild) => guild.id == id) || {};

  const random = Math.floor(Math.random() * 6);
  var position = guild.dashboard_permissions
    ? guild.dashboard_permissions
        .replace("1", "Admin")
        .replace("2", "Moderator")
        .replace("3", "Member")
        .replace("0", "Owner")
    : "Member";
  var guildIcon = `${
    guild.icon
      ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`
      : `https://cdn.discordapp.com/embed/avatars/${random}.png`
  }`;

  const inviteBot = () => {
    window.open(
      `https://discord.com/oauth2/authorize?client_id=819979690738974731&scope=bot+applications.commands+applications.commands.permissions.update&guild_id=${guild.id}&permissions=2080374975&response_type=code&redirect_uri=http://localhost:3000/auth/redirect`,
      "_self",
      "noopener,noreferrer"
    );
  };

  const ManageGuild = () => {
    navigate(`/dashboard/${guild.id}`);
  };


  return (
    loading ? (
      <Loading loading={loading} />
      ) : (
      <>
      {guild.isBotInServer ? (
        <div className="invites">
          <div className="already-in-guild">
            <h1>Already in this guild!</h1>
            <p>
              You can manage ZeroOne's settings by clicking the button below.
            </p>
            <button className="already-in-guild-button"onClick={ManageGuild}>Manage</button>
          </div>
        </div>
      ) : (
        <div className="invite-bot">
          <h1>Invite Me!</h1>
          <div className="invite-bot-content">
            <div className="invite-bot-content-left">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo.png"}
                alt="ZeroOne Bot"
              />
              <h1>ZeroOne</h1>
              <p>
                ZeroOne is a multipurpose bot with a lot of features, such as
                moderation, fun, music, economy, and much more!
              </p>
            </div>

            <div className="invite-bot-content-right">
              <img src={guildIcon} alt={guild.name + "'s icon"} />
              <h1>{guild.name}</h1>
              <p>
                You are currently logged in as <span>{user.username} </span>
                You are now a member of this guild and have the{" "}
                <span>{position}</span> position.
              </p>

              <div className="invite-bot-content-right-button">
                <button className="invite-me" onClick={inviteBot}>
                  Invite Me!
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    )
   
  );
};
