import React, { useState } from "react";
import { ChromePicker } from "react-color";

export const ColorPicker = ({ color, onChange,module }) => {
  const [currentColor, setCurrentColor] = useState(color);

  function handleColorChange(newColor) {
    const selectedColor = newColor.rgb;
    const selectedColorHex = newColor.hex;
    setCurrentColor(selectedColor);
    onChange({
      r: selectedColor.r,
      g: selectedColor.g,
      b: selectedColor.b,
      a: selectedColor.a,
      hex: selectedColorHex,
      module: module ? module : null,
    });
  }

  return (
    <div className="zerw-color-picker">
      <ChromePicker
        className="color-picker"
        color={currentColor}
        onChange={handleColorChange}
        disableAlpha={false}
        styles={{
          default: {
            picker: {
              height: "max-content",
              width: "100%",
              borderRadius: "5px",
              boxShadow: "none",
              border: "none",
              background: "#2f3136",
              // background: `rgba(${currentColor.r}, ${currentColor.g}, ${currentColor.b}, ${currentColor.a}`,
            },
            body: {
              padding: ".5rem",
              borderRadius: "5px",
            },
          },
        }}
      />
    </div>
  );
};
