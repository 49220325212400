import React from 'react';
import '../../styles/scss/components/Utilities/EditsConfig.scss';

export const EditsConfig = ({ show, onChange }) => {
    const handleConfirm = () => {
        // Invoke the onChange function with a "true" value
        onChange(true);
    };

    const handleDiscard = () => {
        // Invoke the onChange function with a "false" value
        onChange(false);
    };

    return (
        <div className={`config__edits ${show ? 'show' : ''}`}>
            <p>
                <span>Careful!</span> You can't undo this action.
            </p>

            <div className="config__edits__buttons">
                <button className="config__edits__buttons__cancel"
                    onClick={handleDiscard}
                >
                    Discard
                </button>
                <button className="config__edits__buttons__confirm"
                    onClick={handleConfirm}
                >Confirm</button>
            </div>
        </div>
    );
};
