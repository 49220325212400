import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { ColorPicker } from "./ColorsPicker";
export const WelcomeImageControls = React.memo(
  ({
    onBackgroundUpload,
    onUsernameColorChange,
    onTextColorChange,
    onAddEffect,
    onTextAlignmentChange,
    onAddBackgroundImage,
    onAddBackgroundTypes,
    onAddBackgroundImageSize,
    backgroundType,
    onAddUsernameColor,
    onAddText,
    onAddAvatarShape,
    avatarShapeType
  }) => {
    const [text, setText] = useState("");
    const [usernameColor, setUsernameColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#ffffff");
    const [colorPickerForm, setColorPickerForm] = useState(false);
    const [selectedEffect, setSelectedEffect] = useState("");
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedControl, setSelectedControl] = useState(null);
    const [backgroundLink, setBackgroundLink] = useState("");
//const [avatarShape, setAvatarShape] = useState(avatarShapeType);
    const [inputOpen, setInputOpen] = useState(false);
    const [availableControls, setAvailableControls] = useState([
      { label: "Background", value: "background" },
      { label: "Username", value: "username" },
      { label: "Text", value: "text" },
      { label: "Avatar", value: "avatar" },
      { label: "Effects", value: "effects" },
    ]);


    const [textAlignment, setTextAlignment] = useState("left");
    const handleTextAlignmentChange = (event) => {
      setTextAlignment(event.target.value);
      onTextAlignmentChange(event.target.value);
    };
    function handleTextChange(event) {
      setText(event.target.value);
    }
    const uploadInputRef = useRef(null); // Ref for the upload input element

    const handleControlSelect = (control) => {
      setSelectedControl((prevControl) => {
        if (prevControl === control) {
          return null; // Toggle off if the same control is clicked again
        } else {
          return control;
        }
      });

      setDropdownOpen(false);

      setAvailableControls((prevControls) => {
        const updatedControls = prevControls.filter((c) => c.value !== control);
        if (selectedControl) {
          updatedControls.push({
            label: selectedControl,
            value: selectedControl,
          });
        }
        return updatedControls;
      });
    };

    const toggleDropdown = () => {
      setDropdownOpen((prevOpen) => !prevOpen);
    };
    function handleColorPicker() {
      setColorPickerForm(!colorPickerForm);
    }
    function handleUsernameColorChange(newColor) {
      setUsernameColor(newColor);
      onUsernameColorChange(newColor);
      onAddUsernameColor(newColor);
    }
    function handleTextColorChange(newColor) {
      setTextColor(newColor);
      onTextColorChange(newColor);
    }
    function handleBackgroundTypeChange(event) {
      if (event.target.value === "image") {
        setInputOpen(true);
        onAddBackgroundTypes("image");
      } else if (event.target.value === "transparent") {
        setInputOpen(false);
        onAddBackgroundTypes("transparent");
      }
    }

    useEffect(() => {
     if (backgroundType === "image") {
        setInputOpen(true);
      } else if (backgroundType === "transparent") {
        setInputOpen(false);
      }
    }, [backgroundType]);


   
    function handleAddText(text) {
      onAddText(text);
    }
    const handleAvatarShapeChange = (event) => {
      const newAvatarShape = event.target.value; 

      onAddAvatarShape(newAvatarShape);
     
    };
    const renderSelectedControl = () => {
      if (!selectedControl) return null;

      switch (selectedControl) {
        case "background":
          return (
            <div className="control-panel">
              <div>
                <label className="control-label">Background Type:</label>
                <select
                  className="form-control control-panel"
                  onChange={handleBackgroundTypeChange}
                  value={backgroundType}
                >
                  <option value="image">Image Upload (Link recommended)</option>
                  <option value="transparent">Transparent Background</option>
                </select>
              </div>
              {inputOpen && (
                <div>
                  <label className="control-label" htmlFor="background-link">
                    Background Image:
                  </label>

                  <div className="">
                    <div className="">
                      <div className="file-control">
                        <input
                          style={{
                            borderRadius: "8px 0 0 8px",
                          }}
                          className="form-control"
                          type="text"
                          id="background-link"
                          value={backgroundLink}
                          onChange={handleBackgroundLinkChange}
                          placeholder="Paste Image Link"
                          // disabled={backgroundImage}
                        />
                        <input
                          className="form-control"
                          type="file"
                          id="background-upload"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleBackgroundUpload}
                          ref={uploadInputRef}
                        />
                        <div className="fileupload">
                          <button
                            className="file-control"
                            onClick={handleBackgroundUpload}
                            htmlFor="background-upload"
                          >
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="fileupload"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        case "text":
          return (
            <div className="control-panel">
              <div>
                <label className="control-label">Welcome Text:</label>
                <div className="">
                  <div className="">
                    <div className="file-control">
                      <input
                        style={{
                          borderRadius: "8px 0 0 8px",
                        }}
                        className="form-control"
                        type="text"
                        // value={text}
                        placeholder="Type your welcome text here"
                        onChange={(e) => handleAddText(e.target.value)}
                        // disabled={backgroundImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="control-label">Text Color:</label>

                <div
                  className="form-control control-panel section_color_picker "
                  onClick={handleColorPicker}
                  style={{
                    backgroundColor: `rgba(${textColor.r}, ${textColor.g}, ${textColor.b}, ${textColor.a})`,
                  }}
                />
                {colorPickerForm && (
                  <div>
                    <ColorPicker
                      color={textColor}
                      onChange={handleTextColorChange}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        case "username":
          return (
            <div className="control-panel">
              <div>
                <label className="control-label">Username Color:</label>

                <div
                  className="form-control control-panel section_color_picker "
                  onClick={handleColorPicker}
                  style={{
                    backgroundColor: `rgba(${usernameColor.r}, ${usernameColor.g}, ${usernameColor.b}, ${usernameColor.a})`,
                  }}
                />
                {colorPickerForm && (
                  <div>
                    <ColorPicker
                      color={usernameColor}
                      onChange={handleUsernameColorChange}
                    />
                  </div>
                )}
              </div>
              <div>
                <label className="control-label">
                  Text Alignment:{" "}
                  <span>
                    For limited time, we only support mouse events for text
                    position
                  </span>
                </label>

                <select
                  disabled
                  className="form-control control-panel cursor-not-allowed"
                  id="text-alignment"
                  value={textAlignment}
                  onChange={handleTextAlignmentChange}
                >
                  <option value="left">Disabled for limited time</option>

                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </div>
            </div>
          );
        case "avatar":
          return (
            <div className="control-panel">
              <div>
                <label className="control-label">Avatar Shape:</label>
                <select
                  className="form-control control-panel"
                  onChange={handleAvatarShapeChange}
                  value={avatarShapeType}
                >
                  <option value="circle">Circle</option>
                  <option value="square">Square</option>
                </select>
              </div>
              <div>
                <label className="control-label">
                  More Options Coming Soon!
                  <span>
                    We are beta testing this features and will be adding more
                  </span>
                </label>
                <div></div>
              </div>
            </div>
          );
        case "effects":
          return (
            <div className="control-panel">
              <div>
                <label className="control-label" htmlFor="effect-select">
                  Image Effect:
                  <span>
                    Beta testing this feature, more effects will be added soon
                  </span>
                </label>
                <select
                  disabled
                  className="form-control control-panel cursor-not-allowed"
                  id="effect-select"
                  value={selectedEffect}
                  onChange={handleEffectSelect}
                >
                  <option value="">
                    Select an effect disabled (Beta testing)
                  </option>
                  <option value="grayscale">Grayscale</option>
                  <option value="sepia">Sepia</option>
                  <option value="invert">Invert</option>
                </select>
              </div>
            </div>
          );
        default:
          return null;
      }
    };

    const handleBackgroundUpload = () => {
      const uploadInput = uploadInputRef.current;
      if (uploadInput) {
        uploadInput.click(); // Open the file dialog
        uploadInput.onchange = (event) => {
          const file = event.target.files[0]; // Get the selected file
          const reader = new FileReader();

          reader.onload = (e) => {
            const imageSrc = e.target.result; // Get the image source

            onAddBackgroundImage(imageSrc); // Pass the image source to the onAddBackgroundImage function

            const backgroundImg = new window.Image();
            backgroundImg.src = imageSrc;

            backgroundImg.onload = () => {
              //get the image data
              const imageData = {
                width: backgroundImg.width,
                height: backgroundImg.height,
              };

              onAddBackgroundImageSize(imageData);

              // setBackgroundImage(backgroundImg);
            };
          };

          reader.readAsDataURL(file); // Read the file as data URL
        };
      }
    };

    const handleBackgroundLinkChange = (event) => {
      setBackgroundLink(event.target.value);

      // Pass the image source to the onAddBackgroundImage function

      const backgroundImg = new window.Image();
      backgroundImg.src = event.target.value;

      backgroundImg.onload = () => {
        const imgData = {
          width: backgroundImg.width,
          height: backgroundImg.height,
        };
        onAddBackgroundImageSize(imgData);
      };
      onAddBackgroundImage(event.target.value);
    };

    const handleEffectSelect = (event) => {
      setSelectedEffect(event.target.value);
      onAddEffect(event.target.value);
    };

    return (
      <div className="wlc_module_body_content_image_controls">
        <div className="dropdown">
          <button className="dropdown-toggle" onClick={toggleDropdown}>
            {selectedControl ? `${selectedControl}` : "More Control!"}
          </button>

          {isDropdownOpen && (
            <div className="dropdown-menu">
              {availableControls.map((control) => (
                <button
                  key={control.value}
                  onClick={() => handleControlSelect(control.value)}
                >
                  {control.label}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="selected-control">{renderSelectedControl()}</div>
      </div>
    );
  }
);
