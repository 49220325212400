import "../../../../styles/scss/pages/Dashboard/Servers/Management/Welcome&Goodbye.scss";

import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/fontawesome-free-solid";
import { faHand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SwitchButtons } from "../../../../components/Buttons/SwitchButton";
import { Loading } from "../../../../components/Loaders/Loading";
import { Channels } from "../../../../components/Utilities/Channels";
import { EditsConfig } from "../../../../components/Utilities/EditsConfig";
import { Notify } from "../../../../components/Utilities/Notify";
import { Tooltip } from "../../../../components/Utilities/ToolTip";
import { WelcomeImageControls } from "../../../../components/Utilities/WelcomeControls";
import { WelcomeImage } from "../../../../components/Utilities/WelcomeImage";

export const WelcomeGoodbye = ({ StatOfData, loading, socket }) => {
  const [WGloading, setWGloading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const guildId = path.split("/")[2];
  const guildChannels = StatOfData && StatOfData.current.channels;
  const guildRoles = StatOfData && StatOfData.current.roles;
  const user = StatOfData && StatOfData.user;
  const WelcBye = StatOfData && StatOfData.current.Welcome;
  const guilds = StatOfData.guilds || [];
  const [loadingState, setLoadingState] = useState(true);

  const [roles, setRoles] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isModuleEnable, setisModuleEnable] = useState(false);
  const [msgUserJoin, setmsgUserJoin] = useState(false);
  const [msgUserLeave, setmsgUserLeave] = useState(false);
  const [msgUserJoinImage, setmsgUserJoinImage] = useState(false);
  const [msgUserJoinPrivate, setmsgUserJoinPrivate] = useState(false);
  const [User, setUser] = useState(``);
  const [wlcMessage, setwlcMessage] = useState({});
  const [wlcMessageImage, setwlcMessageImage] = useState({});
  const [wlcMessagePrivate, setwlcMessagePrivate] = useState({});
  const [goodbyeMessage, setgoodbyeMessage] = useState({});
  const [saveForm, setSaveForm] = useState(false);
  const [usernameSize, setUsernameSize] = useState(24);
  const [textSize, settextSize] = useState(30);
  const [avatarShape, setAvatarShape] = useState("circle");
  const [backgroundUrl, setBackgroundUrl] = useState(
    "https://probot.media/yjG5AnGe1g.png"
  );
  const [avatarUrl, setAvatarUrl] = useState(
    "https://cdn.discordapp.com/embed/avatars/0.png"
  );
  const [backgroundType, setBackgroundType] = useState("transparent");
  const [usernameColor, setUsernameColor] = useState("#ffffff");
  const [textColor, setTextColor] = useState("#ffffff");
  const [text, setText] = useState("Welcome to the server");
  const [selectedEffect, setSelectedEffect] = useState("");

  const currentGuild = guilds.find((guild) => guild.id === guildId);
  const handleNavigate = (e) => {
    navigate(e);
  };
  useEffect(() => {
    console.log("WelcBye is mounted");
    if (currentGuild && !currentGuild.id === guildId) {
      window.location.href = "/servers";
      handleNavigate(`/servers`);
    }
    if (
      (currentGuild && currentGuild.id !== guildId) ||
      (currentGuild && currentGuild === undefined)
    ) {
      handleNavigate(`/servers`);
    }
  }, []);

  useEffect(() => {
    if (
      StatOfData &&
      StatOfData.current &&
      StatOfData.current.Welcome &&
      roles &&
      channels
    ) {
     
      setWGloading(false);
    } else {
   
      const initialData = {
        guildId: guildId,
        client: socket.id,
        from: "WelcBye",
      };
      socket.emit("getWelcomes", initialData);
      socket.emit("channels", initialData);
      socket.emit("roles", initialData);
      setWGloading(true);
    }
  }, [StatOfData, guildRoles, guildChannels]);

  useEffect(() => {
    if (WelcBye && WelcBye.welcomeMessage) {
      setwlcMessage(WelcBye.welcomeMessage);
    }
    if (WelcBye && WelcBye.welcomeImage) {
      setwlcMessageImage(WelcBye.welcomeImage);
    }
    if (WelcBye && WelcBye.privateMessage) {
      setwlcMessagePrivate(WelcBye.privateMessage);
    }
    if (WelcBye && WelcBye.leaveMessage) {
      setgoodbyeMessage(WelcBye.leaveMessage);
    }
  }, [WelcBye]);

  useEffect(() => {
    //if (socket && socket.id) {

    socket.on("getWelcomes", (data) => {
      if (data.success && data.updated) {
        Notify({
          type: "update",
          id: data.module,
          updateType: "success",
          message: `Successfully updated.`,
        });
        /*  setmsgUserJoin(false);
          setmsgUserLeave(false);
          setmsgUserJoinImage(false);
          setmsgUserJoinPrivate(false);*/

        // toggleOpen(data.module);
      } else {
        //toggleOpen(data.module);
        setSaveForm(false);
        Notify({
          type: "update",
          id: data.module,
          updateType: "error",
          message: `Failed to update ${data.module} settings.`,
        });
      }
    });
    /* } else {
      Notify({
        message: "Something went wrong, check your connection.",
        type: "error",
      });
    }*/
  }, []);

  useEffect(() => {
    let roless = [];
    let channelss = [];
    if (guildRoles && guildRoles.roles && !roles.length) {
      roless = guildRoles.roles.map((role) => {
        return {
          ...role,
        };
      });

      setRoles(roless);
    }

    if (guildChannels && guildChannels.ChData && !channels.length) {
      channelss = guildChannels.ChData.map((channel) => {
        return {
          id: channel.id,
          name: channel.name,
        };
      });
      setChannels(channelss);
    }
  }, [guildChannels, guildRoles]);
  /*
  useEffect(() => {
    if (
      WelcBye &&
      wlcMessage &&
      wlcMessageImage &&
      wlcMessagePrivate &&
      goodbyeMessage
    ) {
      if (msgUserJoin) {
        console.log(
          "wlcMessage",
          wlcMessage,
          wlcMessage.channel,
          WelcBye.welcomeMessage.channel
        );
        if (wlcMessage.message === WelcBye.welcomeMessage.message) {
          setSaveForm(false);
        }
      }
    } else return;
  }, [wlcMessage, wlcMessageImage, wlcMessagePrivate, goodbyeMessage]);*/

  const handleShapeChange = (shape) => {
    setSaveForm(true);
    setAvatarShape(shape);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        avatarShape: shape,
      },
    }));
  };
  const trRef = useRef();
  const moduleEnable = () => {
    setisModuleEnable(!isModuleEnable);
  };
  const msgUserJoinisOpen = () => {
    if(!WelcBye.enabled) return;
    setmsgUserJoin(!msgUserJoin);
    setmsgUserLeave(false);
    setmsgUserJoinImage(false);
    setmsgUserJoinPrivate(false);
  };
  const msgUserLeaveisOpen = () => {
    if(!WelcBye.enabled) return;

    setmsgUserLeave(!msgUserLeave);
    setmsgUserJoin(false);
    setmsgUserJoinImage(false);
    setmsgUserJoinPrivate(false);
  };
  const msgUserJoinImageisOpen = () => {
    if(!WelcBye.enabled) return;

    setmsgUserJoinImage(!msgUserJoinImage);
    setmsgUserJoin(false);
    setmsgUserLeave(false);
    setmsgUserJoinPrivate(false);
  };
  const msgUserJoinPrivateisOpen = () => {
    if(!WelcBye.enabled) return;

    setmsgUserJoinPrivate(!msgUserJoinPrivate);
    setmsgUserJoin(false);
    setmsgUserLeave(false);
    setmsgUserJoinImage(false);
  };

  /////////////////////////
  useEffect(() => {
    if (user && user.avatar) {
      setUser(user);

      const avatarUrl = `https://cdn.discordapp.com/avatars/${user.discordID}/${user.avatar}?size=256`;

      setAvatarUrl(avatarUrl);
    } else {
      console.log("Something went wrong. user.avatar");
    
    }
  }, [user]);

  // const avatarSize = 100; // Adjust the avatar size as needed

  /* Wlc  handlers*/
  const handleWelcomePrivateMessage = (e) => {
    setSaveForm(true);
    setwlcMessagePrivate({ ...wlcMessagePrivate, message: e.target.value });
  };

  const handleChangeGoodbyeMessage = (e) => {
    setSaveForm(true);
    setgoodbyeMessage({ ...goodbyeMessage, message: e.target.value });
  };

  const handleChangeWlcMessage = (e) => {
    setSaveForm(true);
    setwlcMessage({ ...wlcMessage, message: e.target.value });
  };

  const handleWlcMessageChannels = (callback) => {
    setSaveForm(true);
    setwlcMessage({
      ...wlcMessage,
      channel: callback.value ? callback.value : [],
    });
  };
  const handleGoodbyeMessageChannels = (callback) => {
    setSaveForm(true);
    setgoodbyeMessage({
      ...goodbyeMessage,
      channel: callback.value,
    });
  };

  const handleWlcImageChannels = (callback) => {
    setSaveForm(true);
    setwlcMessageImage({
      ...wlcMessageImage,
      channel: callback.value,
    });
  };

  const handleUsernameDragEnd = useCallback((event) => {
    setSaveForm(true);
    console.log(event.target.attrs);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        username_left: event.target.attrs.x,
        username_top: event.target.attrs.y,
      },
    }));
  }, []);

  const handleUsernameTransformEnd = useCallback((data) => {
    console.log(data);
    setSaveForm(true);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,

        username_height: data.height,
        username_width: data.width,
        username_rotation: data.rotation,
        username_left: data.left,
        username_top: data.top,
        username_size: data.size,
        username_scaleX: data.scaleX,
        username_scaleY: data.scaleY,
      },
    }));
  });

  const handleTextTransformEnd = useCallback((data) => {
    setSaveForm(true);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        text_height: data.height,
        text_width: data.width,
        text_rotation: data.rotation,
        text_left: data.left,
        text_top: data.top,
        text_size: data.size,
        text_scaleX: data.scaleX,
        text_scaleY: data.scaleY,
      },
    }));
  });
  const handleAvatarTransformEnd = useCallback((data) => {
    setSaveForm(true);

    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        avatar_height: data.height,
        avatar_width: data.width,
        radius: data.radius,
        avatar_rotation: data.rotation,
        avatar_left: data.x,
        avatar_top: data.y,
      },
    }));
  });
  const handleAvatarDragEnd = useCallback((event) => {
    setSaveForm(true);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        avatar_left: event.x,
        avatar_top: event.y,
      },
    }));
  }, []);

  const handleTextDragEnd = useCallback((event) => {
    setSaveForm(true);

    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,

        text_left: event.target.attrs.x,
        text_top: event.target.attrs.y,
      },
    }));
  }, []);
  const handleBackgroundUpload = (url) => {
    setSaveForm(true);
    setBackgroundUrl(url);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        backgroundUrl: url,
      },
    }));
  };
  const handleBackgroundTypes = (type) => {
    setSaveForm(true);

    setBackgroundType(type);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        backgroundType: type,
      },
    }));
  };
  const handleBackgroundSize = (size) => {
    console.log("size", size);
    setSaveForm(true);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        backgroundSize: size,
      },
    }));
  };
  const handleUsernameColorChange = (color) => {
    setSaveForm(true);

    setUsernameColor(color);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        usernameColor: color.hex,
      },
    }));
  };
  const handleTextChange = (text) => {
    setSaveForm(true);

    setText(text);
  };
  const handleTextColorChange = (color) => {
    setSaveForm(true);

    setTextColor(color);
    setwlcMessageImage((prevState) => ({
      ...prevState,
      canvas: {
        ...prevState.canvas,
        textColor: color.hex,
      },
    }));
  };
  const handleAddEffect = (effect) => {
    setSaveForm(true);

    setSelectedEffect(effect);
  };

  const handleAvatarSizeChange = useCallback((event, node) => {
    setSaveForm(true);
    //const newAvatarSize = parseInt(event.target.value);
    //setAvatarSize(newAvatarSize);
    //  node.width(newAvatarSize);
    //node.height(newAvatarSize);
  }, []);

  /*Welcome Message Image end*/

  const handleCancel = () => {
    setSaveForm(false);
    setwlcMessage(WelcBye.welcomeMessage);
    setwlcMessageImage(WelcBye.welcomeImage);
    setwlcMessagePrivate(WelcBye.privateMessage);
    setgoodbyeMessage(WelcBye.leaveMessage);
    setmsgUserJoin(false);
    setmsgUserLeave(false);
    setmsgUserJoinImage(false);
    setmsgUserJoinPrivate(false);
  };

  const handleSave = async () => {
    toast.loading(`Updating welcome module`, {
      toastId: "updateWelcome",
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
    });
    setSaveForm(false);

    await new Promise((resolve) => setTimeout(resolve, 2000));
    if (msgUserJoin) {
      const data = {
        guildId: guildId,
        client: socket.id,
        from: "welcomeMessage",
        model: wlcMessage,
      };

      socket.emit("updateWelcomes", data);
    } else if (msgUserJoinImage) {
      const data = {
        guildId: guildId,
        client: socket.id,
        from: "welcomeImage",
        model: wlcMessageImage,
      };
      socket.emit("updateWelcomes", data);
    } else if (msgUserJoinPrivate) {
      const data = {
        guildId: guildId,
        client: socket.id,
        from: "privateMessage",
        model: wlcMessagePrivate,
      };
      socket.emit("updateWelcomes", data);
    } else if (msgUserLeave) {
      const data = {
        guildId: guildId,
        client: socket.id,
        from: "leaveMessage",
        model: goodbyeMessage,
      };
      socket.emit("updateWelcomes", data);
    } else {
      return Notify({
        message: "Update failed, please try again.",
        type: "error",
      });
    }
  };

  const handleToggle = (module) => {
    switch (module.module) {
      case "welcomeMessage":
        socket.emit("welcomesSwitch", {
          guildId: guildId,
          client: socket.id,
          from: "welcomeMessage",
          value: module.value,
        });
        break;
      case "welcomeImage":
        socket.emit("welcomesSwitch", {
          guildId: guildId,
          client: socket.id,
          from: "welcomeImage",
          value: module.value,
        });
        break;
      case "privateMessage":
        socket.emit("welcomesSwitch", {
          guildId: guildId,
          client: socket.id,
          from: "privateMessage",
          value: module.value,
        });
        break;
      case "leaveMessage":
        socket.emit("welcomesSwitch", {
          guildId: guildId,
          client: socket.id,
          from: "leaveMessage",
          value: module.value,
        });
        break;
      default:
        break;
    }
  };
  const handleModuleSwitch = (module) => {
    socket.emit("moduleSwitch", {
      guildId,
      client: socket.id,
      from: "moduleSwitch",
      module: module.module,
      value: module.value,
    });
  };
  return WGloading ? (
    <Loading loading={WGloading} />
  ) : (
    <div className="dashcon">
      <div className="container">
        <div className="dashboard_header">
          <h2>
            Welcome & Goodbye
            <span>
              <Tooltip text="Welcome & Goodbye module allows you to set a welcome and goodbye message for your server." />
            </span>
          </h2>
          <SwitchButtons
            module="Welcome&Goodbye"
            isDisabled={false}
            onToggle={handleModuleSwitch}
            defaultChecked={WelcBye.enabled}
          />
        </div>
        <div className="dashboard_content wlc_dashboard_content">
          <div className="wlc_module">
            <div className="wlc_module_header">
              <div style={{ display: "flex", flexDirection: "revert" }}>
                <div className="logs_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>
                <div className="wlc_module_header_content">
                  <h2 className="wlc_module_header_title">Welcome Message</h2>
                  <p className="wlc_module_header_description">
                    Send a message when a user joins the server
                  </p>
                </div>
              </div>
              {/* Rest of the JSX code */}
              <div className="arrowsupanddown" onClick={msgUserJoinisOpen}>
                <div className="arrowsupanddown_button">
                  <FontAwesomeIcon
                    icon={msgUserJoin ? faChevronUp : faChevronDown}
                    className="arrowsupanddown_button_icon"
                  />
                </div>
              </div>
            </div>
            <div className={`${msgUserJoin ? "wlc_module_body open" : ""}`}>
              {msgUserJoin && (
                <div className="wlc_module_body open">
                  <div className="separator_body"></div>

                  <div className="wlc_module_body_header">
                    <h3></h3>
                    <SwitchButtons
                      onToggle={handleToggle}
                      module={"welcomeMessage"}
                      defaultChecked={WelcBye.welcomeMessage.enabled}
                      isDisabled={false}
                    />
                  </div>
                  <div className="wlc_module_body_content">
                    <div className="wlc_module_body_content_message">
                      <h3>Customize your welcome message</h3>

                      <div>
                        <textarea
                          className="wlc_module_message"
                          placeholder="Type here .."
                          value={wlcMessage.message}
                          name="welcomeMessage"
                          onChange={handleChangeWlcMessage}
                        />
                      </div>
                    </div>

                    <div className="wlc_module_body_content_send_channel">
                      <div className="response_options">
                        <div className="avoid-types">
                          <div className="avoid-type">
                            <h2>Select the channels you want to log into</h2>

                            <Channels
                              className="avoid"
                              onToggle={handleWlcMessageChannels}
                              channels={channels}
                              pickedChannels={
                                wlcMessage ? wlcMessage.channel : []
                              }
                              module={"welcomeMessage"}
                              single={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wlc_module_body_content_variables">
                      <h3>
                        Variables <span>Learn more</span>
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="wlc_module">
            <div className="wlc_module_header">
              <div style={{ display: "flex", flexDirection: "revert" }}>
                <div className="logs_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>
                <div className="wlc_module_header_content">
                  <h2 className="wlc_module_header_title">
                    Welcome Message Image
                  </h2>
                  <p className="wlc_module_header_description">
                    Send an image when a user joins the server
                  </p>
                </div>
              </div>

              <div className="arrowsupanddown" onClick={msgUserJoinImageisOpen}>
                <div className="arrowsupanddown_button">
                  <FontAwesomeIcon
                    icon={msgUserJoinImage ? faChevronUp : faChevronDown}
                    className="arrowsupanddown_button_icon"
                  />
                </div>
              </div>
            </div>
            <div
              className={`${msgUserJoinImage ? "wlc_module_body open" : ""}`}
            >
              {msgUserJoinImage && (
                <div className="wlc_module_body open">
                  <div className="separator_body"></div>

                  <div className="wlc_module_body_header">
                    <h3></h3>
                    <SwitchButtons
                      onToggle={handleToggle}
                      module={"welcomeImage"}
                      defaultChecked={WelcBye.welcomeImage.enabled}
                      isDisabled={false}
                    />
                  </div>
                  <div className="wlc_module_body_content">
                    <div className="wlc_module_body_content_message">
                      <div className="response_options">
                        <div className="avoid-types">
                          <div className="avoid-type">
                            <h2>Select the channels you want to log into</h2>

                            <Channels
                              className="avoid"
                              onToggle={handleWlcImageChannels}
                              channels={channels}
                              pickedChannels={
                                wlcMessageImage ? wlcMessageImage.channel : []
                              }
                              module={"wlcMessageImage"}
                              single={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="wlc_module_body_content_image">
                      {/* <div>
                        <label htmlFor="avatarSize">Avatar Size:</label>
                        <input
                          type="number"
                          id="avatarSize"
                          value={avatarSize}
                          onChange={(e) =>
                            handleAvatarSizeChange(e, trRef.current)
                          }
                        />
                      </div>
                      <div>
                        <label htmlFor="usernameSize">Username Size:</label>
                        <input
                          type="number"
                          id="usernameSize"
                          value={usernameSize}
                          onChange={(e) =>
                            setUsernameSize(parseInt(e.target.value))
                          }
                        />
                      </div> */}
                      <WelcomeImage
                        avatarUrl={avatarUrl}
                        username={"ZerwOne"}
                        text={text}
                        textSize={textSize}
                        textColor={wlcMessageImage.canvas.textColor}
                        textRotation={wlcMessageImage.canvas.text_rotation}
                        textHeight={wlcMessageImage.canvas.text_height}
                        textWidth={wlcMessageImage.canvas.text_width}
                        usernameSize={wlcMessageImage.canvas.username_size}
                        usernameLeft={wlcMessageImage.canvas.username_left}
                        usernameTop={wlcMessageImage.canvas.username_top}
                        usernameColor={wlcMessageImage.canvas.usernameColor}
                        usernameRotation={
                          wlcMessageImage.canvas.username_rotation
                        }
                        usernameScaleX={wlcMessageImage.canvas.username_scaleX}
                        usernameScaleY={wlcMessageImage.canvas.username_scaleY}
                        textScaleX={wlcMessageImage.canvas.text_scaleX}
                        textScaleY={wlcMessageImage.canvas.text_scaleY}
                        usernameHeight={wlcMessageImage.canvas.username_height}
                        usernameWidth={wlcMessageImage.canvas.username_width}
                        textTop={wlcMessageImage.canvas.text_top}
                        textLeft={wlcMessageImage.canvas.text_left}
                        backgroundUrl={wlcMessageImage.canvas.backgroundUrl}
                        backgroundType={wlcMessageImage.canvas.backgroundType}
                        backgroundSize={wlcMessageImage.canvas.backgroundSize}
                        effect={selectedEffect}
                        avatarSize={100}
                        avatarLeft={wlcMessageImage.canvas.avatar_left}
                        avatarTop={wlcMessageImage.canvas.avatar_top}
                        avatarHeight={wlcMessageImage.canvas.avatar_height}
                        avatarWidth={wlcMessageImage.canvas.avatar_width}
                        avatarRotation={wlcMessageImage.canvas.avatar_rotation}
                        onUsernameDragEnd={handleUsernameDragEnd}
                        onUsernameTransform={handleUsernameTransformEnd}
                        onAvatarTransform={handleAvatarTransformEnd}
                        onTextTransform={handleTextTransformEnd}
                        onAvatarDragEnd={handleAvatarDragEnd}
                        onTextDragEnd={handleTextDragEnd}
                        onAvatarSizeChange={handleAvatarSizeChange}
                        avatarShape={wlcMessageImage.canvas.avatarShape}
                      />
                    </div>
                    <WelcomeImageControls
                      onBackgroundUpload={handleBackgroundUpload}
                      onUsernameColorChange={handleUsernameColorChange}
                      onTextColorChange={handleTextColorChange}
                      onAddEffect={handleAddEffect}
                      onAddBackgroundImage={handleBackgroundUpload}
                      onAddBackgroundTypes={handleBackgroundTypes}
                      onAddBackgroundImageSize={handleBackgroundSize}
                      backgroundType={wlcMessageImage.canvas.backgroundType}
                      avatarShapeType={wlcMessageImage.canvas.avatarShape}
                      onAddUsernameColor={handleUsernameColorChange}
                      onAddText={handleTextChange}
                      onAddAvatarShape={handleShapeChange}
                    />
                    {/* <div className="wlc_module_body_content_image_controls">
                      <label>
                        <input
                          type="radio"
                          value="square"
                          checked={avatarShape === "square"}
                          onChange={() => handleShapeChange("square")}
                        />
                        Square
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="circle"
                          checked={avatarShape === "circle"}
                          onChange={() => handleShapeChange("circle")}
                        />
                        Circle
                      </label>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="wlc_module">
            <div className="wlc_module_header">
              <div style={{ display: "flex", flexDirection: "revert" }}>
                <div className="logs_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>
                <div className="wlc_module_header_content">
                  <h2 className="wlc_module_header_title">
                    Welcome Message Private
                  </h2>
                  <p className="wlc_module_header_description">
                    Send a private message when a user joins the server
                  </p>
                </div>
              </div>
              {/* Rest of the JSX code */}
              <div
                className="arrowsupanddown"
                onClick={msgUserJoinPrivateisOpen}
              >
                <div className="arrowsupanddown_button">
                  <FontAwesomeIcon
                    icon={msgUserJoinPrivate ? faChevronUp : faChevronDown}
                    className="arrowsupanddown_button_icon"
                  />
                </div>
              </div>
            </div>
            <div
              className={`${msgUserJoinPrivate ? "wlc_module_body open" : ""}`}
            >
              {msgUserJoinPrivate && (
                <div className="wlc_module_body open">
                  <div className="separator_body"></div>

                  <div className="wlc_module_body_header">
                    <h3></h3>
                    <SwitchButtons
                      onToggle={handleToggle}
                      module={"privateMessage"}
                      defaultChecked={WelcBye.privateMessage.enabled}
                      isDisabled={false}
                    />
                  </div>
                  <div className="wlc_module_body_content">
                    <div className="wlc_module_body_content_message">
                      <h3>Customize your welcome message</h3>

                      <div>
                        <textarea
                          className="wlc_module_message"
                          placeholder="Type here .."
                          value={wlcMessagePrivate.message}
                          name="welcomeMessage"
                          onChange={handleWelcomePrivateMessage}
                        />
                      </div>
                    </div>

                    <div className="wlc_module_body_content_variables">
                      <h3>
                        Variables <span>Learn more</span>
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="wlc_module">
            <div className="wlc_module_header">
              <div style={{ display: "flex", flexDirection: "revert" }}>
                <div className="logs_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>
                <div className="wlc_module_header_content">
                  <h2 className="wlc_module_header_title">Goodbye Message</h2>
                  <p className="wlc_module_header_description">
                    Send a message when a user leaves the server
                  </p>
                </div>
              </div>
              {/* Rest of the JSX code */}
              <div className="arrowsupanddown" onClick={msgUserLeaveisOpen}>
                <div className="arrowsupanddown_button">
                  <FontAwesomeIcon
                    icon={msgUserLeave ? faChevronUp : faChevronDown}
                    className="arrowsupanddown_button_icon"
                  />
                </div>
              </div>
            </div>
            <div className={`${msgUserLeave ? "wlc_module_body open" : ""}`}>
              {msgUserLeave && (
                <div className="wlc_module_body open">
                  <div className="separator_body"></div>

                  <div className="wlc_module_body_header">
                    <h3></h3>
                    <SwitchButtons
                      onToggle={handleToggle}
                      module={"leaveMessage"}
                      defaultChecked={WelcBye.leaveMessage.enabled}
                      isDisabled={false}
                    />
                  </div>
                  <div className="wlc_module_body_content">
                    <div className="wlc_module_body_content_message">
                      <h3>Customize your goodbye message</h3>

                      <div>
                        <textarea
                          className="wlc_module_message"
                          placeholder="Type here .."
                          value={goodbyeMessage.message}
                          name="welcomeMessage"
                          onChange={handleChangeGoodbyeMessage}
                        />
                      </div>
                    </div>

                    <div className="wlc_module_body_content_send_channel">
                      <div className="response_options">
                        <div className="avoid-types">
                          <div className="avoid-type">
                            <h2>Select the channels you want to log into</h2>

                            <Channels
                              className="avoid"
                              onToggle={handleGoodbyeMessageChannels}
                              channels={channels}
                              pickedChannels={
                                goodbyeMessage ? goodbyeMessage.channel : []
                              }
                              module={"goodbyeMessage"}
                              single={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wlc_module_body_content_variables">
                      <h3>
                        Variables <span>Learn more</span>
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <EditsConfig
        show={saveForm}
        onChange={(value) => {
          if (value) {
            handleSave();
          } else {
            handleCancel();
          }
        }}
      />
    </div>
  );
};
