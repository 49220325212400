import axios from "axios";

export function isAuth() {
  return axios.get("http://localhost:3000/auth", {
    "Content-Type": "application/json",
    withCredentials: true,
  });
}

export function getUser() {
  return axios.get("http://localhost:3000/user", {
    "Content-Type": "application/json",
    withCredentials: true,
  });
}

export function getGuilds() {
  return axios.get("http://localhost:3000/guild", {
    "Content-Type": "application/json",
    withCredentials: true,
  });
}

export function getChannels(id) {
  return axios.get(`http://localhost:3000/guild/${id}/channels`, {
    "Content-Type": "application/json",
    withCredentials: true,
  });
}
export function getMembers(id) {
	return axios.get(`http://localhost:3000/guild/${id}/members`, {
	  "Content-Type": "application/json",
	  withCredentials: true,
	});
  }
