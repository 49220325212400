import { useEffect } from 'react';
import io from 'socket.io-client';
const socket = io.connect('http://localhost:3000');

export const Chat = () => {
const Send = () => {
    socket.emit('chat message', 'Hello World');
}

useEffect(() => {
    socket.on('received', (data) => {
        alert(data);
    }
    )
}, [socket])

return (
    <div className='app'>
        <input type="text"  placeholder='Message...'/>
        <button onClick={Send}>Send</button>
    </div>
)

}