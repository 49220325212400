import React, { useState } from "react";
import Select from "react-select";
import "../../styles/scss/components/Utilities/Channels.scss";

export const Roles = ({ roles, pickedRoles, onToggle, module }) => {
  const [selectedOption, setSelectedOption] = useState(pickedRoles);
  const filteredRoles = roles.filter((role) => role.name !== "@everyone");

  const ch = filteredRoles.map((role) => {
    return { value: role.id, label: role.name };
  });
  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const handleToggle = (role) => {
    setSelectedOption(role);
    onToggle({
      module: module,
      value: role,
    });
  };

  return (
    <Select
      classNamePrefix="channels_picker_form"
      defaultValue={selectedOption}
      onChange={handleToggle}
      menuPortalTarget={document.body}
      options={ch}
      isMulti={true}
      isSearchable={true}
      isClearable={true}
      isRtl={false}
      backspaceRemovesValue={true}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          backgroundColor: "#36393f",
        }),

        control: (base) => ({
          ...base,
          WebkitBoxAlign: "center",
          backgroundColor: "rgb(255, 255, 255)",
          alignItems: "center",
          borderColor: "rgb(204, 204, 204)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          cursor: "pointer",
          display: "flex",
          flexWrap: "wrap",
          WebkitBoxPack: "justify",
          justifyContent: "space-between",
          minHeight: "38px",
          transition: "all 100ms ease 0s",
          outline: "0px !important",
        }),

        menu: (base) => ({
          ...base,
          backgroundColor: "#36393f",
          color: "#fff",
          borderRadius: "0.5rem",
          border: "none",
          boxShadow: "0 0 0.5rem #000",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          padding: "0.5rem",
          width: "100%",
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? "#f44336" : "#36393f",
          borderRadius: "0.5rem",
          color: "#fff",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#f44336",
          },
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: "#f44336",
          borderRadius: "0.5rem",
          color: "#fff",
          cursor: "pointer",
          minWidth: "0px",
          WebkitAlignItems: "center",
        }),

        multiValueRemove: (base) => ({
          ...base,
          borderRadius: "0.5rem",
          color: "#fff",
          "&:hover": {
            color: "#f44336",
            backgroundColor: "#36393f",
          },
        }),
        valueContainer: (base) => ({
          ...base,
          padding: "0.5rem",
        }),
      }}
    />
  );
};
