import "../../../../styles/scss/pages/Dashboard/Servers/Modaration/AutoMod.scss";

import { faBan, faCog } from "@fortawesome/fontawesome-free-solid";
import { faHand, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { SwitchButtons } from "../../../../components/Buttons/SwitchButton";
import { Loading } from "../../../../components/Loaders/Loading";
import { Channels } from "../../../../components/Utilities/Channels";
import { Notify } from "../../../../components/Utilities/Notify";
import { Roles } from "../../../../components/Utilities/Roles";
import { Tooltip } from "../../../../components/Utilities/ToolTip";

export const AutoMod = ({ StatOfData, socket }) => {
  const [loading, setLoading] = useState(true);
  const guildChannels = StatOfData && StatOfData.current.channels;
  const guildRoles = StatOfData && StatOfData.current.roles;
  const [roles, setRoles] = useState([]);
  const [channels, setChannels] = useState([]);
  const [switchValue, setSwitchValue] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [progress, setProgress] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState({
    badWords: false,
    repeatedText: false,
    excessiveEmojis: false,
    spam: false,
    excessiveMentions: false,
    excessiveEmojis: false,
    externalLinks: false,
    discordInvites: false,
    emojiSpam: false,
    zalgo: false,
    spamCaps: false,
    spoiler: false,
  });
  const [newBadWords, setNewBadWords] = useState([]);
  const [newRepeatedText, setNewRepeatedText] = useState([]);
  const [newExcessiveEmojis, setNewExcessiveEmojis] = useState([]);
  const [newSpam, setNewSpam] = useState([]);
  const [newExcessiveMentions, setNewExcessiveMentions] = useState([]);
  const [newExternalLinks, setNewExternalLinks] = useState([]);
  const [newDiscordInvites, setNewDiscordInvites] = useState([]);
  const [newEmojiSpam, setNewEmojiSpam] = useState([]);
  const [newZalgo, setNewZalgo] = useState([]);
  const [newSpamCaps, setNewSpamCaps] = useState([]);
  const AutoMod = StatOfData && StatOfData.current.AutoMod;
  const location = useLocation();
  const path = location.pathname;
  const guildId = path.split("/")[2];

  useEffect(() => {
    if (StatOfData && StatOfData.current && StatOfData.current.AutoMod && AutoMod && roles && channels) {
      setLoading(false);
    } else {
      const initialData = {
        guildId: guildId,
        client: socket.id,
        from: "getAutoMod",
      };
      socket.emit("getAutoMod", initialData);
      
      setLoading(true);
    }
  }, [StatOfData, guildRoles, guildChannels]);

  useEffect(() => {
    console.log(AutoMod, "AutoMod");
    if (AutoMod && AutoMod.badWords) {
      setNewBadWords(AutoMod.badWords);
    }
    if (AutoMod && AutoMod.repeatedText) {
      setNewRepeatedText(AutoMod.repeatedText);
    }
    if (AutoMod && AutoMod.excessiveEmojis) {
      setNewExcessiveEmojis(AutoMod.excessiveEmojis);
    }
    if (AutoMod && AutoMod.spam) {
      setNewSpam(AutoMod.spam);
    }
    if (AutoMod && AutoMod.excessiveMentions) {
      setNewExcessiveMentions(AutoMod.excessiveMentions);
    }
    if (AutoMod && AutoMod.externalLinks) {
      setNewExternalLinks(AutoMod.externalLinks);
    }
    if (AutoMod && AutoMod.discordInvites) {
      setNewDiscordInvites(AutoMod.discordInvites);
    }
    if (AutoMod && AutoMod.emojiSpam) {
      setNewEmojiSpam(AutoMod.emojiSpam);
    }
    if (AutoMod && AutoMod.zalgo) {
      setNewZalgo(AutoMod.zalgo);
    }
    if (AutoMod && AutoMod.spamCaps) {
      setNewSpamCaps(AutoMod.spamCaps);
    }
  }, [AutoMod]);

  useEffect(() => {
   // if (socket && socket.id) {


      socket.on("getAutoMod", (data) => {
        if (data.success) {
          Notify({
            type: "update",
            id: data.module,
            updateType: "success",
            message: `Successfully updated ${data.module} settings.`,
          });
        } else {
          Notify({
            type: "update",
            id: data.module,
            updateType: "error",
            message: `Failed to update ${data.module} settings.`,
          });
        }
      });
  /*  } else {
      Notify({
        message: "Something went wrong, check your connection.",
        type: "error",
      });
    }*/
  }, []);

  useEffect(() => {
    let roless = [];
    let channelss = [];
    if (guildRoles && guildRoles.roles && !roles.length) {
      roless = guildRoles.roles.map((role) => {
        return {
          ...role,
        };
      });

      setRoles(roless);
    }

    if (guildChannels && guildChannels.ChData && !channels.length) {
      channelss = guildChannels.ChData.map((channel) => {
        return {
          id: channel.id,
          name: channel.name,
        };
      });
      setChannels(channelss);
    }
  }, [guildChannels, guildRoles]);

  const handleModuleSwitch = (callback) => {
    let initialData = {
      client: socket.id,
      guildId: guildId,
      module: callback.module,
      value: callback.value,
    };

    switch (callback.module) {
      case "AutoMod": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "badWords": {
        socket.emit("AutoModAction", initialData);
        break;
      }
      case "repeatedText": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "excessiveEmojis": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "spam": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "excessiveMentions": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "externalLinks": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "discordInvites": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "emojiSpam": {
        socket.emit("AutoModAction", initialData);
        break;
      }

      case "zalgo": {
        socket.emit("AutoModAction", initialData);

        break;
      }
      case "spamCaps":
        {
          socket.emit("AutoModAction", initialData);

          break;
        }
        socket.emit("AutoModAction", initialData);
      default: {
        break;
      }
    }
  };

  const handleDeleteMessageAction = (callback) => {
    switch (callback.module) {
      case "badWords": {
        if (callback.value === true) {
          setNewBadWords({
            ...newBadWords,
            actions: [...newBadWords.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newBadWords.actions.filter(
            (item) => item !== "delete"
          );
          setNewBadWords({
            ...newBadWords,
            actions: [...filtered],
          });
        }
        break;
      }
      case "repeatedText": {
        if (callback.value === true) {
          setNewRepeatedText({
            ...newRepeatedText,
            actions: [...newRepeatedText.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newRepeatedText.actions.filter(
            (item) => item !== "delete"
          );
          setNewRepeatedText({
            ...newRepeatedText,
            actions: [...filtered],
          });
        }
        break;
      }
      case "excessiveEmojis": {
        if (callback.value === true) {
          setNewExcessiveEmojis({
            ...newExcessiveEmojis,
            actions: [...newExcessiveEmojis.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newExcessiveEmojis.actions.filter(
            (item) => item !== "delete"
          );
          setNewExcessiveEmojis({
            ...newExcessiveEmojis,
            actions: [...filtered],
          });
        }
        break;
      }
      case "spam": {
        if (callback.value === true) {
          setNewSpam({
            ...newSpam,
            actions: [...newSpam.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newSpam.actions.filter((item) => item !== "delete");
          setNewSpam({
            ...newSpam,
            actions: [...filtered],
          });
        }
        break;
      }
      case "excessiveMentions": {
        if (callback.value === true) {
          setNewExcessiveMentions({
            ...newExcessiveMentions,
            actions: [...newExcessiveMentions.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newExcessiveMentions.actions.filter(
            (item) => item !== "delete"
          );
          setNewExcessiveMentions({
            ...newExcessiveMentions,
            actions: [...filtered],
          });
        }
        break;
      }
      case "externalLinks": {
        if (callback.value === true) {
          setNewExternalLinks({
            ...newExternalLinks,
            actions: [...newExternalLinks.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newExternalLinks.actions.filter(
            (item) => item !== "delete"
          );
          setNewExternalLinks({
            ...newExternalLinks,
            actions: [...filtered],
          });
        }
        break;
      }
      case "discordInvites": {
        if (callback.value === true) {
          setNewDiscordInvites({
            ...newDiscordInvites,
            actions: [...newDiscordInvites.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newDiscordInvites.actions.filter(
            (item) => item !== "delete"
          );
          setNewDiscordInvites({
            ...newDiscordInvites,
            actions: [...filtered],
          });
        }
        break;
      }
      case "emojiSpam": {
        if (callback.value === true) {
          setNewEmojiSpam({
            ...newEmojiSpam,
            actions: [...newEmojiSpam.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newEmojiSpam.actions.filter(
            (item) => item !== "delete"
          );
          setNewEmojiSpam({
            ...newEmojiSpam,
            actions: [...filtered],
          });
        }
        break;
      }
      case "zalgo": {
        if (callback.value === true) {
          setNewZalgo({
            ...newZalgo,
            actions: [...newZalgo.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newZalgo.actions.filter((item) => item !== "delete");
          setNewZalgo({
            ...newZalgo,
            actions: [...filtered],
          });
        }
        break;
      }
      case "spamCaps": {
        if (callback.value === true) {
          setNewSpamCaps({
            ...newSpamCaps,
            actions: [...newSpamCaps.actions, "delete"],
          });
        } else {
          //remove bad words from actions
          let filtered = newSpamCaps.actions.filter(
            (item) => item !== "delete"
          );
          setNewSpamCaps({
            ...newSpamCaps,
            actions: [...filtered],
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  };
  const handleMutetUserAction = (callback) => {
    switch (callback.module) {
      case "badWords": {
        if (callback.value === true) {
          setNewBadWords({
            ...newBadWords,
            actions: [...newBadWords.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newBadWords.actions.filter((item) => item !== "mute");
          setNewBadWords({
            ...newBadWords,
            actions: [...filtered],
          });
        }
      }
      case "repeatedText": {
        if (callback.value === true) {
          setNewRepeatedText({
            ...newRepeatedText,
            actions: [...newRepeatedText.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newRepeatedText.actions.filter(
            (item) => item !== "mute"
          );
          setNewRepeatedText({
            ...newRepeatedText,
            actions: [...filtered],
          });
        }
      }
      case "excessiveEmojis": {
        if (callback.value === true) {
          setNewExcessiveEmojis({
            ...newExcessiveEmojis,
            actions: [...newExcessiveEmojis.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newExcessiveEmojis.actions.filter(
            (item) => item !== "mute"
          );
          setNewExcessiveEmojis({
            ...newExcessiveEmojis,
            actions: [...filtered],
          });
        }
      }
      case "spam": {
        if (callback.value === true) {
          setNewSpam({
            ...newSpam,
            actions: [...newSpam.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newSpam.actions.filter((item) => item !== "mute");
          setNewSpam({
            ...newSpam,
            actions: [...filtered],
          });
        }
      }
      case "excessiveMentions": {
        if (callback.value === true) {
          setNewExcessiveMentions({
            ...newExcessiveMentions,
            actions: [...newExcessiveMentions.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newExcessiveMentions.actions.filter(
            (item) => item !== "mute"
          );
          setNewExcessiveMentions({
            ...newExcessiveMentions,
            actions: [...filtered],
          });
        }
      }
      case "externalLinks": {
        if (callback.value === true) {
          setNewExternalLinks({
            ...newExternalLinks,
            actions: [...newExternalLinks.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newExternalLinks.actions.filter(
            (item) => item !== "mute"
          );
          setNewExternalLinks({
            ...newExternalLinks,
            actions: [...filtered],
          });
        }
      }
      case "discordInvites": {
        if (callback.value === true) {
          setNewDiscordInvites({
            ...newDiscordInvites,
            actions: [...newDiscordInvites.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newDiscordInvites.actions.filter(
            (item) => item !== "mute"
          );
          setNewDiscordInvites({
            ...newDiscordInvites,
            actions: [...filtered],
          });
        }
      }
      case "emojiSpam": {
        if (callback.value === true) {
          setNewEmojiSpam({
            ...newEmojiSpam,
            actions: [...newEmojiSpam.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newEmojiSpam.actions.filter((item) => item !== "mute");
          setNewEmojiSpam({
            ...newEmojiSpam,
            actions: [...filtered],
          });
        }
      }
      case "zalgo": {
        if (callback.value === true) {
          setNewZalgo({
            ...newZalgo,
            actions: [...newZalgo.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newZalgo.actions.filter((item) => item !== "mute");
          setNewZalgo({
            ...newZalgo,
            actions: [...filtered],
          });
        }
      }
      case "spamCaps": {
        if (callback.value === true) {
          setNewSpamCaps({
            ...newSpamCaps,
            actions: [...newSpamCaps.actions, "mute"],
          });
        } else {
          //remove bad words from actions
          let filtered = newSpamCaps.actions.filter((item) => item !== "mute");
          setNewSpamCaps({
            ...newSpamCaps,
            actions: [...filtered],
          });
        }
      }
      default: {
        break;
      }
    }
  };

  const handleTimeoutUserAction = (callback) => {
    if (callback.value === true) {
      setNewBadWords({
        ...newBadWords,
        actions: [...newBadWords.actions, "timeout"],
      });
    } else {
      //remove bad words from actions
      let filtered = newBadWords.actions.filter((item) => item !== "timeout");
      setNewBadWords({
        ...newBadWords,
        actions: [...filtered],
      });
    }
  };

  const handleExcludeChannels = (callback) => {
    switch (callback.module) {
      case "badWords": {
        setNewBadWords({
          ...newBadWords,
          excludedChannels: callback.value,
        });
        break;
      }
      case "repeatedText": {
        setNewRepeatedText({
          ...newRepeatedText,
          excludedChannels: callback.value,
        });
        break;
      }
      case "excessiveEmojis": {
        setNewExcessiveEmojis({
          ...newExcessiveEmojis,
          excludedChannels: callback.value,
        });
        break;
      }
      case "spam": {
        setNewSpam({
          ...newSpam,
          excludedChannels: callback.value,
        });
        break;
      }
      case "excessiveMentions": {
        setNewExcessiveMentions({
          ...newExcessiveMentions,
          excludedChannels: callback.value,
        });
        break;
      }
      case "externalLinks": {
        setNewExternalLinks({
          ...newExternalLinks,
          excludedChannels: callback.value,
        });
        break;
      }
      case "discordInvites": {
        setNewDiscordInvites({
          ...newDiscordInvites,
          excludedChannels: callback.value,
        });
      }
      case "emojiSpam": {
        setNewEmojiSpam({
          ...newEmojiSpam,
          excludedChannels: callback.value,
        });
        break;
      }
      case "zalgo": {
        setNewZalgo({
          ...newZalgo,
          excludedChannels: callback.value,
        });
        break;
      }
      case "spamCaps": {
        setNewSpamCaps({
          ...newSpamCaps,
          excludedChannels: callback.value,
        });
        break;
      }
      default: {
        break;
      }
    }
  };
  const handleExcludeRoles = (callback) => {
    switch (callback.module) {
      case "badWords": {
        setNewBadWords({
          ...newBadWords,
          excludedRoles: callback.value,
        });
        break;
      }
      case "repeatedText": {
        setNewRepeatedText({
          ...newRepeatedText,
          excludedRoles: callback.value,
        });
        break;
      }
      case "excessiveEmojis": {
        setNewExcessiveEmojis({
          ...newExcessiveEmojis,
          excludedRoles: callback.value,
        });
        break;
      }
      case "spam": {
        setNewSpam({
          ...newSpam,
          excludedRoles: callback.value,
        });
        break;
      }
      case "excessiveMentions": {
        setNewExcessiveMentions({
          ...newExcessiveMentions,
          excludedRoles: callback.value,
        });
        break;
      }
      case "externalLinks": {
        setNewExternalLinks({
          ...newExternalLinks,
          excludedRoles: callback.value,
        });
        break;
      }
      case "discordInvites": {
        setNewDiscordInvites({
          ...newDiscordInvites,
          excludedRoles: callback.value,
        });
      }
      case "emojiSpam": {
        setNewEmojiSpam({
          ...newEmojiSpam,
          excludedRoles: callback.value,
        });
        break;
      }
      case "zalgo": {
        setNewZalgo({
          ...newZalgo,
          excludedRoles: callback.value,
        });
        break;
      }
      case "spamCaps": {
        setNewSpamCaps({
          ...newSpamCaps,
          excludedRoles: callback.value,
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSave = async (module, value) => {
    switch (module) {
      case "badWords": {
        if (newBadWords === AutoMod.badWords) return;
        break;
      }
      case "repeatedText": {
        if (newRepeatedText === AutoMod.repeatedText) return;
        break;
      }
      case "excessiveEmojis": {
        if (newExcessiveEmojis === AutoMod.excessiveEmojis) return;
        break;
      }
      case "spam": {
        if (newSpam === AutoMod.spam) return;
        break;
      }
      case "excessiveMentions": {
        if (newExcessiveMentions === AutoMod.excessiveMentions) return;
        break;
      }
      case "externalLinks": {
        if (newExternalLinks === AutoMod.externalLinks) return;
        break;
      }
      case "discordInvites": {
        if (newDiscordInvites === AutoMod.discordInvites) return;
        break;
      }
      case "emojiSpam": {
        if (newEmojiSpam === AutoMod.emojiSpam) return;
        break;
      }
      case "zalgo": {
        if (newZalgo === AutoMod.zalgo) return;
        break;
      }
      case "spamCaps": {
        if (newSpamCaps === AutoMod.spamCaps) return;
        break;
      }
      default: {
        break;
      }
    }
    toast.loading(`Updating ${module} settings...`, {
      toastId: module,
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
    });
    try {
      console.log(value, "callback");
      let initialData = {
        client: socket.id,
        guildId: guildId,
        module: module,
        value: value,
      };
      await new Promise((resolve) => setTimeout(resolve, 2000));

      switch (module) {
        case "badWords": {
          socket.emit("updateAutoMod", initialData);
          setNewBadWords([]);
          setModalIsOpen({ ...modalIsOpen, badWords: false });
          break;
        }
        case "repeatedText": {
          socket.emit("updateAutoMod", initialData);
          setNewRepeatedText([]);
          setModalIsOpen({ ...modalIsOpen, repeatedText: false });
          break;
        }
        case "excessiveEmojis": {
          socket.emit("updateAutoMod", initialData);
          setNewExcessiveEmojis([]);
          setModalIsOpen({ ...modalIsOpen, excessiveEmojis: false });
          break;
        }
        case "spam": {
          socket.emit("updateAutoMod", initialData);
          setNewSpam([]);
          setModalIsOpen({ ...modalIsOpen, spam: false });
          break;
        }
        case "excessiveMentions": {
          socket.emit("updateAutoMod", initialData);
          setNewExcessiveMentions([]);
          setModalIsOpen({ ...modalIsOpen, excessiveMentions: false });
          break;
        }
        case "externalLinks": {
          socket.emit("updateAutoMod", initialData);
          setNewExternalLinks([]);
          setModalIsOpen({ ...modalIsOpen, externalLinks: false });
          break;
        }
        case "discordInvites": {
          socket.emit("updateAutoMod", initialData);
          setNewDiscordInvites([]);
          setModalIsOpen({ ...modalIsOpen, discordInvites: false });
          break;
        }
        case "emojiSpam": {
          socket.emit("updateAutoMod", initialData);
          setNewEmojiSpam([]);
          setModalIsOpen({ ...modalIsOpen, emojiSpam: false });
          break;
        }
        case "zalgo": {
          socket.emit("updateAutoMod", initialData);
          setNewZalgo([]);
          setModalIsOpen({ ...modalIsOpen, zalgo: false });
          break;
        }
        case "spamCaps": {
          socket.emit("updateAutoMod", initialData);
          setNewSpamCaps([]);
          setModalIsOpen({ ...modalIsOpen, spamCaps: false });
          break;
        }
        default: {
          break;
        }
      }
    } catch (error) {
      // Update the toast to an error message if something goes wrong
      toast.update(module, {
        render: `Failed to update ${module} settings....`,
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeOnClick: true,
      });
    }
  };

  return loading ? (
    <Loading loading={loading} />
  ) : (
    <div className="dashcon">
      <div className="container">
        <div className="dashboard_header">
          <h2>
            AutoMod
            <span>
              <Tooltip text="Ensure server safety with automated moderation and equip your moderators with robust moderation capabilities." />
            </span>
          </h2>
          <SwitchButtons
            module="AutoMod"
            isDisabled={false}
            onToggle={handleModuleSwitch}
            defaultChecked={AutoMod.enabled}
          />
        </div>
        <div className="dashboard_content_automod">
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">
                    Bad Words Filter
                  </h2>
                  <p className="automod_module_header_description">
                    Filter out bad words from your server.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  module="badWords"
                  onToggle={handleModuleSwitch}
                  isDisabled={false}
                  defaultChecked={AutoMod.badWords && AutoMod.badWords.enabled}
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, badWords: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.badWords}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              preventScroll={true}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, badWords: false });
                setNewBadWords(AutoMod.badWords);
              }}
              role={"dialog"}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} /> Bad
                  Words Filter
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>What should happen when a bad word is detected?</h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the bad word.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={false}
                          module="badWords"
                          onToggle={handleDeleteMessageAction}
                          defaultChecked={
                            AutoMod.badWords &&
                            AutoMod.badWords.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the bad
                          word.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="badWords"
                          defaultChecked={
                            AutoMod.badWords &&
                            AutoMod.badWords.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the bad
                          word.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module="badWords"
                          defaultChecked={
                            AutoMod.badWords &&
                            AutoMod.badWords.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        pickedChannels={
                          AutoMod.badWords
                            ? AutoMod.badWords.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                        module="badWords"
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        pickedRoles={
                          AutoMod.badWords ? AutoMod.badWords.excludedRoles : []
                        }
                        onToggle={handleExcludeRoles}
                        module="badWords"
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, badWords: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() => handleSave("badWords", newBadWords)}
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">Repeated Text</h2>
                  <p className="automod_module_header_description">
                    Filters out messages that contain the same text multiple
                    times in a row.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="repeatedText"
                  onToggle={handleModuleSwitch}
                  defaultChecked={
                    AutoMod.repeatedText && AutoMod.repeatedText.enabled
                  }
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, repeatedText: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.repeatedText}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, repeatedText: false });
                setNewBadWords(AutoMod.repeatedText);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  Repeated Text
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>What should happen when a repeated text is detected?</h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the bad word.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="repeatedText"
                          defaultChecked={
                            AutoMod.repeatedText &&
                            AutoMod.repeatedText.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="repeatedText"
                          defaultChecked={
                            AutoMod.repeatedText &&
                            AutoMod.repeatedText.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module="repeatedText"
                          defaultChecked={
                            AutoMod.repeatedText &&
                            AutoMod.repeatedText.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        module="repeatedText"
                        pickedChannels={
                          AutoMod.repeatedText
                            ? AutoMod.repeatedText.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        module="repeatedText"
                        pickedRoles={
                          AutoMod.repeatedText
                            ? AutoMod.repeatedText.excludedRoles
                            : []
                        }
                        onToggle={handleExcludeRoles}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, repeatedText: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() => handleSave("repeatedText", newRepeatedText)}
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">
                    Excessive Emojis
                  </h2>
                  <p className="automod_module_header_description">
                    Filters out messages that contain an excessive amount of
                    emojis.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="excessiveEmojis"
                  onToggle={handleModuleSwitch}
                  defaultChecked={
                    AutoMod.excessiveEmojis && AutoMod.excessiveEmojis.enabled
                  }
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, excessiveEmojis: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.excessiveEmojis}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, excessiveEmojis: false });
                setNewBadWords(AutoMod.excessiveEmojis);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  Excessive Emojis
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>
                    What should happen when a excessive emojis is detected?
                  </h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the Excessive Emojis.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="excessiveEmojis"
                          defaultChecked={
                            AutoMod.excessiveEmojis &&
                            AutoMod.excessiveEmojis.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="excessiveEmojis"
                          defaultChecked={
                            AutoMod.excessiveEmojis &&
                            AutoMod.excessiveEmojis.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module="excessiveEmojis"
                          defaultChecked={
                            AutoMod.excessiveEmojis &&
                            AutoMod.excessiveEmojis.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        pickedChannels={
                          AutoMod.excessiveEmojis
                            ? AutoMod.excessiveEmojis.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                        module={"excessiveEmojis"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        pickedRoles={
                          AutoMod.excessiveEmojis
                            ? AutoMod.excessiveEmojis.excludedRoles
                            : []
                        }
                        onToggle={handleExcludeRoles}
                        module={"excessiveEmojis"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, excessiveEmojis: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() =>
                    handleSave("excessiveEmojis", newExcessiveEmojis)
                  }
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">SPAM</h2>
                  <p className="automod_module_header_description">
                    Filters out messages that are deemed as spam, such as
                    messages that contain repetitive or irrelevant content.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="spam"
                  onToggle={handleModuleSwitch}
                  defaultChecked={AutoMod.spam && AutoMod.spam.enabled}
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() => setModalIsOpen({ ...modalIsOpen, spam: true })}
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.spam}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, spam: false });
                setNewBadWords(AutoMod.spam);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} /> SPAM
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>What should happen when a SPAM is detected?</h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the SPAM.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="spam"
                          defaultChecked={
                            AutoMod.spam &&
                            AutoMod.spam.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="spam"
                          defaultChecked={
                            AutoMod.spam &&
                            AutoMod.spam.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module={"spam"}
                          defaultChecked={
                            AutoMod.spam &&
                            AutoMod.spam.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        pickedChannels={
                          AutoMod.spam ? AutoMod.spam.excludedChannels : []
                        }
                        onToggle={handleExcludeChannels}
                        module={"spam"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        pickedRoles={
                          AutoMod.spam ? AutoMod.spam.excludedRoles : []
                        }
                        onToggle={handleExcludeRoles}
                        module={"spam"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, spam: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() => handleSave("spam", newSpam)}
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">
                    Excessive Mentions{" "}
                  </h2>
                  <p className="automod_module_header_description">
                    Filters out messages that contain an excessive amount of
                    mentions of users or roles.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="excessiveMentions"
                  onToggle={handleModuleSwitch}
                  defaultChecked={
                    AutoMod.excessiveMentions &&
                    AutoMod.excessiveMentions.enabled
                  }
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, excessiveMentions: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.excessiveMentions}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, excessiveMentions: false });
                setNewBadWords(AutoMod.excessiveMentions);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  Excessive Mentions
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>
                    What should happen when a Excessive Mentions is detected?
                  </h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the Excessive Mentions.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="excessiveMentions"
                          defaultChecked={
                            AutoMod.excessiveMentions &&
                            AutoMod.excessiveMentions.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="excessiveMentions"
                          defaultChecked={
                            AutoMod.excessiveMentions &&
                            AutoMod.excessiveMentions.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module={"excessiveMentions"}
                          defaultChecked={
                            AutoMod.excessiveMentions &&
                            AutoMod.excessiveMentions.actions.includes(
                              "timeout"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        pickedChannels={
                          AutoMod.excessiveMentions
                            ? AutoMod.excessiveMentions.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                        module={"excessiveMentions"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        pickedRoles={
                          AutoMod.excessiveMentions
                            ? AutoMod.excessiveMentions.excludedRoles
                            : []
                        }
                        onToggle={handleExcludeRoles}
                        module={"excessiveMentions"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, excessiveMentions: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() =>
                    handleSave("excessiveMentions", newExcessiveMentions)
                  }
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">
                    External Links
                  </h2>
                  <p className="automod_module_header_description">
                    Filters out messages that contain external links to other
                    websites.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="externalLinks"
                  onToggle={handleModuleSwitch}
                  defaultChecked={
                    AutoMod.externalLinks && AutoMod.externalLinks.enabled
                  }
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, externalLinks: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.externalLinks}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, externalLinks: false });
                setNewBadWords(AutoMod.externalLinks);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  External Links
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>What should happen when a External Links is detected?</h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the External Links.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="externalLinks"
                          defaultChecked={
                            AutoMod.externalLinks &&
                            AutoMod.externalLinks.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="externalLinks"
                          defaultChecked={
                            AutoMod.externalLinks &&
                            AutoMod.externalLinks.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module={"externalLinks"}
                          defaultChecked={
                            AutoMod.externalLinks &&
                            AutoMod.externalLinks.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        pickedChannels={
                          AutoMod.externalLinks
                            ? AutoMod.externalLinks.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                        module={"externalLinks"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        pickedRoles={
                          AutoMod.externalLinks
                            ? AutoMod.externalLinks.excludedRoles
                            : []
                        }
                        onToggle={handleExcludeRoles}
                        module={"externalLinks"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, externalLinks: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() => handleSave("externalLinks", newExternalLinks)}
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">
                    Discord Invites
                  </h2>
                  <p className="automod_module_header_description">
                    Filters out messages that contain invites to Discord
                    servers.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="discordInvites"
                  onToggle={handleModuleSwitch}
                  defaultChecked={
                    AutoMod.discordInvites && AutoMod.discordInvites.enabled
                  }
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, discordInvites: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.discordInvites}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, discordInvites: false });
                setNewBadWords(AutoMod.discordInvites);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  Discord Invites
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>
                    What should happen when a Discord Invites is detected?
                  </h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the Discord Invites.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="discordInvites"
                          defaultChecked={
                            AutoMod.discordInvites &&
                            AutoMod.discordInvites.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="discordInvites"
                          defaultChecked={
                            AutoMod.discordInvites &&
                            AutoMod.discordInvites.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module={"discordInvites"}
                          defaultChecked={
                            AutoMod.discordInvites &&
                            AutoMod.discordInvites.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        pickedChannels={
                          AutoMod.discordInvites
                            ? AutoMod.discordInvites.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                        module={"discordInvites"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        pickedRoles={
                          AutoMod.discordInvites
                            ? AutoMod.discordInvites.excludedRoles
                            : []
                        }
                        onToggle={handleExcludeRoles}
                        module={"discordInvites"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, discordInvites: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() =>
                    handleSave("discordInvites", newDiscordInvites)
                  }
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">Emoji Spam</h2>
                  <p className="automod_module_header_description">
                    Filters out messages that contain an excessive amount of the
                    same emoji.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="emojiSpam"
                  onToggle={handleModuleSwitch}
                  defaultChecked={
                    AutoMod.emojiSpam && AutoMod.emojiSpam.enabled
                  }
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, emojiSpam: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.emojiSpam}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, emojiSpam: false });
                setNewBadWords(AutoMod.emojiSpam);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  Emoji Spam
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>What should happen when a Emoji Spam is detected?</h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the Emoji Spam.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="emojiSpam"
                          defaultChecked={
                            AutoMod.emojiSpam &&
                            AutoMod.emojiSpam.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="emojiSpam"
                          defaultChecked={
                            AutoMod.emojiSpam &&
                            AutoMod.emojiSpam.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module={"emojiSpam"}
                          defaultChecked={
                            AutoMod.emojiSpam &&
                            AutoMod.emojiSpam.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        pickedChannels={
                          AutoMod.emojiSpam
                            ? AutoMod.emojiSpam.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                        module={"emojiSpam"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        pickedRoles={
                          AutoMod.emojiSpam
                            ? AutoMod.emojiSpam.excludedRoles
                            : []
                        }
                        onToggle={handleExcludeRoles}
                        module={"emojiSpam"}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, emojiSpam: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() => handleSave("emojiSpam", newEmojiSpam)}
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">Zalgo</h2>
                  <p className="automod_module_header_description">
                    Filters out messages that use Zalgo text, which involves
                    adding diacritical marks to letters to create a creepy or
                    distorted effect.{" "}
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="zalgo"
                  onToggle={handleModuleSwitch}
                  defaultChecked={AutoMod.zalgo && AutoMod.zalgo.enabled}
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, zalgo: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.zalgo}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, zalgo: false });
                setNewBadWords(AutoMod.zalgo);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  Zalgo
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>What should happen when a Zalgo is detected?</h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the Zalgo.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="zalgo"
                          defaultChecked={
                            AutoMod.zalgo &&
                            AutoMod.zalgo.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="zalgo"
                          defaultChecked={
                            AutoMod.zalgo &&
                            AutoMod.zalgo.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module={"zalgo"}
                          defaultChecked={
                            AutoMod.zalgo &&
                            AutoMod.zalgo.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        module={"zalgo"}
                        pickedChannels={
                          AutoMod.zalgo ? AutoMod.zalgo.excludedChannels : []
                        }
                        onToggle={handleExcludeChannels}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        module={"zalgo"}
                        pickedRoles={
                          AutoMod.zalgo ? AutoMod.zalgo.excludedRoles : []
                        }
                        onToggle={handleExcludeRoles}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, zalgo: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() => handleSave("zalgo", newZalgo)}
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
          <div className="automod_module">
            <div className="automod_module_header">
              <div
                style={{
                  display: "flex",
                  flexDirection: "revert",
                }}
              >
                <div className="automod_icon">
                  <FontAwesomeIcon icon={faHand} />
                </div>

                <div className="automod_module_header_content">
                  <h2 className="automod_module_header_title">
                    Spammed Caps (70% ${`>`} Caps)
                  </h2>
                  <p className="automod_module_header_description">
                    Filters out messages that have a high percentage of
                    uppercase letters, which can be seen as spam or aggressive
                    behavior.
                  </p>
                </div>
              </div>
              <div className="automod_module_header_switch">
                <SwitchButtons
                  isDisabled={false}
                  module="spamCaps"
                  onToggle={handleModuleSwitch}
                  defaultChecked={AutoMod.spamCaps && AutoMod.spamCaps.enabled}
                />
              </div>
            </div>

            <div className="automod_module_setups">
              <div className="automod_module_setups_section">
                <button
                  className="automod_module_setups_button"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, spamCaps: true })
                  }
                >
                  <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={modalIsOpen.spamCaps}
              style={{
                overlay: {
                  position: "fixed",
                  inset: "0px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {},
              }}
              portalClassName={"ReactModalPortal"}
              className={"smallModal bg-modal automod-modal__content"}
              bodyOpenClassName={"ReactModalPortal__Body--open"}
              htmlOpenClassName={"ReactModalPortal__Html--open"}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              onRequestClose={() => {
                setModalIsOpen({ ...modalIsOpen, spamCaps: false });
                setNewBadWords(AutoMod.spamCaps);
              }}
              role={"dialog"}
              preventScroll={true}
              parentSelector={() => document.body.querySelector("#zero")}
              overlayElement={(props, contentElement) => (
                <div {...props}>{contentElement}</div>
              )}
              contentElement={(props, children) => (
                <div {...props}>{children}</div>
              )}
            >
              <div className="Modalhead">
                <h5>
                  <FontAwesomeIcon className="secand-color" icon={faCog} />{" "}
                  Spammed Caps (70% ${`>`} Caps)
                </h5>
              </div>

              <div className="ReactModalPortal__custom-automod-body">
                <div className="automod-action">
                  <h2>What should happen when Caps is detected?</h2>
                  <div className="action-types">
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faTrash} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Delete Message</h3>
                        <p className="action-type-description">
                          Delete the message containing the Caps letters.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleDeleteMessageAction}
                          isDisabled={false}
                          module="spamCaps"
                          defaultChecked={
                            AutoMod.spamCaps &&
                            AutoMod.spamCaps.actions.includes("delete")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">Mute User</h3>
                        <p className="action-type-description">
                          Mute the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          onToggle={handleMutetUserAction}
                          isDisabled={false}
                          module="spamCaps"
                          defaultChecked={
                            AutoMod.spamCaps &&
                            AutoMod.spamCaps.actions.includes("mute")
                          }
                        />
                      </div>
                    </div>
                    <div className="action-type">
                      <FontAwesomeIcon className="zero_icon" icon={faBan} />
                      <div className="action-type-content">
                        <h3 className="action-type-title">
                          Timeout User <span className="badge_soon">Soon</span>
                        </h3>
                        <p className="action-type-description">
                          Timeout the user who sent the message containing the
                          specified text.
                        </p>
                      </div>
                      <div className="action-type-switch">
                        <SwitchButtons
                          isDisabled={true}
                          onToggle={handleTimeoutUserAction}
                          module={"spamCaps"}
                          defaultChecked={
                            AutoMod.spamCaps &&
                            AutoMod.spamCaps.actions.includes("timeout")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="automod-avoid">
                  <div className="avoid-types">
                    <div className="avoid-type">
                      <h2>
                        Which channels should be excluded from that action?
                      </h2>

                      <Channels
                        className="avoid"
                        channels={channels}
                        module={"spamCaps"}
                        pickedChannels={
                          AutoMod.spamCaps
                            ? AutoMod.spamCaps.excludedChannels
                            : []
                        }
                        onToggle={handleExcludeChannels}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>Which roles should be excluded from that action?</h2>

                      <Roles
                        className="avoid"
                        roles={roles}
                        module={"spamCaps"}
                        pickedRoles={
                          AutoMod.spamCaps ? AutoMod.spamCaps.excludedRoles : []
                        }
                        onToggle={handleExcludeRoles}
                      />
                    </div>
                    <div className="avoid-type">
                      <h2>
                        exclude users from that action?
                        <span className="badge_soon">Soon</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="commandSave"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setModalIsOpen({ ...modalIsOpen, spamCaps: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="saveBtn"
                  onClick={() => handleSave("spamCaps", newSpamCaps)}
                >
                  Save
                </button>
              </div>
            </ReactModal>
          </div>
        </div>
      </div>
    </div>
  );
};
