import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { Loading } from "../../../components/Loaders/Loading";
import { EditsConfig } from "../../../components/Utilities/EditsConfig";
import { Notify } from "../../../components/Utilities/Notify";
import "../../../styles/scss/pages/Dashboard/Servers/Settings.scss";
import { languagesJson } from "../../../utils/langs";
export const Settings = ({ StatOfData, loading, socket }) => {
  const [slashCommanda, setslashCommanda] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const guildId = path.split("/")[2];
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [oldSettings, setOldSettings] = useState(StatOfData);
  const currentGuild = StatOfData && StatOfData.current.guild;
  const [newSettings, setNewSettings] = useState(null);
  const [oldLang, setOldLang] = useState(null);

  useEffect(() => {
    setOldSettings(StatOfData);

    if (StatOfData && StatOfData.current && StatOfData.current.guild) {
      console.log("StatOfData", StatOfData);
      setOldLang({
        value: currentGuild && currentGuild.settings.language,
        label: languagesJson.find(
          (lang) => lang.code == currentGuild.settings.language
        ).name,
      });
      setSettingsLoading(false);
    } else {
      const initialData = {
        guildId: guildId,
        client: socket.id,
      };

      socket.emit("guild", initialData);
      setSettingsLoading(true);
    }
  }, [StatOfData]);
  useEffect(() => {
    if (newSettings && newSettings.language === "") {
      setNewSettings(null);
      handleCancel();
    }
    if (
      newSettings &&
      newSettings.language === oldSettings.current.guild.settings.language
    ) {
      setNewSettings(null);
      handleCancel();
    }

    if (newSettings && newSettings.prefix === "") {
      setNewSettings(null);
      handleCancel();
    }
  }, [newSettings]);

  useEffect(() => {
    socket.on("changeSettings", (data) => {
      if (data.success == true) {
        setNewSettings(null);
        setIsEnabled(false);

        Notify({
          message: "Settings changed successfully",
        });
      } else {
        handleCancel();
        Notify({
          message: "Something went wrong",
        });
      }
      //setIsEnabled(false);
    });
  }, []);

  useEffect(() => {
    if (currentGuild && currentGuild.settings) {
      setSettingsLoading(false);
    }
    return () => {
      console.log("cleaned up");
    };
  }, [StatOfData]);

  useEffect(() => {
    console.log("settingsLoading", settingsLoading);
  }, [settingsLoading]);
  //find lang in current

  const handleCancel = () => {
    setNewSettings(null);
    const setPrefix = window.document.querySelector(
      ".server__settings__form__input"
    );

    setPrefix.value = StatOfData.current.guild.settings.prefix;
    setOldLang({
      value: StatOfData.current.guild.settings.language,
      label: languagesJson.find(
        (lang) => lang.code == StatOfData.current.guild.settings.language
      ).name,
    });
    handleSetLang({
      label: StatOfData.current.guild.settings.language,
      value: StatOfData.current.guild.settings.language,
      canceled: true,
    });

    setIsEnabled(false);
  };

  const handleConfirm = (type) => {
    if (newSettings.language && newSettings.prefix) {
      socket.emit("changeSettings", {
        guildId: guildId,
        language: newSettings.language
          ? newSettings.language
          : StatOfData.current.guild.settings.language,
        prefix: newSettings.prefix
          ? newSettings.prefix
          : StatOfData.current.guild.settings.prefix,
        client: socket.id,
      });
    }
    if (newSettings.language && !newSettings.prefix) {
      socket.emit("changeSettings", {
        guildId: guildId,
        language: newSettings.language
          ? languagesJson.find((lang) => lang.code == newSettings.language).code
          : StatOfData.current.guild.settings.language,
        client: socket.id,
      });
    }

    if (newSettings.prefix && !newSettings.language) {
      socket.emit("changeSettings", {
        guildId: guildId,
        prefix: newSettings.prefix
          ? newSettings.prefix
          : StatOfData.current.guild.settings.prefix,
        client: socket.id,
      });
    }

    if (!newSettings.language && !newSettings.prefix) {
      handleCancel();
    }
  };

  const handleChangePrefix = (e) => {
    e.target.value =
      "Haha try again ;). By the way, this action has been reported.";
    return;
    let newPrefix = e.target.value;

    if (newPrefix.length > 3) {
      newPrefix = newPrefix.slice(0, 3); // Truncate to 3 characters if longer
    }

    // Update the input field's value
    e.target.value = newPrefix;

    if (newPrefix !== currentGuild.settings.prefix) {
      setIsEnabled(true);

      setNewSettings({
        ...newSettings,
        prefix: newPrefix,
      });
    }
  };

  const handleSetLang = (e) => {
    if (e.canceled) {
      setIsEnabled(false);
    } else {
      setNewSettings({
        ...newSettings,
        language: languagesJson.find((lang) => lang.code == e.value).code,
      });
      setOldLang({
        value: e.value,
        label: languagesJson.find((lang) => lang.code == e.value).name,
      });
      setIsEnabled(true);
    }
  };

  function handleSlash(value) {
    setslashCommanda(value);
  }

  const sleectedLangs = languagesJson.map((lang, index) => {
    return {
      value: lang.code,
      label: lang.name,
      key: index,
    };
  });

  return settingsLoading ? (
    <Loading loading={settingsLoading} />
  ) : (
    <div className="dashcon server__settings">
      <div className="container">
        <div className="server__settings__header">
          <div>
            <h2>Server Settings</h2>
          </div>
        </div>

        <form className="server__settings__form">
          <label className="server__settings__form__label">Language</label>

          <Select
            classNamePrefix="channels_picker_form"
            options={sleectedLangs}
            onChange={handleSetLang}
            menuPortalTarget={document.body}
            isSearchable={true}
            isClearable={true}
            value={oldLang}
            isRtl={false}
            backspaceRemovesValue={true}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                backgroundColor: "#36393f",
              }),

              control: (base) => ({
                ...base,
                WebkitBoxAlign: "center",
                backgroundColor: "rgb(255, 255, 255)",
                alignItems: "center",
                borderColor: "rgb(204, 204, 204)",
                borderRadius: "4px",
                borderStyle: "solid",
                borderWidth: "1px",
                cursor: "pointer",
                display: "flex",
                flexWrap: "wrap",
                WebkitBoxPack: "justify",
                justifyContent: "space-between",
                minHeight: "38px",
                transition: "all 100ms ease 0s",
                outline: "0px !important",
              }),

              menu: (base) => ({
                ...base,
                backgroundColor: "#36393f",
                color: "#fff",
                borderRadius: "0.5rem",
                border: "none",
                boxShadow: "0 0 0.5rem #000",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                padding: "0.5rem",
                width: "100%",
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isFocused ? "#f44336" : "#36393f",
                borderRadius: "0.5rem",
                color: "#fff",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#f44336",
                },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "#f44336",
                borderRadius: "0.5rem",
                color: "#fff",
                cursor: "pointer",
                minWidth: "0px",
                WebkitAlignItems: "center",
              }),

              multiValueRemove: (base) => ({
                ...base,
                borderRadius: "0.5rem",
                color: "#fff",
                "&:hover": {
                  color: "#f44336",
                  backgroundColor: "#36393f",
                },
              }),
              valueContainer: (base) => ({
                ...base,
                padding: "0.5rem",
              }),
            }}
          />

          <label className="server__settings__form__label">Prefix</label>
          <input
            type="text"
            className="server__settings__form__input prefix"
            placeholder="At the moment it is not possible to change the prefix"
            defaultValue={currentGuild.settings.prefix} //default value
            onChange={handleChangePrefix}
            disabled
          />
          {/* <label className="server__settings__form__label">
            Slash Commands
          </label>
         <SwitchButton onToggle={handleSlash} />*/}
        </form>
      </div>
      <EditsConfig
        show={isEnabled}
        onChange={(value) => {
          value ? handleConfirm() : handleCancel();
        }}
      />
    </div>
  );
};
