import {
  faBroom,
  faHashtag,
  faPoundSign,
  faUser,
} from "@fortawesome/fontawesome-free-solid";
import { faServicestack } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Loading } from "../../../components/Loaders/Loading";
import "../../../styles/scss/pages/Dashboard//Servers/Overview.scss";

export const Overview = ({ loading, StatOfData, socket }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState(true);
  const path = location.pathname;
  const guildId = path.split("/")[2];
  const guilds = StatOfData.guilds || [];
  const [sent, setSent] = useState(0);
  const [chartData, setChartData] = useState([]);
  const OverviewData = StatOfData && StatOfData.current;
  const currentGuild = guilds.find((guild) => guild.id === guildId);

  useEffect(() => {
    if (socket.id) {
      if (
        socket.id &&
        OverviewData &&
        OverviewData.members &&
        OverviewData.messageSent &&
        OverviewData.messageDeleted &&
        OverviewData.emojis &&
        OverviewData.stickers &&
        OverviewData.channels &&
        OverviewData.roles
      ) {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        setChartData([
          {
            date: "2023-01-01",
            messagesSent: OverviewData.messageSent.message / 2,
            messagesDeleted: OverviewData.messageDeleted.message / 2,
          },
          {
            date: "2023-01-02",
            messagesSent: 120,
            messagesDeleted: 10,
          },
          {
            date: "2023-01-03",
            messagesSent: 90,
            messagesDeleted: 15,
          },
          {
            date: "2023-01-03",
            messagesSent: 90,
            messagesDeleted: 15,
          },
          {
            date: formattedDate,
            messagesSent: OverviewData.messageSent.message,
            messagesDeleted: OverviewData.messageDeleted.message,
          },
        ]);
        setLoadingState(false);
      } else {
        if (sent >= 1) {
          return;
        }
        const initialData = {
          guildId: guildId,
          client: socket.id,
          from: "Overview",
        };

        socket.emit("messagesSent", initialData);
        socket.emit("messagesDeleted", initialData);
        socket.emit("channels", initialData);
        socket.emit("roles", initialData);
        socket.emit("emojis", initialData);
        socket.emit("members", initialData);
        socket.emit("stickers", initialData);
        setSent(sent + 1);
      }
    } else {
      console.log("socket is not connected");
    }
  }, [StatOfData, socket]);

  const handleNavigate = (e) => {
    navigate(e);
  };
  useEffect(() => {
    if (currentGuild && !currentGuild.id === guildId) {
      window.location.href = "/servers";
      handleNavigate(`/servers`);
    }
    if (
      (currentGuild && currentGuild.id !== guildId) ||
      (currentGuild && currentGuild === undefined)
    ) {
      handleNavigate(`/servers`);
    }
  }, []);

  if (!guilds) {
    return <Loading loading={loading} />;
  }

  // const img = `https://cdn.discordapp.com/icons/${currentGuild.id}/${currentGuild.icon}.png`;
  return loadingState ? (
    <div className="overview">
      <Loading loading={loadingState} />
    </div>
  ) : (
    <div className="overview">
      <div className="overview__header">
        <h1 className="overview__header__title">
          Overview of <span>{currentGuild && currentGuild.name}</span>
        </h1>
      </div>
      <div className="overview__server__statistics">
        <h2 className="overview__server__statistics__header__title">
          Server Statistics
        </h2>
        <div className="cards">
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faUser} />
              </div>

              <h1>Members</h1>
            </div>
            <p>{OverviewData.members ? OverviewData.members.length : 0}</p>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faUser} />
              </div>

              <h1>Roles</h1>
            </div>
            <p>{OverviewData.roles ? OverviewData.roles.total : 0}</p>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faHashtag} />
              </div>

              <h1>
                Channels{" "}
                <span
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "normal",
                    color: "gray",
                  }}
                >
                  ( Categories / Text / Voice / Stage / Name / Store )
                </span>
              </h1>
            </div>
            <h1
              style={{
                display: "flex",
                fontSize: "1rem",
                fontWeight: "normal",
              }}
            >
              {OverviewData.channels ? OverviewData.channels.total : 0}{" "}
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "normal",
                  color: "gray",
                  margin: "0 5px",
                }}
              >
                {" "}
                {" ( "}
                {OverviewData.channels ? OverviewData.channels.category : 0}
                {" / "}
                {OverviewData.channels ? OverviewData.channels.text : 0}
                {" / "}
                {OverviewData.channels ? OverviewData.channels.voice : 0}
                {" / "}
                {OverviewData.channels ? OverviewData.channels.stage : 0}
                {" / "}
                {OverviewData.channels ? OverviewData.channels.news : 0}
                {" / "}
                {OverviewData.channels ? OverviewData.channels.store : 0}
                {" )"}
              </div>
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faServicestack} />
              </div>

              <h1>Emojis / Stickers</h1>
            </div>
            <p>
              {OverviewData.emojis ? OverviewData.emojis.length : 0} /{" "}
              {OverviewData.stickers ? OverviewData.stickers.length : 0}
            </p>
          </div>
        </div>
      </div>
      <div className="overview__bot__usage">
        <h2 className="overview__bot__usage__header__title">Bot Usage</h2>
        <div className="cards">
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faBroom} />
              </div>
              <h1>Commands Used</h1>
            </div>
            <p>0</p>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faPoundSign} />
              </div>
              <h1>Most Popular Command</h1>
            </div>
            <p>None</p>
          </div>
        </div>
      </div>

      <div className="overview__activity">
        <h2 className="overview__activity__header__title">Activity</h2>
        <div className="cards">
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faBroom} />
              </div>
              <h1>Messsages Sent</h1>
            </div>
            <p>
              {OverviewData.messageSent ? OverviewData.messageSent.message : 0}
            </p>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="icon">
                <FontAwesomeIcon icon={faPoundSign} />
              </div>
              <h1>Messsages Deleted</h1>
            </div>
            <p>
              {OverviewData.messageDeleted
                ? OverviewData.messageDeleted.message
                : 0}
            </p>
          </div>
        </div>
        <div className="disable-cont">
          <span>
            <FontAwesomeIcon icon={faHashtag} color="#7289da" /> Charts of Sent Messages and Deleted Messages are disabled
          </span>
        </div>

        <div
          className=" disable-cont blur charts charts-cols-lg-2 charts-cols-1"
          aria-disabled
        >
          <div className="chart blur">
            Messages Sent
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                className="blur"
                width={500}
                height={400}
                data={chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip
                  cursorStyle={{ fill: "transparent" }}
                  contentStyle={{ backgroundColor: "transparent" }}
                />
                <Area
                  className="blur"
                  type="monotone"
                  dataKey="messagesSent"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                  name="Messages Sent"
                />
                <Area
                  className="blur"
                  type="monotone"
                  dataKey="messagesDeleted"
                  stackId="1"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  name="Messages Deleted"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          <div className="chart blur">
            Members
            <ResponsiveContainer
              width="100%"
              height={400}
              className="blur" // Apply the blur effect
            >
              <AreaChart
                className="blur" // Apply the blur effect
                width={500}
                height={400}
                data={chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip
                  cursorStyle={{ fill: "transparent" }}
                  contentStyle={{ backgroundColor: "transparent" }}
                />
                <Area
                  className="blur" // Apply the blur effect
                  type="monotone"
                  dataKey="messagesSent"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                  name="Messages Sent"
                />
                <Area
                  className="blur" // Apply the blur effect
                  type="monotone"
                  dataKey="messagesDeleted"
                  stackId="1"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  name="Messages Deleted"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
