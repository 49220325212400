import { CircleLoader } from "react-spinners";
import "../../styles/scss/components/loaders/Loading.scss";
export const Loading = ({ loading }) => {
  if (!loading) {
    return null;
  } else {
    return (
      <div className="loading">
        <CircleLoader
          color={"red"}
          loading={loading}
          size={150}
          speedMultiplier={0.5}
        />
      </div>
    );
  }
};
