import { toast } from "react-toastify";

export const Notify = ({ message, type, id ,updateType}) => {
  switch (type) {
    case "success":
      return toast.success(message, {
        toastId: id,
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progressClassName: "toast__progress ",
      });
    case "error":
      return toast.error(message, {
        toastId: id ? id : "error",
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,

        draggable: true,
        theme: "dark",
        progressClassName: "toast__progress ",
      });
    case "info":
      return toast.info(message, {
        toastId: id,

        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progressClassName: "toast__progress ",
      });

    case "warn":
      return toast.warn(message, {
        toastId: id,

        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progressClassName: "toast__progress ",
      });
    case "loading":
      return toast.loading(message, {
        type: "loading",
        toastId: id,
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
       
        theme: "dark",
        progressClassName: "toast__progress ",
      });
      case "update":
        return toast.update(id, {
          render: message,
          type: updateType,
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          isLoading: false,
          draggable: false,
          theme: "dark",
          progressClassName: "toast__progress ",
        });

    default:
      return toast(message, {
        toastId: id,
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progressClassName: "toast__progress ",
      });
  }
};
