import "../styles/scss/components/Footer.scss";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <div className="footer">
      <div className="zero">
        <div className="logo">
          <div className="img">
            <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="logo" />
          </div>
          <div className="text">ZEROONE</div>
        </div>
        <div className="logo-content">
          The best Discord bot to bootstrap and grow your Discord server
        </div>
        
      <div className="copy-right">
        <div className="zerwone">Copyright © 2019 - {new Date().getFullYear()} ZerwOne Network</div>
      </div>
      </div>

      <div className="content">
        <div className="m-l">
          <div className="title">ZERWONE</div>
          <ul className="links">
            <div>
              <Link className="li" to="/premium">
                Premium
              </Link>
            </div>
            <div>
              <Link className="li" to="/support">
                Support Server
              </Link>
            </div>
            <div>
              <Link className="li" to="/contact">
                Contact
              </Link>
            </div>
            <div></div>
          </ul>
        </div>
        <div className="m-l">
          <div className="title">Servics</div>
          <ul className="links">
            <div>
              <Link className="li" to="/commands">
                Commands
              </Link>
            </div>
            <div>
              <Link className="li" to="/other-servics">
                Other Services
              </Link>
            </div>
            <div>
              <Link className="li" to="/community">
                Our Community
              </Link>
            </div>
          </ul>
        </div>
        <div className="m-l">
          <div className="title">Corporation</div>
          <ul className="links">
            <div>
              <Link className="li" to="/careers">
                Careers
              </Link>
            </div>
            <div>
              <Link className="li" to="/tos">
                Terms of use
              </Link>
            </div>
            <div>
              <Link className="li" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link className="li" to="/bugs">
                Bug Combat software
              </Link>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
