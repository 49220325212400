import "../../../../styles/scss/pages/Dashboard/Servers/Modaration/Logs.scss";

import {
  faChevronDown,
  faChevronUp,
  faCog,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { faHand } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { SwitchButtons } from "../../../../components/Buttons/SwitchButton";
import { Loading } from "../../../../components/Loaders/Loading";
import { Channels } from "../../../../components/Utilities/Channels";
import { ColorPicker } from "../../../../components/Utilities/ColorsPicker";
import { Notify } from "../../../../components/Utilities/Notify";
import { Tooltip } from "../../../../components/Utilities/ToolTip";

export const Logs = ({ StatOfData, loading, socket }) => {
  const [logsLoading, setLogsLoading] = useState(true);
  const guildChannels = StatOfData && StatOfData.current.channels;
  const guildRoles = StatOfData && StatOfData.current.roles;
  const [roles, setRoles] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [color, setColor] = useState({ r: 255, g: 0, b: 0, a: 1 });
  const [colorPickerForm, setColorPickerForm] = useState(false);
  const [isOpen, setIsOpen] = useState({
    messageDelete: false,
    messageUpdate: false,
    messageBulkDelete: false,
    messagePin: false,
    messageUnPin: false,
    memberJoin: false,
    memberLeave: false,
    memberBan: false,
    memberUnban: false,
    memberKick: false,
    memberRoleUpdate: false,

    channelCreate: false,
    channelDelete: false,
    channelUpdate: false,

    roleCreate: false,
    roleDelete: false,
    roleUpdate: false,

    // inviteCreate: false,
    // inviteDelete: false,

    // emojiCreate: false,
    // emojiDelete: false,
    // emojiUpdate: false,

    // webhookCreate: false,
    // webhookDelete: false,
    // webhookUpdate: false,

    // voiceStateUpdate: false,
    // voiceStateJoin: false,
    // voiceStateLeave: false,
    // voiceStateMute: false,
    // voiceStateUnmute: false,
    // voiceStateDeafen: false,

    // guildUpdate: false,
    // guildBoost: false,
    // guildBoostTier: false,
    // guildBoostTier1: false,
    // guildBoostTier2: false,
    // guildBoostTier3: false,

    // guildBannerAdd: false,
    // guildBannerRemove: false,

    // guildVanityUrlCreate: false,
    // guildVanityUrlDelete: false,
  });
  const Logs = StatOfData && StatOfData.current.Logs;
  const [messageDelete, setMessageDelete] = useState([]);
  const [messageUpdate, setMessageUpdate] = useState([]);
  const [messageBulkDelete, setMessageBulkDelete] = useState([]);
  const [messagePin, setMessagePin] = useState([]);
  const [messageUnPin, setMessageUnPin] = useState([]);
  const [memberJoin, setMemberJoin] = useState([]);
  const [memberLeave, setMemberLeave] = useState([]);
  const [memberBan, setMemberBan] = useState([]);
  const [memberUnban, setMemberUnban] = useState([]);
  const [memberKick, setMemberKick] = useState([]);
  const [memberRoleUpdate, setMemberRoleUpdate] = useState([]);
  const [channelCreate, setChannelCreate] = useState([]);
  const [channelDelete, setChannelDelete] = useState([]);
  const [channelUpdate, setChannelUpdate] = useState([]);
  const [roleCreate, setRoleCreate] = useState([]);
  const [roleDelete, setRoleDelete] = useState([]);
  const [roleUpdate, setRoleUpdate] = useState([]);
  const [inviteCreate, setInviteCreate] = useState([]);
  const [inviteDelete, setInviteDelete] = useState([]);
  const [emojiCreate, setEmojiCreate] = useState([]);
  const [emojiDelete, setEmojiDelete] = useState([]);
  const [emojiUpdate, setEmojiUpdate] = useState([]);
  const [webhookCreate, setWebhookCreate] = useState([]);
  const [webhookDelete, setWebhookDelete] = useState([]);
  const [webhookUpdate, setWebhookUpdate] = useState([]);
  const [voiceStateUpdate, setVoiceStateUpdate] = useState([]);
  const [voiceStateJoin, setVoiceStateJoin] = useState([]);
  const [voiceStateLeave, setVoiceStateLeave] = useState([]);
  const [voiceStateMute, setVoiceStateMute] = useState([]);
  const [voiceStateUnmute, setVoiceStateUnmute] = useState([]);
  const [voiceStateDeafen, setVoiceStateDeafen] = useState([]);
  const [guildUpdate, setGuildUpdate] = useState([]);
  const [guildBoost, setGuildBoost] = useState([]);
  const [guildBoostTier, setGuildBoostTier] = useState([]);
  const [guildBoostTier1, setGuildBoostTier1] = useState([]);
  const [guildBoostTier2, setGuildBoostTier2] = useState([]);
  const [guildBoostTier3, setGuildBoostTier3] = useState([]);
  const [guildBannerAdd, setGuildBannerAdd] = useState([]);
  const [guildBannerRemove, setGuildBannerRemove] = useState([]);
  const [guildVanityUrlCreate, setGuildVanityUrlCreate] = useState([]);
  const [guildVanityUrlDelete, setGuildVanityUrlDelete] = useState([]);
  const location = useLocation();
  const path = location.pathname;
  const guildId = path.split("/")[2];
  const [isModuleOpen, setisModuleOpen] = useState(false);

  useEffect(() => {
    if (StatOfData && StatOfData.current && StatOfData.current.Logs && roles && channels) {
      setLogsLoading(false);
    } else {
      const initialData = {
        guildId: guildId,
        client: socket.id,
        from: "getLogs",
      };
      socket.emit("getLogs", initialData);
      socket.emit("channels", initialData);
      socket.emit("roles", initialData);
      setLogsLoading(true);
    }
  }, [StatOfData, guildRoles, guildChannels]);

  useEffect(() => {
    // if (socket && socket.id) {

    socket.on("getLogs", (data) => {
      console.log(data);
      if (data.success && data.updated) {
        Notify({
          type: "update",
          id: data.module,
          updateType: "success",
          message: `Successfully updated ${data.module} settings.`,
        });
        toggleOpen(data.module);
      } else {
        toggleOpen(data.module);
        Notify({
          type: "update",
          id: data.module,
          updateType: "error",
          message: `Failed to update ${data.module} settings.`,
        });
      }
    });
    /*  } else {
      Notify({
        message: "Something went wrong, check your connection.",
        type: "error",
      });
    }*/
  }, []);

  useEffect(() => {
    let roless = [];
    let channelss = [];
    if (guildRoles && guildRoles.roles && !roles.length) {
      roless = guildRoles.roles.map((role) => {
        return {
          ...role,
        };
      });

      setRoles(roless);
    }

    if (guildChannels && guildChannels.ChData && !channels.length) {
      channelss = guildChannels.ChData.map((channel) => {
        return {
          id: channel.id,
          name: channel.name,
        };
      });
      setChannels(channelss);
    }
  }, [guildChannels, guildRoles]);

  useEffect(() => {
    if (Logs && Logs.MessageDelete) {
      setMessageDelete(Logs.MessageDelete);
    }
    if (Logs && Logs.MessageUpdate) {
      setMessageUpdate(Logs.MessageUpdate);
    }
    if (Logs && Logs.MessageBulkDelete) {
      setMessageBulkDelete(Logs.MessageBulkDelete);
    }
    if (Logs && Logs.MessagePin) {
      setMessagePin(Logs.MessagePin);
    }
    if (Logs && Logs.MessageUnPin) {
      setMessageUnPin(Logs.MessageUnPin);
    }
    if (Logs && Logs.MemberJoin) {
      setMemberJoin(Logs.MemberJoin);
    }
    if (Logs && Logs.MemberLeave) {
      setMemberLeave(Logs.MemberLeave);
    }
    if (Logs && Logs.MemberBan) {
      setMemberBan(Logs.MemberBan);
    }
    if (Logs && Logs.MemberUnban) {
      setMemberUnban(Logs.MemberUnban);
    }
    if (Logs && Logs.MemberKick) {
      setMemberKick(Logs.MemberKick);
    }
    if (Logs && Logs.MemberRoleUpdate) {
      setMemberRoleUpdate(Logs.MemberRoleUpdate);
    }
    if (Logs && Logs.ChannelCreate) {
      setChannelCreate(Logs.ChannelCreate);
    }
    if (Logs && Logs.ChannelDelete) {
      setChannelDelete(Logs.ChannelDelete);
    }
    if (Logs && Logs.ChannelUpdate) {
      setChannelUpdate(Logs.ChannelUpdate);
    }
    if (Logs && Logs.RoleCreate) {
      setRoleCreate(Logs.RoleCreate);
    }
    if (Logs && Logs.RoleDelete) {
      setRoleDelete(Logs.RoleDelete);
    }
    if (Logs && Logs.RoleUpdate) {
      setRoleUpdate(Logs.RoleUpdate);
    }
    if (Logs && Logs.InviteCreate) {
      setInviteCreate(Logs.InviteCreate);
    }
    if (Logs && Logs.InviteDelete) {
      setInviteDelete(Logs.InviteDelete);
    }
  }, [Logs]);

  const moduleNames = {
    messageDelete: "Message Delete",
    messageUpdate: "Message Update",
    messageBulkDelete: "Bulk Message Delete",
    messagePin: "Message Pin",
    messageUnPin: "Message Unpin",
    memberJoin: "Member Join",
    memberLeave: "Member Leave",
    memberBan: "Member Ban",
    memberUnban: "Member Unban",
    memberKick: "Member Kick",
    memberRoleUpdate: "Member Role Update",
    channelCreate: "Channel Create",
    channelDelete: "Channel Delete",
    channelUpdate: "Channel Update",
    roleCreate: "Role Create",
    roleDelete: "Role Delete",
    roleUpdate: "Role Update",
    // Add more module keys and names as needed
  };

  // const toggleOpen = (event) => {
  //   setIsOpen({ ...isOpen, [event]: !isOpen[event] });
  // };

  const toggleOpen = (event) => {
    setisModuleOpen((prevIsOpen) => !prevIsOpen);
    setIsOpen((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === event ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
    /*
    //convert hex to rgba
    const hexToRgb = (hex) => {
      if (!hex) {
        setLogsLoading(true);
      } else {
        setLogsLoading(false);
      }
      const r = parseInt(hex.substring(1, 3), 16);
      const g = parseInt(hex.substring(3, 5), 16);
      const b = parseInt(hex.substring(5, 7), 16);

      const result = {
        r: r,
        g: g,
        b: b,
        a: 1,
      };
      return result;
    };

    setColor({
      ...embedsColor,
      r: hexToRgb(getFromOpendModules(event).embedsColor).r,
      g: hexToRgb(getFromOpendModules(event).embedsColor).g,
      b: hexToRgb(getFromOpendModules(event).embedsColor).b,
      a: hexToRgb(getFromOpendModules(event).embedsColor).a,
    });
*/
    setColorPickerForm(false); // Close the color picker
  };

  function handleColorPicker() {
    setColorPickerForm(!colorPickerForm);
  }

  const getFromOpendModules = (moduleKey) => {
    switch (moduleKey) {
      case "messageDelete": {
        return messageDelete;
      }
      case "messageUpdate": {
        return messageUpdate;
      }
      case "messageBulkDelete": {
        return messageBulkDelete;
      }
      case "messagePin": {
        return messagePin;
      }
      case "messageUnPin": {
        return messageUnPin;
      }
      case "memberJoin": {
        return memberJoin;
      }
      case "memberLeave": {
        return memberLeave;
      }
      case "memberBan": {
        return memberBan;
      }
      case "memberUnban": {
        return memberUnban;
      }
      case "memberKick": {
        return memberKick;
      }
      case "memberRoleUpdate": {
        return memberRoleUpdate;
      }
      case "channelCreate": {
        return channelCreate;
      }

      case "channelDelete": {
        return channelDelete;
      }

      case "channelUpdate": {
        return channelUpdate;
      }

      case "roleCreate": {
        return roleCreate;
      }

      case "roleDelete": {
        return roleDelete;
      }

      case "roleUpdate": {
        return roleUpdate;
      }

      case "inviteCreate": {
        return inviteCreate;
      }

      case "inviteDelete  ": {
        return inviteDelete;
      }

      case "emojiCreate": {
        return emojiCreate;
      }

      case "emojiDelete": {
        return emojiDelete;
      }

      case "emojiUpdate": {
        return emojiUpdate;
      }

      default: {
        return [];
      }
    }
  };
  const moduleKeys = Object.keys(isOpen);

  const handlepickChannels = (callback) => {
    switch (callback.module) {
      case "messageDelete": {
        setMessageDelete({
          ...messageDelete,
          channel: callback.value,
        });
        break;
      }
      case "messageUpdate": {
        setMessageUpdate({
          ...messageUpdate,
          channel: callback.value,
        });
        break;
      }
      case "messageBulkDelete": {
        setMessageBulkDelete({
          ...messageBulkDelete,
          channel: callback.value,
        });
        break;
      }
      case "messagePin": {
        setMessagePin({
          ...messagePin,
          channel: callback.value,
        });
        break;
      }
      case "messageUnPin": {
        setMessageUnPin({
          ...messageUnPin,
          channel: callback.value,
        });
        break;
      }
      case "memberJoin": {
        setMemberJoin({
          ...memberJoin,
          channel: callback.value,
        });
        break;
      }
      case "memberLeave": {
        setMemberLeave({
          ...memberLeave,
          channel: callback.value,
        });
        break;
      }
      case "memberBan": {
        setMemberBan({
          ...memberBan,
          channel: callback.value,
        });
        break;
      }
      case "memberUnban": {
        setMemberUnban({
          ...memberUnban,
          channel: callback.value,
        });
        break;
      }
      case "memberKick": {
        setMemberKick({
          ...memberKick,
          channel: callback.value,
        });
        break;
      }
      case "memberRoleUpdate": {
        setMemberRoleUpdate({
          ...memberRoleUpdate,
          channel: callback.value,
        });
        break;
      }
      case "channelCreate": {
        setChannelCreate({
          ...channelCreate,
          channel: callback.value,
        });
        break;
      }
      case "channelDelete": {
        setChannelDelete({
          ...channelDelete,
          channel: callback.value,
        });
        break;
      }
      case "channelUpdate": {
        setChannelUpdate({
          ...channelUpdate,
          channel: callback.value,
        });
        break;
      }
      case "roleCreate": {
        setRoleCreate({
          ...roleCreate,
          channel: callback.value,
        });
        break;
      }

      case "roleDelete": {
        setRoleDelete({
          ...roleDelete,
          channel: callback.value,
        });
        break;
      }

      case "roleUpdate": {
        setRoleUpdate({
          ...roleUpdate,
          channel: callback.value,
        });
        break;
      }

      case "inviteCreate": {
        setInviteCreate({
          ...inviteCreate,
          channel: callback.value,
        });
        break;
      }

      case "emojiCreate": {
        setEmojiCreate({
          ...emojiCreate,
          channel: callback.value,
        });
        break;
      }

      case "emojiDelete": {
        setEmojiDelete({
          ...emojiDelete,
          channel: callback.value,
        });
        break;
      }

      case "emojiUpdate": {
        setEmojiUpdate({
          ...emojiUpdate,
          channel: callback.value,
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  const handleNewColor = (callback) => {
    setColor({
      ...color,
      r: callback.r,
      g: callback.g,
      b: callback.b,
      a: callback.a,
    });

    switch (callback.module) {
      case "messageDelete": {
        console.log("messageDelete", callback.hex);
        setMessageDelete({
          ...messageDelete,
          embedsColor: callback.hex,
        });

        break;
      }
      case "messageUpdate": {
        setMessageUpdate({
          ...messageUpdate,
          embedsColor: callback.hex,
        });
        break;
      }
      case "messageBulkDelete": {
        setMessageBulkDelete({
          ...messageBulkDelete,
          embedsColor: callback.hex,
        });
        break;
      }
      case "messagePin": {
        setMessagePin({
          ...messagePin,
          embedsColor: callback.hex,
        });
        break;
      }
      case "messageUnPin": {
        setMessageUnPin({
          ...messageUnPin,
          embedsColor: callback.hex,
        });
        break;
      }
      case "memberJoin": {
        setMemberJoin({
          ...memberJoin,
          embedsColor: callback.hex,
        });
        break;
      }
      case "memberLeave": {
        setMemberLeave({
          ...memberLeave,
          embedsColor: callback.hex,
        });
        break;
      }
      case "memberBan": {
        setMemberBan({
          ...memberBan,
          embedsColor: callback.hex,
        });
        break;
      }
      case "memberUnban": {
        setMemberUnban({
          ...memberUnban,
          embedsColor: callback.hex,
        });
        break;
      }
      case "memberKick": {
        setMemberKick({
          ...memberKick,
          embedsColor: callback.hex,
        });
        break;
      }
      case "memberRoleUpdate": {
        setMemberRoleUpdate({
          ...memberRoleUpdate,
          embedsColor: callback.hex,
        });
        break;
      }
      case "channelCreate": {
        setChannelCreate({
          ...channelCreate,
          embedsColor: callback.hex,
        });
        break;
      }
      case "channelDelete": {
        setChannelDelete({
          ...channelDelete,
          embedsColor: callback.hex,
        });
        break;
      }
      case "channelUpdate": {
        setChannelUpdate({
          ...channelUpdate,
          embedsColor: callback.hex,
        });
        break;
      }
      case "roleCreate": {
        setRoleCreate({
          ...roleCreate,
          embedsColor: callback.hex,
        });
        break;
      }

      case "roleDelete": {
        setRoleDelete({
          ...roleDelete,
          embedsColor: callback.hex,
        });
        break;
      }

      case "roleUpdate": {
        setRoleUpdate({
          ...roleUpdate,
          embedsColor: callback.hex,
        });
        break;
      }

      case "inviteCreate": {
        setInviteCreate({
          ...inviteCreate,
          embedsColor: callback.hex,
        });
        break;
      }

      case "emojiCreate": {
        setEmojiCreate({
          ...emojiCreate,
          embedsColor: callback.hex,
        });
        break;
      }

      case "emojiDelete": {
        setEmojiDelete({
          ...emojiDelete,
          embedsColor: callback.hex,
        });
        break;
      }

      case "emojiUpdate": {
        setEmojiUpdate({
          ...emojiUpdate,
          embedsColor: callback.hex,
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  const handleSave = async (callback) => {
    switch (callback.module) {
      case "messageDelete": {
        if (!messageDelete.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!messageDelete.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });

        break;
      }
      case "messageUpdate": {
        if (!messageUpdate.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!messageUpdate.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });

        break;
      }
      case "messageBulkDelete": {
        if (!messageBulkDelete.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!messageBulkDelete.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "messagePin": {
        if (!messagePin.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!messagePin.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "messageUnPin": {
        if (!messageUnPin.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!messageUnPin.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "memberJoin": {
        if (!memberJoin.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!memberJoin.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "memberLeave": {
        if (!memberLeave.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!memberLeave.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "memberBan": {
        if (!memberBan.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!memberBan.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "memberUnban": {
        if (!memberUnban.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!memberUnban.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "memberKick": {
        if (!memberKick.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!memberKick.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "memberRoleUpdate": {
        //
        if (!memberRoleUpdate.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!memberRoleUpdate.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "channelCreate": {
        if (!channelCreate.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!channelCreate.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "channelDelete": {
        if (!channelDelete.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!channelDelete.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "channelUpdate": {
        if (!channelUpdate.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!channelUpdate.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "roleCreate": {
        if (!roleCreate.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!roleCreate.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "roleDelete": {
        if (!roleDelete.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!roleDelete.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      case "roleUpdate": {
        if (!roleUpdate.channel)
          return Notify({
            message: "Please select a channel.",
            type: "info",
          });
        if (!roleUpdate.embedsColor)
          return Notify({
            message: "Please select a color.",
            type: "info",
          });
        break;
      }
      default: {
        // Handle other module types or provide an error message
        return Notify({
          message: "Unknown module type.",
          type: "error",
        });
      }
    }

    toast.loading(`Updating ${callback.module} settings...`, {
      toastId: callback.module,
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
    });
    try {
      const data = {
        guildId: guildId,
        client: socket.id,
        from: "setLogs",
        module: callback.module,
        value: callback.value,
      };

      await new Promise((resolve) => setTimeout(resolve, 2000));

      switch (callback.module) {
        case "messageDelete": {
          socket.emit("updateLogs", data);

          break;
        }
        case "messageUpdate": {
          socket.emit("updateLogs", data);

          break;
        }
        default: {
          Notify({
            message: "We are BETA, we are working on it.",
            type: "info",
          });
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggle = (callback) => {
    const data = {
      guildId: guildId,
      client: socket.id,
      from: "setLogs",
      module: callback.module,
      value: callback.value,
    };
    switch (callback.module) {
      case "messageDelete": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "messageUpdate": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "messageBulkDelete": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "messagePin": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "messageUnPin": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "memberJoin": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "memberLeave": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "memberBan": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "memberUnban": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "memberKick": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "memberRoleUpdate": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "channelCreate": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "channelDelete": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "channelUpdate": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "roleCreate": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "roleDelete": {
        socket.emit("logsSwitch", data);
        break;
      }
      case "roleUpdate": {
        socket.emit("logsSwitch", data);
        break;
      }

      default: {
        Notify({
          message: "We are BETA, we are working on it.",
          type: "info",
        });
        break;
      }
    }
  };

  const handleModuleSwitch = (module) => {
    socket.emit("moduleSwitch", {
      guildId,
      client: socket.id,
      from: "moduleSwitch",
      module: module.module,
      value: module.value,
    });
  };
  return logsLoading ? (
    <Loading loading={logsLoading} />
  ) : (
    <div className="dashcon">
      <div className="container">
        <div className="dashboard_header">
          <h2>
            Audit Logs
            <span>
              <Tooltip text="Discord Audit Logs are a record of all actions taken within a Discord server, including user activity, moderation actions, and changes made to settings and configurations. They provide a comprehensive system or platform for reviewing and managing server activity" />
            </span>
          </h2>
          <SwitchButtons
            module="Logs"
            isDisabled={false}
            onToggle={handleModuleSwitch}
            defaultChecked={Logs.enabled}
          />
        </div>
        <div className="dashboard_content_logs">
          {moduleKeys.map((moduleKey) => (
            <div
              className={isOpen[moduleKey] ? "logs_module_open" : "logs_module"}
              key={moduleKey}
            >
              <div className="logs_module_header">
                <div style={{ display: "flex", flexDirection: "revert" }}>
                  <div className="logs_icon">
                    <FontAwesomeIcon icon={faHand} />
                  </div>
                  <div className="logs_module_header_content">
                    <h2 className="logs_module_header_title">
                      {moduleNames[moduleKey]}
                    </h2>
                    <p className="logs_module_header_description">
                      Log {moduleKey.toUpperCase()} messages
                    </p>
                  </div>
                </div>
                {/* Rest of the JSX code */}
                <div
                  className="arrowsupanddown"
                  onClick={() => toggleOpen(moduleKey)}
                >
                  <div className="arrowsupanddown_button">
                    <FontAwesomeIcon
                      icon={isOpen[moduleKey] ? faChevronUp : faChevronDown}
                      className="arrowsupanddown_button_icon"
                    />
                  </div>
                </div>
                <SwitchButtons
                  onToggle={handleToggle}
                  isDisabled={false}
                  module={moduleKey}
                  defaultChecked={getFromOpendModules(moduleKey).enabled}
                  className="logs_module_setups_section_switch_button"
                />
              </div>

              <div className="logs_module_setups">
                {isOpen[moduleKey] && (
                  <div className="logs_module_setups_section">
                    <div className="logs_module_setups_section_switch"></div>
                    <div className="response_options">
                      <div className="avoid-types">
                        <div className="avoid-type">
                          <h2>Select the channels you want to log into</h2>

                          <Channels
                            className="avoid"
                            onToggle={handlepickChannels}
                            channels={channels}
                            pickedChannels={
                              getFromOpendModules(moduleKey).channel
                            }
                            module={moduleKey}
                            single={true}
                          />
                        </div>
                      </div>
                      <div className="response_options_group">
                        <label>Embed color</label>

                        <div
                          // onMouseEnter={() => setColorPickerForm(true)}
                          onClick={handleColorPicker}
                          className="section_color_picker"
                          style={{
                            backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                          }}
                        />
                        {colorPickerForm && (
                          <div onMouseLeave={() => setColorPickerForm(false)}>
                            <ColorPicker
                              color={getFromOpendModules(moduleKey).embedsColor}
                              onChange={handleNewColor}
                              module={moduleKey}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      className="logs_module_setups_button"
                      onClick={() =>
                        handleSave({
                          module: moduleKey,
                          value: getFromOpendModules(moduleKey),
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faCog} /> <span>Setup</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
          {/*isModuleOpen && <div className="overlay" />*/}
        </div>
      </div>
    </div>
  );
};
