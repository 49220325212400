import React, { Component } from "react";
import ErrorMessage from "./ErrorMessage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }
  render() {
    if (this.state.hasError) {
      return <ErrorMessage />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
