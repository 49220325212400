import styled from "@emotion/styled";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../../styles/scss/components/Dashboard/SideBar.scss";
const StyledSidebarHeader = styled.div`
  // height: 64px;
  // min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledLogo = styled.div`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #009fdb;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  ${({ rtl }) =>
    rtl
      ? `
      margin-left: 10px;
      margin-right: 4px;
      `
      : `
      margin-right: 10px;
      margin-left: 4px;
      `}
`;

export const SidebarHeader = ({
  children,
  currentGuild,
  loadingStatus,
  rtl,
  ...rest
}) => {
  return (
    <StyledSidebarHeader {...rest}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <StyledLogo rtl={rtl}>P</StyledLogo>
        <Typography variant="subtitle1" fontWeight={700} color="#0098e5">
          Pro Sidebar
        </Typography> */}
        <div className="SideBar__Server">
          <div className="SideBar__Server__info">
          {loadingStatus ? (
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__avatar"
                style={{
                  padding: "1.5rem",
                  borderRadius: "1rem",
                  margin: "0.5rem",
                }}
                circle={true}
                count={1}
              />
            </SkeletonTheme>
          ) : (
            <img
              className="SideBar__Server__avatar"
              src={
                currentGuild && currentGuild.icon
                  ? `https://cdn.discordapp.com/icons/${currentGuild.id}/${currentGuild.icon}.png`
                  : `https://cdn.discordapp.com/embed/avatars/${Math.floor(
                      Math.random() * 1
                    )}.png`
              }
              alt={currentGuild.name}
            />
          )}
          <h1 className="SideBar__Server__name">
            {loadingStatus ? (
              <SkeletonTheme
                className="colorchange"
                baseColor="#202020"
                color="#202225"
                highlightColor="#2f3136"
              >
                <Skeleton
                  className="SideBar__Server__name"
                  height={30}
                  width={80}
                  count={1}
                />
              </SkeletonTheme>
            ) : currentGuild ? (
              currentGuild.name
            ) : (
              "Select a server"
            )}
          </h1>
          </div>
          <p className="bio">
            {loadingStatus ? (
              <SkeletonTheme
                className="colorchange"
                baseColor="#202020"
                color="#202225"
                highlightColor="#2f3136"
              >
                <Skeleton
                  className="SideBar__Server__name"
                  height={20}
                  width={150}
                  count={1}
                />
              </SkeletonTheme>
            ) : currentGuild && currentGuild.description ? (
              currentGuild.description
            ) : (
              "No description"
            )}
          </p>

          {<span></span>}
          <div className="break">
            <hr />
          </div>
        </div>
      </div>
    </StyledSidebarHeader>
  );
};

/**
 *   <div className="SideBar__Server">
          {loadingStatus ? (
            <SkeletonTheme
              className="colorchange"
              baseColor="#202020"
              color="#202225"
              highlightColor="#2f3136"
            >
              <Skeleton
                className="SideBar__Server__avatar"
                style={{
                  padding: "1.5rem",
                  borderRadius: "1rem",
                  margin: "0.5rem",
                }}
                circle={true}
                count={1}
              />
            </SkeletonTheme>
          ) : (
            <img
              className="SideBar__Server__avatar"
              src={
                currentGuild && currentGuild.icon
                  ? `https://cdn.discordapp.com/icons/${currentGuild.id}/${currentGuild.icon}.png`
                  : `https://cdn.discordapp.com/embed/avatars/${Math.floor(
                      Math.random() * 1
                    )}.png`
              }
              alt={currentGuild.name}
            />
          )}
          <h1 className="SideBar__Server__name">
            {loadingStatus ? (
              <SkeletonTheme
                className="colorchange"
                baseColor="#202020"
                color="#202225"
                highlightColor="#2f3136"
              >
                <Skeleton
                  className="SideBar__Server__name"
                  height={30}
                  width={80}
                  count={1}
                />
              </SkeletonTheme>
            ) : currentGuild ? (
              currentGuild.name
            ) : (
              "Select a server"
            )}
          </h1>
          <p className="bio">
            {loadingStatus ? (
              <SkeletonTheme
                className="colorchange"
                baseColor="#202020"
                color="#202225"
                highlightColor="#2f3136"
              >
                <Skeleton
                  className="SideBar__Server__name"
                  height={20}
                  width={150}
                  count={1}
                />
              </SkeletonTheme>
            ) : currentGuild && currentGuild.description ? (
              currentGuild.description
            ) : (
              "No description"
            )}
          </p>

          {<span></span>}
          <div className="break">
            <hr />
          </div>
        </div>
 * 
 */
