import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-tooltip/dist/react-tooltip.css';
import { App } from "./App";
import ErrorBoundary from "./components/Utilities/Error/ErrorBoundary";
import "./i18next/index";
import "./index.scss";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("zero"));

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ToastContainer />
      <Router>
        <App />
      </Router>
    </Provider>
  </ErrorBoundary>
);

reportWebVitals();
