import { faSpinner } from "@fortawesome/fontawesome-free-solid";
import { faGift, faGifts } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import "../../styles/scss/components/User/UserMenu.scss";
export const UserMenu = ({ StatOfData, isAuth, isConnected, loading }) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const user = StatOfData && StatOfData.user;
  const guilds = StatOfData && StatOfData.guilds;

  const path = window.location.pathname;
  const myRef = useRef(null);

  // const [toggle, setToggle] = useState(false);

  const scrollTo = (c) => document.getElementById(c);

  // const toggleMenu = () => {
  //   const menu = document.querySelector(".user-menu");
  //   menu.classList.toggle("active");
  //   const toggles = document.querySelector(".toggle");
  //   toggles.classList.toggle("hide");
  //   if (toggle) {
  //     document.getElementsByClassName("users")[0].style.marginInlineStart =
  //     "20rem";
  //   } else {
  //     document.getElementsByClassName("users")[0].style.marginInlineStart = "0";

  //   }

  //   setToggle(!toggle);
  // };

  // useEffect(() => {
  //   // selction of the selected menu item
  //   const selected = document.querySelector(".selected");
  //   if (selected) {
  //     selected.classList.remove("selected");
  //   }
  //   const selectedMenu = document.querySelector(`.${props.selected}`);
  //   if (selectedMenu) {
  //     selectedMenu.classList.add("selected");
  //   }
  // }, [selected]);
  if (loading || !user || !guilds)
    return (
      <div className="user-menu active">
        <div className="user-menu__user">
          <SkeletonTheme
            className="colorchange"
            baseColor="#1f1c1c"
            highlightColor="black"
          >
            <Skeleton className="user-menu__user__avatar" count={1} />
            <Skeleton
              className="user-menu__user__username"
              style={{
                borderRadius: "1rem",
                margin: "1rem",
              }}
              height={30}
              width={150}
              count={1}
            />
            <Skeleton
              className="bio"
              style={{
                borderRadius: "1rem",
                margin: "0.3rem",
              }}
              height={30}
              width={120}
              count={1}
            />
          </SkeletonTheme>
        </div>
      </div>
    );
  return (
    <>
      <div className="toggle">
        {/* {toggle ? (
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        ) : (
          // <FontAwesomeIcon icon={["fas", "chevron-left"]} />
          <div></div>
        )} */}
      </div>

      <div className="user-menu active">
        <div className="user-menu__user">
          <img
            className="user-menu__user__avatar"
            src={`https://cdn.discordapp.com/avatars/${user.discordID}/${user.avatar}?size=256`}
            alt="user avatar"
          />
          <h1 className="user-menu__user__username">
            {user.username}#<span>{user.discordTag}</span>
          </h1>
          <p className="bio">{user && user.profile && user.profile.bio}</p>

          {/* <span>{data.profile.bio}</span> */}
          <div className="break">
            <hr />
          </div>
        </div>
        <NavLink className="special" to="/servers">
          <li className="list">
            <FontAwesomeIcon
              className="special__icon"
              icon={["fas", "server"]}
            />
            <span>My Servers</span>
          </li>
        </NavLink>

        <div className="sections">
          <ul className="sections__list">
            <span>General</span>
            <NavLink
              id="overview"
              className={`${path === "/users/@me" ? "selected" : ""}`}
              data-selection="overview"
              // onClick={() => {
              //   if (path === "/users/@me") {
              //     scrollTo("overview").scrollIntoView({ behavior: "smooth" });
              //   } else return;
              // }}
              to="/users/@me"
            >
              <li className="sections__list__item">
                <FontAwesomeIcon
                  className="icon"
                  icon={["fas", "street-view"]}
                />
                <span>Overview</span>
              </li>
            </NavLink>
          </ul>
          <ul className="sections__list">
            <span>Analytics</span>
            <NavLink
              to="/users/@me/social"
              className={`${path === "/users/@me/social" ? "selected" : ""}`}
              data-selection="social"
              // onClick={() => {
              //   if (path === "/users/@me/social") {
              //     scrollTo("social").scrollIntoView({ behavior: "smooth" });
              //   } else return;
              // }}
            >
              <li className="sections__list__item">
                <FontAwesomeIcon className="icon" icon={["fas", "users"]} />
                <span>
                  Social <span className="badge-soon">Soon</span>
                </span>
              </li>
            </NavLink>
          </ul>

          {/* <ul className="sections__list">
              <span>Financial</span>
              <NavLink
                to="/users/@me/financial/banking"
                className={`${
                  path === "/users/@me/financial/banking" ? "selected" : ""
                }`}
                data-selection="banking"
                onClick={() => {
                  if (path === "/users/@me/financial/banking") {
                    scrollTo("banking").scrollIntoView({ behavior: "smooth" });
                  } else return;
                }}
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon className="icon" icon={faLandmark} />
                  <span>
                    Banking
                    <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>

              <NavLink
                to="/users/@me/financial/investments"
                className={`${
                  path === "/users/@me/financial/investments" ? "selected" : ""
                }`}
                data-selection="investments"
                onClick={() => {
                  if (path === "/users/@me/financial/investments") {
                    scrollTo("investments").scrollIntoView({
                      behavior: "smooth",
                    });
                  } else return;
                }}
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon className="icon" icon={faChartColumn} />
                  <span>
                    Investments
                    <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>
              <NavLink
                to="/users/@me/financial/rep"
                className={`${
                  path === "/users/@me/financial/rep" ? "selected" : ""
                }`}
                data-selection="rep"
                onClick={() => {
                  if (path === "/users/@me/financial/rep") {
                    scrollTo("rep").scrollIntoView({ behavior: "smooth" });
                  } else return;
                }}
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon className="icon" icon={faGripfire} />
                  <span>
                    Reputation
                    <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>
              <NavLink
                to="/users/@me/financial/loans"
                className={`${
                  path === "/users/@me/financial/loans" ? "selected" : ""
                }`}
                data-selection="loans"
                onClick={() => {
                  if (path === "/users/@me/financial/loans") {
                    scrollTo("loans").scrollIntoView({ behavior: "smooth" });
                  } else return;
                }}
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon className="icon" icon={faMoneyCheckAlt} />
                  <span>
                    Loans
                    <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>
            </ul>

            <ul className="sections__list">
              <span>Zerw Store</span>
              <NavLink
                to="/users/@me/items"
                className={`${path === "/users/@me/items" ? "selected" : ""}`}
                data-selection="items"
                onClick={() => {
                  if (path === "/users/@me/items") {
                    scrollTo("items").scrollIntoView({ behavior: "smooth" });
                  } else return;
                }}
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon className="icon" icon={["fas", "inbox"]} />
                  <span>
                    My Items <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>
              <NavLink
                to="/store/backgrounds"
                className={`${path === "/store/backgrounds" ? "selected" : ""}`}
                data-selection="backgrounds"
                onClick={() => {
                  if (path === "/store/backgrounds") {
                    scrollTo("backgrounds").scrollIntoView({
                      behavior: "smooth",
                    });
                  } else return;
                }}
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon className="icon" icon={["fas", "images"]} />
                  <span>
                    Backgrounds <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>
              <NavLink
                to="/store/badges"
                className={`${path === "/store/badges" ? "selected" : ""}`}
                data-selection="badges"
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon
                    className="icon"
                    icon={["fas", "user-tag"]}
                  />
                  <span>
                    Badges <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>

              <NavLink
                to="/store/others"
                className={`${path === "/store/others" ? "selected" : ""}`}
                data-selection="others"
                onClick={() => {
                  if (path === "/store/others") {
                    scrollTo("others").scrollIntoView({ behavior: "smooth" });
                  } else return;
                }}
              >
                <li className="sections__list__item">
                  <FontAwesomeIcon className="icon" icon={faSmile} />

                  <span>
                    Others <span className="badge-soon">Soon</span>
                  </span>
                </li>
              </NavLink>
            </ul> */}

          <ul className="sections__list" ref={myRef}>
            <span>Rewards</span>
            <NavLink
              to="/users/@me/spin"
              className={`${path === "/users/@me/spin" ? "selected" : ""}`}
              data-selection="spin"
              onClick={() => {
                if (path === "/users/@me/spin") {
                  scrollTo("spin").scrollIntoView({ behavior: "smooth" });
                } else return;
              }}
            >
              <li className="sections__list__item">
                <FontAwesomeIcon className="icon" icon={faSpinner} />
                <span>Spin it</span>
              </li>
            </NavLink>
            <NavLink
              id="claim"
              className={`${path === "/users/claim" ? "selected" : ""}`}
              data-selection="claim"
              // onClick={() => {
              //   if (path === "/users/claim") {
              //     scrollTo("claim").scrollIntoView({ behavior: "smooth" });
              //   } else return;
              // }}
              to="/users/claim"
            >
              <li className="sections__list__item">
                <FontAwesomeIcon className="icon" icon={faGift} />

                <span>Claim me!</span>
              </li>
            </NavLink>

            <NavLink
              to="/users/@me/drop"
              className={`${path === "/users/@me/drop" ? "selected" : ""}`}
              data-selection="profile"
              onClick={() => {
                if (path === "/users/@me/drop") {
                  scrollTo("drop").scrollIntoView({ behavior: "smooth" });
                } else return;
              }}
            >
              <li className="sections__list__item">
                <FontAwesomeIcon className="icon" icon={faGifts} />

                <span>Drop</span>
              </li>
            </NavLink>
          </ul>

          <ul className="sections__list">
            <span>Settings</span>
            <NavLink
              to="/users/@me/general"
              className={`${path === "/users/@me/general" ? "selected" : ""}`}
              data-selection="general"
              onClick={() => {
                if (path === "/users/@me/general") {
                  scrollTo("general").scrollIntoView({ behavior: "smooth" });
                } else return;
              }}
            >
              <li className="sections__list__item">
                <FontAwesomeIcon className="icon" icon={["fas", "cog"]} />
                <span>General</span>
              </li>
            </NavLink>
            <NavLink
              to="/users/@me/profile"
              className={`${path === "/users/@me/profile" ? "selected" : ""}`}
              data-selection="profile"
              onClick={() => {
                if (path === "/users/@me/profile") {
                  scrollTo("profile").scrollIntoView({ behavior: "smooth" });
                } else return;
              }}
            >
              <li className="sections__list__item">
                <FontAwesomeIcon className="icon" icon={["fas", "user"]} />

                <span>Profile</span>
              </li>
            </NavLink>
            <NavLink
              to="/users/@me/security"
              className={`${path === "/users/@me/security" ? "selected" : ""}`}
              data-selection="security"
              onClick={() => {
                if (path === "/users/@me/security") {
                  scrollTo("security").scrollIntoView({ behavior: "smooth" });
                } else return;
              }}
            >
              <li className="sections__list__item">
                <FontAwesomeIcon
                  className="icon"
                  icon={["fas", "shield-alt"]}
                />

                <span>Security</span>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </>
  );
};
