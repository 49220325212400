import React from "react";
import "../../styles/scss/components/Utilities/ToolTip.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export const Tooltip = ({ text, children }) => {
  const handleMouseOver = (event) => {
    const tooltip = event.currentTarget.querySelector(".tooltip");
    tooltip.style.visibility = "visible";
  };

  const handleMouseOut = (event) => {
    const tooltip = event.currentTarget.querySelector(".tooltip");
    tooltip.style.visibility = "hidden";
  };

  return (
    <div
      className="tooltip-container chat-message"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {children}
      <FontAwesomeIcon icon={faExclamationCircle} className="protip" />

      <div className="tooltip">{text}</div>
    </div>
  );
};
