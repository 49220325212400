import {
  faChartLine,
  faCog,
  faComment,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, menuClasses } from "react-pro-sidebar";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import "../../styles/scss/components/Dashboard/SideBar.scss";
import { Badge } from "../Utilities/SideBar/Badge";
import { SideBarLoading } from "../Utilities/SideBar/Loading";
import { SidebarHeader } from "../Utilities/SideBar/SideBarHeader";
import { Typography } from "../Utilities/SideBar/Typography";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#6c6c6c",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#f44336",
      hover: {
        backgroundColor: "#11111d",
        color: "#6c6c6c",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0a0a14",
      color: "#fff",
      borderColor: "#0a0a14",
    },
    menu: {
      menuContent: "#11111d",
      icon: "#f44336",
      hover: {
        backgroundColor: "#11111d",
        color: "#6c6c6c",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const SideBar = ({ loading, StatOfData }) => {
  const [loadingStatus, setLoadingStatus] = useState(true);
  const location = useLocation();
  const path = location.pathname;
  const guildId = path.split("/")[2];
  const currentGuild = StatOfData.current.guild ?? {};
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [theme, setTheme] = useState("dark");
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentGuild.id !== guildId) {
      setLoadingStatus(true);
    } else {
      setLoadingStatus(false);
    }
  }, [StatOfData]);

  const useDirectionObserver = (callback) => {
    useEffect(() => {
      // Callback will be called whenever the "dir" attribute changes
      const observer = new MutationObserver(callback);

      // Start observing changes to the attributes of the body element
      observer.observe(document.body, {
        attributes: true,
        attributeFilter: ["dir"],
      });

      // Cleanup function to disconnect the observer when the component unmounts
      return () => {
        observer.disconnect();
      };
    }, [callback]);
  };

  // Usage in your component
  useDirectionObserver(() => {
    const dir = document.body.getAttribute("dir");
    if (dir === "rtl") {
      console.log("RTL");
      handleRTLChange({ target: { checked: true } });
      setRtl(true);
    } else {
      console.log("LTR");
      handleRTLChange({ target: { checked: false } });
      setRtl(false);
    }
  });

  useEffect(() => {
    let dir = document.body.getAttribute("dir");

    if (dir === "rtl") {
      console.log("rtl");
      handleRTLChange({ target: { checked: true } });
      setRtl(true);
    } else {
      handleRTLChange({ target: { checked: false } });
      console.log("rtl");

      setRtl(false);
    }

    return () => {
      console.log("cleaned up");
    };
  }, []);

  const handleRTLChange = (e) => {
    setRtl(e.target.checked);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  const handleImageChange = (e) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        borderRadius: "0.5rem",// editing
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div
      className="SideBar"
      style={{
        display: "flex",
        height: "106%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        // {`${window.location.origin}/assets/logo.png`}
        rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
          borderColor: `${themes[theme].sidebar.borderColor} !important`,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <SidebarHeader
            currentGuild={currentGuild}
            loadingStatus={loadingStatus}
            rtl={rtl}
            style={{ marginBottom: "0px" }}
          />
          {loadingStatus ? (
            <SideBarLoading
              loadingStatus={loadingStatus}
              StatOfData={StatOfData}
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "32px",
              }}
              menuItemStyles={menuItemStyles}
              collapsed={collapsed}
            />
          ) : (
            <div style={{ flex: 1, marginBottom: "32px" }}>
              <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  Analytics
                </Typography>
              </div>

              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faChartLine} />}
                  suffix={<Badge variant="new">New</Badge>}
                  className={`${
                    path === `/dashboard/${guildId}/overview` ? "selected" : ""
                  }`}
                  component={<Link to={`/dashboard/${guildId}/overview`} />}
                >
                  Overview
                </MenuItem>
              </Menu>

              <div
                style={{
                  padding: "0 24px",
                  marginBottom: "8px",
                  marginTop: "32px",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  General
                </Typography>
              </div>

              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faCog} />}
                  suffix={<Badge variant="new">New</Badge>}
                  component={<Link to={`/dashboard/${guildId}/settings`} />}
                  className={`${
                    path === `/dashboard/${guildId}/settings` ? "selected" : ""
                  }`}
                >
                  Settings
                </MenuItem>
              </Menu>
              <div
                style={{
                  padding: "0 24px",
                  marginBottom: "8px",
                  marginTop: "32px",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  Utilities
                </Typography>
              </div>

              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faComment} />}
                  suffix={<Badge variant="new">New</Badge>}
                  component={<Link to={`/dashboard/${guildId}/responders`} />}
                  className={`${
                    path === `/dashboard/${guildId}/responders`
                      ? "selected"
                      : ""
                  }`}
                >
                  Auto Response
                </MenuItem>
              </Menu>

              <div
                style={{
                  padding: "0 24px",
                  marginBottom: "8px",
                  marginTop: "32px",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  Modaration
                </Typography>
              </div>

              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faComment} />}
                  suffix={<Badge variant="new">New</Badge>}
                  component={<Link to={`/dashboard/${guildId}/automod`} />}
                  className={`${
                    path === `/dashboard/${guildId}/automod` ? "selected" : ""
                  }`}
                >
                  AutoMod
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faComment} />}
                  suffix={<Badge variant="new">New</Badge>}
                  component={<Link to={`/dashboard/${guildId}/logs`} />}
                  className={`${
                    path === `/dashboard/${guildId}/logs` ? "selected" : ""
                  }`}
                >
                  Logs
                </MenuItem>
              </Menu>
              <div
                style={{
                  padding: "0 24px",
                  marginBottom: "8px",
                  marginTop: "32px",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  Management
                </Typography>
              </div>

              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faComment} />}
                  suffix={<Badge variant="new">New</Badge>}
                  data-tooltip-id="socio-tip"
                  data-tooltip-content="Welcome & Goodbye"
                  component={<Link to={`/dashboard/${guildId}/wlc&bye`} />}
                  className={`${
                    path === `/dashboard/${guildId}/wlc&bye` ? "selected" : ""
                  }`}
                >
                  Welcome & Goodbye
                </MenuItem>
                <Tooltip id="socio-tip" />
              </Menu>

              {/*
               * here you can add more menu items
               */}
            </div>
          )}

          {/* <SidebarFooter collapsed={collapsed} /> */}
        </div>
      </Sidebar>
    </div>
  );
};
