import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { Loading } from "../../components/Loaders/Loading";
import "../../styles/scss/pages/services/Support.scss";

export const Support = () => {
  const [support, setSupport] = useState([]);
  const [data, setData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  async function fetchSupport() {
    await axios
      .get(`${process.env.REACT_APP_API}/bot/support-server`)
      .then((res) => {
        setSupport(res.data);
      });
  }

  useEffect(() => {
    fetchSupport();

    return () => {};
  }, []);

  async function getGuild() {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/guilds?id=${support.support_Server.id}`,
      {
        "Content-Type": "application/json",
        withCredentials: true,
      }
    );
    const data = await response.data;
    setData(data);
  }

  useEffect(() => {
    if (support.status === 200) {
      getGuild();
    }
  }, [support]);
  const handleAuthorize = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const login = function () {
    window.open(`http://localhost:3000/auth/login`, "_self");
  };
  if (support.status !== 200 || !data.id) return <Loading loading={true} />;

  return (
    <div className="support">
      <div className="support-server">
        <img
          src={`https://cdn.discordapp.com/icons/${data.id}/${data.icon}.png`}
          alt={data.name}
        />
        <span className="support-server-name">{data.name}</span>
      </div>

      <div className="support-content">
        <h3>
          New Features <span className="badge-soon">Soon</span>
        </h3>
        <p>We are working on new features. Stay tuned!</p>
        <button onClick={handleAuthorize} className="support-button">
          Authorize
        </button>
        <small>Or</small>

        <Link to={`https://discord.gg/${data.vanityURL}`}>Join</Link>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            position: "fixed",
            inset: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {},
        }}
        portalClassName={"ReactModalPortal"}
        className={"smallModal bg-modal automod-modal__content"}
        bodyOpenClassName={"ReactModalPortal__Body--open"}
        htmlOpenClassName={"ReactModalPortal__Html--open"}
        ariaHideApp={false}
        preventScroll={true}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        s
        role={"dialog"}
        parentSelector={() => document.body.querySelector("#zero")}
        overlayElement={(props, contentElement) => (
          <div {...props}>{contentElement}</div>
        )}
        contentElement={(props, children) => <div {...props}>{children}</div>}
      >
        <div className="Modalhead">Authorization Policy</div>
        <div className="ReactModalPortal__custom-automod-body">
          <p>
            To authorize your account with discord, you must have a verified
            email.
          </p>

          <h3>Terms and Conditions</h3>
          <p>
            Before authorizing your account with Discord, please read and agree
            to the following terms:
          </p>
          <p>
            1. You must have a verified email associated with your Discord
            account.
          </p>
          <p>
            2. By authorizing your account, you agree to allow our application
            to access certain information from your Discord account.
          </p>
          <p>
            3. This information will be used solely for the purpose of providing
            you with the requested services and features.
          </p>
          <p>
            4. We respect your privacy and will not share your information with
            third parties.
          </p>
          <p>
            5. You can revoke the authorization at any time from your Discord
            account settings.
          </p>
          <p>
            By clicking "Authorize with Discord," you confirm that you have read
            and agree to these terms.
          </p>
        </div>
        <div className="commandSave">
          <button onClick={login} className="support-button-policy">
            Authorize with Discord
          </button>
          <button onClick={handleCancel} className="support-button-cancel">
            Cancel
          </button>
        </div>
      </ReactModal>
    </div>
  );
};
