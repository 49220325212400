import {
  // faCode,
  faHeart,
  faServer,
  faUser,
} from "@fortawesome/fontawesome-free-solid";
import {
  faDiscord,
  faInstagram,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "animate.css/animate.min.css";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactModal from "react-modal";
import { NavLink } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Loading } from "../components/Loaders/Loading";
import { MoonLoaders } from "../components/Loaders/MoonLoader";
import "../styles/scss/components/button.scss";
import "../styles/scss/pages/Home.scss";
// import { Card } from "../components/effects/Card";

export const Home = ({ StatOfData, isAuth, isConnected, loading }) => {
  const user = StatOfData.user || null;
  const [openedInvite, setOpenedInvite] = useState(false);
  //check if user is logged in
  const login = function () {
    var h = 720;
    var w = 530;
    let windowWidth = window.screen.width;
    let windowHeight = window.screen.height;
    var left = windowWidth / 2 - w / 2;
    var top = windowHeight / 2 - h / 2;
    window.open(
      `http://localhost:3000/auth/login`,
      "_blank",
      "modal =yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };
  const handleOpenInvite = () => {
    setOpenedInvite(true);
  };

  const handleCloseInvite = () => {
    setOpenedInvite(false);
  };
  const handleInvite = () => {
    var h = 720;
    var w = 530;
    let windowWidth = window.screen.width;
    let windowHeight = window.screen.height;
    var left = windowWidth / 2 - w / 2;
    var top = windowHeight / 2 - h / 2;

    window.open(
      `https://discord.com/api/oauth2/authorize?client_id=819979690738974731&permissions=0&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fredirect&response_type=code&scope=identify%20gdm.join%20guilds.join%20email%20bot`,
      "_blank",
      "modal =yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  function isUserLoggedIn() {
    return user != null && user !== undefined;
  }
  return loading ? (
    <Loading loading={loading} />
  ) : (
    <div className="home">
      <div className="one"></div>

      <div className="header">

        <div className="zero">
          {/* <h1 className="one">Zero One</h1> */}
          <div className="description">
            <div className="descriptiontext">
              <div>
                <h1 className="descriptionp">
                  Choose the best to make <b className="powerful">powerful</b>{" "}
                  community
                </h1>
                <h3 className="descriptionh">
                  In the beginning your choice the first step to success, let us
                  use our magic to make that server.
                </h3>
              </div>
            </div>
            <div className="gate">
              <button onClick={handleOpenInvite} className="invite-btn">
                {" "}
                {/** className="invite" */}
                Add me now
              </button>
              <ReactModal
                isOpen={openedInvite}
                style={{
                  overlay: {
                    position: "fixed",
                    inset: "0px",
                    backgroundColor: "rgba(255, 255, 255, 0.75)",
                  },
                  content: {},
                }}
                portalClassName={"ReactModalPortal"}
                className={"smallModal bg-modal automod-modal__content"}
                bodyOpenClassName={"ReactModalPortal__Body--open"}
                htmlOpenClassName={"ReactModalPortal__Html--open"}
                ariaHideApp={false}
                preventScroll={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                onRequestClose={handleCloseInvite}
                s
                role={"dialog"}
                parentSelector={() => document.body.querySelector("#zero")}
                overlayElement={(props, contentElement) => (
                  <div {...props}>{contentElement}</div>
                )}
                contentElement={(props, children) => (
                  <div {...props}>{children}</div>
                )}
              >
                <div className="Modalhead">Authorization Policy</div>
                <div className="ReactModalPortal__custom-automod-body">
                  <p>
                    To authorize your account with discord, you must have a
                    verified email.
                  </p>

                  <h3>Terms and Conditions</h3>
                  <p>
                    Before authorizing your account with Discord, please read
                    and agree to the following terms:
                  </p>
                  <p>
                    1. You must have a verified email associated with your
                    Discord account.
                  </p>
                  <p>
                    2. By authorizing your account, you agree to allow our
                    application to access certain information from your Discord
                    account.
                  </p>
                  <p>
                    3. This information will be used solely for the purpose of
                    providing you with the requested services and features.
                  </p>
                  <p>
                    4. We respect your privacy and will not share your
                    information with third parties.
                  </p>
                  <p>
                    5. You can revoke the authorization at any time from your
                    Discord account settings.
                  </p>
                  <p>
                    By clicking "Authorize with Discord," you confirm that you
                    have read and agree to these terms.
                  </p>
                </div>
                <div className="commandSave">
                  <button
                    onClick={handleInvite}
                    className="support-button-policy"
                  >
                    Authorize with Discord
                  </button>
                  <button
                    onClick={handleCloseInvite}
                    className="support-button-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </ReactModal>
              {loading || isAuth === null ? (
                <MoonLoaders size={30} loading={true} />
              ) : isAuth ? (
                <NavLink to="/servers" className="dashboard-btn">
                  {" "}
                  {/** className="dashboard" */}
                  Dashboard
                </NavLink>
              ) : (
                <button onClick={login} className="dashboard-btn">
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="servers">
        <div className="server">
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <h2>
              We serve millions of servers: "The best", that's what they said
              about us!
            </h2>
          </AnimationOnScroll>

          <ul className="list">
            <AnimationOnScroll
              animateIn="animate__fadeInRight"
              animateOnce={true}
              duration={1}
            >
              <li className="first"></li>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce={true}
              duration={2}
            >
              <li className="second"></li>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__fadeInDown"
              animateOnce={true}
              duration={3}
            >
              <li className="third"></li>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce={true}
              duration={4}
            >
              <li className="fourth"></li>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__fadeInLeft"
              animateOnce={true}
              duration={5}
            >
              <li className="fifth"></li>
            </AnimationOnScroll>
          </ul>
        </div>
      </div>

      <div className="why">
        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true}>
          <p>
            Why Our <span>Service?</span>
          </p>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
          <h3>
            Zero One Bot allows you to customize and modified your server for
            many commands including welcome image, music, security, configure
            moderation , in-depth logs, social networking like you've never seen
            before with the most easy-to-use dashboard more and more...!
          </h3>
        </AnimationOnScroll>
      </div>
      <div className="features-group">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="features">
            <div className="feature">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <h2>Configure Moderation</h2>
              </AnimationOnScroll>

              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <p>
                  Moderation tweaks are now in a different way that gives you a
                  different way of protecting and working to make it easier to
                  manage your server from the smallest to the largest incidents
                </p>
              </AnimationOnScroll>
            </div>
            <div className="feature-img">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <img src={process.env.PUBLIC_URL + "/assets/ex.jpg"} alt="" />
              </AnimationOnScroll>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="features-group">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="features reverse">
            <div className="feature">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <h2>
                  Security <span>System</span>
                </h2>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <p>
                  Zero One Bot contains a security system that allows you to
                  protect your server in more interactive ways, accuracy and
                  speed of response. The protection is separate on its own,
                  which makes it faster than usual. You can, Configure the
                  security system to your liking and make it easier, Manage your
                  server.
                </p>
              </AnimationOnScroll>
            </div>
            <div className="feature-img">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <img src={process.env.PUBLIC_URL + "/assets/ex.jpg"} alt="" />
              </AnimationOnScroll>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="features-group">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="features">
            <div className="feature">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <h2>Welcome Card</h2>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <p>
                  Take advantage of a welcoming design that includes a user
                  avatar and username with a customizable background image
                  informing newcomers of server rules, topic or current events.
                  You can design your own welcoming card or keep it simple.
                </p>
              </AnimationOnScroll>
            </div>
            <div className="feature-img">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <img src={process.env.PUBLIC_URL + "/assets/ex.jpg"} alt="" />
              </AnimationOnScroll>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="features-group">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="features reverse">
            <div className="feature">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <h2>Beast Music Quality</h2>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <p>
                  Don't forget to give yourself a rest! Listen to the music you
                  like
                </p>
              </AnimationOnScroll>
            </div>
            <div className="feature-img">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <img src={process.env.PUBLIC_URL + "/assets/ex.jpg"} alt="" />
              </AnimationOnScroll>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="features-group">
        <AnimationOnScroll animateIn="animate__bounceIn">
          <div className="features">
            <div className="feature">
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <h2>Social Networking!</h2>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <p>
                  Yes, it is our creation, what you have not seen before, we did
                  it? It is creativity, don't miss the chance to discover it,
                  there is a surprise waiting for you!
                </p>
              </AnimationOnScroll>
            </div>
            <div className="feature-img">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <img src={process.env.PUBLIC_URL + "/assets/ex.jpg"} alt="" />
              </AnimationOnScroll>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="stats">
        <div className="social">
          <div className="join">
            <h1>
              Join Us Today
              <br />
              <span className="waiting">we are waiting you!</span>
            </h1>
          </div>
          <div className="followus">
            <h2>
              Our Social Media
              <br />
              <span className="us">Follow Us!</span>
            </h2>
            <div className="social-links">
              <i>
                <FontAwesomeIcon icon={faDiscord} className="icon" />
              </i>
              <i>
                <FontAwesomeIcon icon={faTwitter} className="icon" />
              </i>
              <i>
                <FontAwesomeIcon icon={faInstagram} className="icon" />
              </i>
              <i>
                <FontAwesomeIcon icon={faTiktok} className="icon" />
              </i>
            </div>
          </div>
        </div>
        <div className="stat">
          <div className="counter">
            <i>
              <FontAwesomeIcon icon={faServer} className="icon" />
            </i>
            <h3 className="value">2,041,051</h3>
            <small className="title">Servers</small>
          </div>

          <div className="counter">
            <i>
              <FontAwesomeIcon icon={faUser} className="icon" />
            </i>
            <h3 className="value">1,000,000</h3>
            <small className="title">Users</small>
          </div>
          <div className="counter">
            <i>
              <FontAwesomeIcon icon={faHeart} className="icon" />
            </i>
            <h3 className="value">1,000,000</h3>
            <small className="title">Likes</small>
          </div>
          <div className="counter">
            <h3 className="value">
              <span>+</span>75
            </h3>
            <small className="title">Available Commands</small>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
