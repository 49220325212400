import { faGift } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ComLoad } from "../../../components/Loaders/ComLoad";
import "../../../styles/scss/components/User/Rewards/Claim.scss";

//claim rewards system'
export const Claim = (props) => {
  const loading = props.loading;
  const user = props.StatOfData.user;
  const levels = {
    1: {
      name: "Level 1",
      rewards: "100",
    },
    5: {
      name: "Level 5",
      rewards: "500",
    },
    10: {
      name: "Level 10",

      rewards: "1000",
    },
    15: {
      name: "Level 15",
      rewards: "1500",
    },
    20: {
      name: "Level 20",
      rewards: "2000",
    },
    25: {
      name: "Level 25",
      rewards: "2500",
    },
    30: {
      name: "Level 30",
      rewards: "3000",
    },
    35: {
      name: "Level 35",
      rewards: "3500",
    },
    40: {
      name: "Level 40",
      rewards: "4000",
    },
    45: {
      name: "Level 45",
      rewards: "4500",
    },
    50: {
      name: "Level 50",
      rewards: "5000",
    },
    55: {
      name: "Level 55",
      rewards: "5500",
    },
    60: {
      name: "Level 60",
      rewards: "6000",
    },
    65: {
      name: "Level 65",
      rewards: "6500",
    },
    70: {
      name: "Level 70",
      rewards: "7000",
    },
    75: {
      name: "Level 75",
      rewards: "7500",
    },
    80: {
      name: "Level 80",
      rewards: "8000",
    },
    85: {
      name: "Level 85",
      rewards: "8500",
    },
    90: {
      name: "Level 90",
      rewards: "9000",
    },
    95: {
      name: "Level 95",
      rewards: "9500",
    },
    100: {
      name: "Level 100",
      rewards: "10000",
    },
  };

  return (
    <>

      {loading ? (
        <div className="claim">
          <ComLoad loading={loading} />
        </div>
      ) : (
        <div className="claim">
          <div className="claim__header">
            <h1 className="claim__header__title">Claim Rewards</h1>
          </div>
          <div className="claim__body">
            {false ? (
              <div className="random__gift">
                <small>
                  You can claim your random gift in <span>24 hours</span>
                </small>
              </div>
            ) : (
              <div className="random__gift">
                <p className="random__gift__title">
                  Get a random gift every 24 hours.
                </p>
                <div className="gift">
                  <FontAwesomeIcon icon={faGift} className="gift__icon" />
                </div>
                <p>
                  You can claim your gift in <span>24 hours</span>
                </p>
              </div>
            )}
            <div className="leveling">
              <p className="leveling__title">Leveling Rewards</p>
              <div className="leveling__rewards">
                {Object.keys(levels).map((key) => {
                  return (
                    <div className="leveling__reward__card">
                      <p className="leveling__reward__card__title">
                        {levels[key].name}
                      </p>
                      <p className="leveling__reward__card__rewards">
                        {levels[key].rewards} XP
                      </p>
                      <button className="leveling__reward__card__button">
                        Claim
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


