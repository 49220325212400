import { faStar, faUsers, faWallet } from "@fortawesome/fontawesome-free-solid";
import { faGripfire } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cookies from "js-cookie";
import { Loading } from "../../components/Loaders/Loading";
import "../../styles/scss/User/Me.scss";
import { languagesJson } from "../../utils/langs";
// import io from "socket.io-client";

export const Me = ({ StatOfData, isAuth, isConnected, loading }) => {
  // const [isRtl, setRtl] = useState(false);
  // const [error, setError] = useState(null);
  const user = StatOfData && StatOfData.user;
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languagesJson.find(
    (l) => l.code === currentLanguageCode
  );
  // useEffect(() => {
  //   if (currentLanguage.dir == "rtl") {
  //     setRtl(true);
  //   } else {
  //     setRtl(false);
  //   } <div className={`${isRtl ? " users users-rtl" : "users"}`}>
  //   console.log(currentLanguage.dir, currentLanguageCode, currentLanguage, isRtl);
  // }, []);

  //fetch data from api
  // useEffect(() => {
  //   //fetch data from api server
  //   const user = async () => {
  //     await getUser()
  //       .then((res) => {
  //         setData(res.data);
  //         loading(false);
  //       })
  //       .catch((err) => {
  //         if (err.response && err.response.data.message) {
  //           setData(null);
  //           loading(false);
  //         }
  //       });
  //   };
  //   user();
  // }, []);

  // useEffect(() => {

  //   socket.connect();
  //   //on connect console log
  //   socket.on("connect", () => {
  //     console.log("connected");
  //   });

  //   socket.on("user", (data) => {
  //     setData(data);
  //     console.log(data);
  //   });

  //   return () => {
  //     socket.off("user");
  //     socket.disconnect();
  //     console.log("disconnected");
  //   }
  // }, []);

  // useEffect(() => {
  //   socket.emit('user', data);
  // }, [data]);


  if (loading || !user) {
    return <Loading loading={loading} />;
  }
  return (
    <div className="users">
      <div className="view">
        <div className="card">
          <div className="card-body">
            <div className="icon">
              <FontAwesomeIcon icon={faUsers} />
            </div>

            <h1>Followers</h1>
          </div>
          <p>{user.profile.followers.length}</p>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon">
              <FontAwesomeIcon icon={faWallet} />
            </div>
            <h1>Account Balance</h1>
          </div>
          <p>{user.finincial.balance}</p>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon">
              <FontAwesomeIcon icon={faStar} />
            </div>

            <h1>Rank</h1>
          </div>
          <p>{user.rank.level}</p>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon">
              <FontAwesomeIcon icon={faGripfire} />
            </div>

            <h1>Reputation</h1>
          </div>
          <p>{user.finincial.reputation.score}</p>
        </div>
      </div>
    </div>
  );
};
