import React from "react";
import "../../styles/scss/components/Utilities/Checkbox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export const Checkbox = (props) => {
    const { id, label, checked, onToggle } = props;
    const handleToggle = (event) => {
      onToggle(event.target.checked);
    };
    return (
      <label className="checkbox-container">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={handleToggle}
        />
        <label htmlFor={id}>{label}</label>
        <span className="checkmark">
          <svg viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        </span>
      </label>
    );
};
