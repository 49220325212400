import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "./App.scss";
import { NavBar } from "./components/NavBar";
import { Home } from "./pages/Home";
import { Servers } from "./pages/Servers";
import { Bugs } from "./pages/corporation/Bugs";
import { Careers } from "./pages/corporation/Careers";
import { Privacy } from "./pages/corporation/PrivacyPolicy";
import { TermsOfUse } from "./pages/corporation/TermsOfUse";
import { Commands } from "./pages/services/Commands";
import { Community } from "./pages/services/Community";
import { Contact } from "./pages/services/Contact";
import { Other } from "./pages/services/OtherServics";
import { Premium } from "./pages/services/Premium";
import { Support } from "./pages/services/Support";
import "./styles/scss/components/Toast.scss";

// import { Loading } from "./components/Loaders/Loading";
import { SideBar } from "./components/Dashboard/SideBar";
import { UserMenu } from "./components/User/UserMenu";
import { Chat } from "./pages/Chat";
import { Welcome } from "./pages/Dashboard/Welcome";
import { WelcomeGoodbye } from "./pages/Dashboard/servers/Management/Welcome&Goodbye";
import { AutoMod } from "./pages/Dashboard/servers/Modaration/AutoMod";
import { Logs } from "./pages/Dashboard/servers/Modaration/Logs";
import { Overview } from "./pages/Dashboard/servers/Overview";
import { Settings } from "./pages/Dashboard/servers/Settings";
import { AutoResponders } from "./pages/Dashboard/servers/Utilities/AutoResponder";

import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "./components/Utilities/Error/ErrorBoundary";
import { Me } from "./pages/User/Me";
import { Claim } from "./pages/User/Rewards/Claim";
import { BotInvite } from "./pages/services/BotInvite";
import { setCurrent, setGuilds, setLogged, setUser } from "./redux/dataReducer";
import { socket } from "./socket";
import { Paths } from "./utils/Paths";
import { checkAuth } from "./utils/helper/CheckAuth";
import { getGuilds, getUser } from "./utils/utils";
export const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const path = location.pathname;
  const guildId = path.split("/")[2];
  const guildComp = path.split("/")[3];
  const paths = Paths;
  const showSidebar = !paths.Dashboard.includes(location.pathname);
  const showUserMenu = location.pathname.includes("users");
  const mode = location.pathname.includes("users") ? "user" : "server";
  const data = useSelector((state) => state.data);
  const [auth, setIsAuth] = useState(null);
  // const loading = useSelector((state) => state.data.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.connect();

    socket.on("connect", () => {
      setIsConnected(true);
      console.log("Connected to socket", socket.id);
      socket.emit("client", socket.id);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
      console.log("Disconnected from socket");
    });

    socket.on("loginAttempt", (data) => {
      if (!data.user || !data.session)
        return console.error("Login Attempt Failed");
      getDataProcess(data);
    });

    socket.on("guild", (data) => {
      if (data && data.guildID) {
        console.log("guild is existing");
        dispatch(
          setCurrent({
            type: "currentguild",
            data: data.guild,
          })
        );
      } else {
        console.log("Guild is not existing");
      }
    });

    socket.on("getAutoResponses", (data) => {
      if (data.message) return console.error(data.message);
      console.log("getAutoResponses", data);
      //  if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "autoResponses", data: data }));
    });
    socket.on("getLogs", (data) => {
      if (data.message) return console.error(data.message);
      //console.log("getLogs", data);
      //  if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "getLogs", data: data }));
    });

    socket.on("getAutoMod", (data) => {
      if (data.message) return console.error(data.message);
      //console.log("getAutoMod", data);
      //  if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "getAutoMod", data: data }));
    });
    socket.on("getWelcomes", (data) => {
      if (data.message) return console.error(data.message);
      //  console.log("getWelcomes", data);
      //  if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "getWelcomes", data: data }));
    });

    socket.on("messagesSent", (data) => {
      //if (data.guildID !== guildId) return;
      dispatch(setCurrent({ type: "messageSent", data: data }));
    });
    socket.on("messagesDeleted", (data) => {
      //  if (data.guildID !== guildId) return;
      dispatch(setCurrent({ type: "messageDeleted", data: data }));
    });

    socket.on("channels", (data) => {
      // console.log("channels", data);
      if (data.message) return console.error(data.message);
      //  if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "channels", data: data.channels }));
    });

    socket.on("roles", (data) => {
      if (data.message) return console.error(data.message);
      //   if (data.roles.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "roles", data: data.roles }));
    });

    socket.on("emojis", (data) => {
      if (data.message) return console.error(data.message);
      //  if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "emojis", data: data.emojis }));
    });

    socket.on("stickers", (data) => {
      //  console.log("stickers", data);
      if (data.message) return console.error(data.message);
      //   if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "stickers", data: data.stickers }));
    });

    socket.on("members", (data) => {
      //  console.log("members", data);
      if (data.message) return console.error(data.message);
      //   if (data.guildID !== guildId) return console.error("Wrong Guild");
      dispatch(setCurrent({ type: "members", data: data.members }));
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.disconnect();
      socket.off("guild");
      socket.off("messagesSent");
      socket.off("messagesDeleted");
      socket.off("channels");
      socket.off("roles");
      socket.off("emojis");
      socket.off("stickers");
      socket.off("loginAttempt");
      socket.off("members");
      socket.off("getAutoResponses");
    };
  }, []);

  async function getDataProcess(login) {
    if (login) {
      const fetchData = async () => {
        try {
          const isAuth = await checkAuth();
          if (!isAuth) {
            setIsAuth(false);

            if (path !== "/") {
              //setLoading(false);
              setLoading(false);
              handleNavigate("/");
            }
          } else {
            if (isAuth.isAuth && login.user !== isAuth.user) {
              console.log(isAuth, login);
              dispatch(setLogged(false));
              setIsAuth(false);
              await getUser();
            } else {
              setIsAuth(true);
            }

            if (data.guilds) {
              return;
            }
            dispatch(setLogged(true));
            await getUser()
              .then(async (u) => {
                console.log("User data fetched: " + u.data.discordID);
                await dispatch(setUser(u.data));
                return await getGuilds();
              })
              .then(async (g) => {
                await dispatch(setGuilds(g.data));
              })
              .catch((err) => {
                if (err.response && err.response.data.message) {
                  //setLoading(false);
                  setLoading(false);
                  dispatch(setLogged(false));
                }
              });
          }
        } catch (error) {
          console.error(error);
          dispatch(setLogged(false));
        }
      };

      fetchData();
      //setLoading(false);
      setLoading(false);
    } else {
      const fetchData = async () => {
        try {
          const isAuth = await checkAuth();
          if (!isAuth) {
            setIsAuth(false);

            if (path !== "/") {
              //setLoading(false);
              setLoading(false);
              handleNavigate("/");
            }
          } else {
            setIsAuth(true);

            if (data.guilds) {
              return;
            }
            dispatch(setLogged(true));
            await getUser()
              .then(async (u) => {
                console.log("User data fetched: " + u.data.discordID);
                await dispatch(setUser(u.data));
                return await getGuilds();
              })
              .then(async (g) => {
                await dispatch(setGuilds(g.data));
                const currentGuild = g.data.find((g) => g.id === guildId);
                //console.log("Current Guild is ::::::::::", currentGuild);
                if (
                  currentGuild === undefined &&
                  !paths.Dashboard.includes(path)
                ) {
                  if (paths.AuthRoute.includes(path)) {
                    console.log("meawwwwww " + path);

                    return;
                  }
                  //handleNavigate("/servers");
                  return;
                }
                if (currentGuild.id !== guildId) {
                  console.log("meawwwwww");
                  handleNavigate("/servers");
                }
                if (currentGuild) {
                  console.log("Current Guild is existing");
                  socket.emit("guild", {
                    client: socket.id,
                    guildId: guildId,
                    from: "Current Guild",
                  });
                } else return console.log("Current Guild is not existing");
              })
              .catch((err) => {
                if (err.response && err.response.data.message) {
                  //setLoading(false);
                  setLoading(false);
                  dispatch(setLogged(false));
                }
              });
          }
        } catch (error) {
          console.error(error);
          dispatch(setLogged(false));
        }
      };

      fetchData();
      //setLoading(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    getDataProcess();
  }, []);

  useEffect(() => {
    if (guildId === undefined) return;
    if (!data.guilds) return;
    const Guilds = data && data.guilds;
    const currentGuild = Guilds.find((g) => g.id === guildId);

    if (path.split("/").includes("users")) return;

    if (currentGuild.id !== guildId) return;
    console.log("I am here ", currentGuild);

    socket.emit("guild", {
      client: socket.id,
      guildId: guildId,
    });
    console.log("Guild is changed");
  }, [guildId, guildComp]);

  useEffect(() => {
    if (!data.isLogged) {
      if (path.includes(paths.AuthRoute)) {
        handleNavigate("/");
      }
      //setLoading(false);
      setLoading(false);
    }
  }, [path]);

  const handleNavigate = (e) => {
    navigate(e);
  };
  return (
    <div>
      <NavBar
        StatOfData={data}
        isAuth={auth}
        isConnected={isConnected}
        loading={loading}
      />
   

      {showSidebar &&
        (mode === "server" ? (
          <SideBar
            StatOfData={data}
            loading={loading}
            guildId={guildId}
            socket={socket}
          />
        ) : (
          <UserMenu StatOfData={data} loading={loading} />
        ))}

      <ErrorBoundary>
        <Routes>
          <Route path="/*" exact={true} element={<div>Not Found</div>} />
          <Route
            path="/"
            exact={true}
            element={<Home StatOfData={data} isAuth={auth} loading={loading} />}
          />
          <Route path="/commands" element={<Commands />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/permium" element={<Premium />} />
          <Route path="/tos" element={<TermsOfUse />} />
          <Route path="/bugs" element={<Bugs />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/other-servics" element={<Other />} />
          <Route path="/community" element={<Community />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/chat" element={<Chat />} />
          <Route
            path="/invite/:id"
            element={<BotInvite StatOfData={data} loading={loading} />}
          />
          <Route
            path="/users/@me"
            exact={true}
            element={<Me StatOfData={data} loading={loading} />}
          />

          <Route
            path="/users/@me/social"
            exact={true}
            element={<Me StatOfData={data} loading={loading} />}
          />

          <Route
            path="/users/claim"
            exact={true}
            element={<Claim StatOfData={data} loading={loading} />}
          />

          <Route
            path="/servers"
            element={<Servers StatOfData={data} loading={loading} />}
          />

          <Route
            path="/dashboard"
            exact={true}
            element={<Welcome StatOfData={data} loading={loading} />}
          />

          <Route
            path="/dashboard/:id"
            exact={true}
            element={<Overview socket={socket} StatOfData={data} />}
          />

          <Route
            path="/dashboard/:id/overview"
            exact={true}
            element={
              <Overview
                StatOfData={data}
                loading={loading}
                socket={socket}
                guildId={guildId}
              />
            }
          />
          <Route
            path="/dashboard/:id/settings"
            exact={true}
            element={
              <Settings StatOfData={data} loading={loading} socket={socket} />
            }
          />

          <Route
            path="/dashboard/:id/responders"
            exact={true}
            element={
              <AutoResponders
                loading={loading}
                StatOfData={data}
                socket={socket}
              />
            }
          />
          <Route
            path="/dashboard/:id/automod"
            exact={true}
            element={
              <AutoMod loading={loading} StatOfData={data} socket={socket} />
            }
          />
          <Route
            path="/dashboard/:id/logs"
            exact={true}
            element={
              <Logs loading={loading} StatOfData={data} socket={socket} />
            }
          />
          <Route
            path="/dashboard/:id/wlc&bye"
            exact={true}
            element={
              <WelcomeGoodbye
                loading={loading}
                StatOfData={data}
                socket={socket}
              />
            }
          />

          {/* <Route path="/dashboard" element={<Dashboard channels={channels}  />} /> */}
        </Routes>
      </ErrorBoundary>
    </div>
  );
};
